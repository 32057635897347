// ** packages **
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "@tiptap/core";

// ** components **
import TranscriptSummary from "./components/TranscriptSummary";
import SelectMenu from "components/SelectMenu";
import Note from "components/Note";

// ** services **
import {
  useSummaryGenerateAPI,
  useSummaryTypeGetAPI,
  useSharedSummaryTypeGetAPI,
  useSharedSummaryGetAPI,
  useUpdateNotesAPI,
} from "./services";

// ** hooks **
import usePermission from "hooks/usePermission";

// ** constants **
import { PERMISSIONS } from "constants/Permissions.constant";
import { Edit } from "react-feather";

type listOfSummaryType = {
  descriptions: string;
  id: number;
  name: string;
  isDefault: boolean;
  prompt: string;
  status: boolean;
  type: string;
};
type summaryPropsType = {
  fileEdit?: any;
  transcriptStatus?: string;
  noteType?: string;
  is_shared?: boolean;
  created_at?: string;
  is_editable?: boolean;
};

type currentSummaryOptionType = {
  id: number | null;
  title: string | null;
  description?: string | null;
  status?: boolean;
  isDefault?: boolean;
  isId?: boolean;
};

const Summary = (props: summaryPropsType) => {
  const {
    transcriptStatus,
    is_shared,
    created_at,
    is_editable = false,
  } = props;
  const { id } = useParams();
  const { getSummaryTypeApi } = useSummaryTypeGetAPI();
  const { getSharedSummaryTypeApi } = useSharedSummaryTypeGetAPI();
  const { summaryGenerateApi, isLoading } = useSummaryGenerateAPI();
  const { sharedSummaryGetApi, isLoading: isSharedLoading } =
    useSharedSummaryGetAPI();
  const [currentSummaryOption, setCurrentSummaryOption] =
    useState<currentSummaryOptionType>({
      id: null,
      isId: false,
      title: null,
      status: false,
      isDefault: false,
      description: null,
    });
  const [summaryOptionTrue, setSummaryOptionTrue] = useState<any>({
    optionTrue: true,
    data: [],
  });
  const [summary, setSummary] = useState<string>(``);
  const [isEditEnable, setIsEditEnable] = useState<boolean>(false);
  const { hasPermission } = usePermission();
  const promptReadPermission = hasPermission(PERMISSIONS.CUSTOM_PROMPT.READ);
  const noteUpdatePermission = hasPermission(PERMISSIONS.NOTES.UPDATE);

  const { updateNotesApi, isLoading: isNoteLoading } = useUpdateNotesAPI();

  const setSummaryType = (data: any) => {
    // setCurrentSummaryOption({
    //   id: currentSummaryOption?.id ? currentSummaryOption?.id : data[0]?.id,
    //   isId: true,
    //   title: currentSummaryOption?.title
    //     ? currentSummaryOption?.title
    //     : data[0]?.title,
    //   status: currentSummaryOption?.status
    //     ? currentSummaryOption?.status
    //     : data[0]?.status,
    //   isDefault: currentSummaryOption?.isDefault
    //     ? currentSummaryOption?.isDefault
    //     : data[0]?.isDefault,
    //   description: currentSummaryOption?.description
    //     ? currentSummaryOption?.description
    //     : data[0]?.description,
    // });

    const noCustomData = data.filter(
      (promptData: listOfSummaryType) => promptData.isDefault
    );

    setSummaryOptionTrue({
      optionTrue: false,
      data: data,
    });
  };

  const getSummaryType = async () => {
    if (id) {

      if (is_shared) {
        const verify_token:string | null = localStorage.getItem("verify_token")
        const { data, error } = await getSharedSummaryTypeApi(id, verify_token);
        if (data && !error) {
          const options: any = [];
          data?.map((option: any) => {
            options.push({
              title: option?.name,
              status: option?.status,
              description: option?.descriptions,
              id: option?.id,
              type: option?.type,
              isDefault: option?.isDefault,
            });
          });
          setSummaryType(options);
        }
      } else {
        const { data, error } = await getSummaryTypeApi(id);
        if (data && !error) {
          const options: any = [];
          data?.map((option: any) => {
            options.push({
              title: option?.name,
              status: option?.status,
              description: option?.descriptions,
              id: option?.id,
              type: option?.type,
              isDefault: option?.isDefault,
            });
          });
          setSummaryType(options);
        }
      }
      setCurrentSummaryOption({
        id: 0,
        isId: false,
        title: "Select AI options",
        status: true,
        isDefault: true,
        description: "Select Options from below",
      });
    }
  };

  const generateSummary = async () => {
    const formData = new FormData();
    formData.append("notetype_id", currentSummaryOption?.id as any);
    if (id) {
      formData.append("transcription_id", id as any);
    }
    if (!is_shared) {
      const { data, error } = await summaryGenerateApi(formData);
      if (data && !error) {
        setSummary(data.Generated_note);
        setSummaryOptionTrue({
          optionTrue: true,
          data: summaryOptionTrue.data.map((item: any) =>
            item.id === data.notetype_id ? { ...item, status: true } : item
          ),
        });
      } else {
        setSummary("");
      }
    } else {
      const { data, error } = await sharedSummaryGetApi(formData);
      if (data && !error) {
        setSummary(data.Generated_note);
        setSummaryOptionTrue({
          optionTrue: true,
          data: summaryOptionTrue.data.map((item: any) =>
            item.id === data.notetype_id ? { ...item, status: true } : item
          ),
        });
      } else {
        setSummary("");
      }
    }
  };

  useEffect(() => {
    getSummaryType();
  }, []);

  useEffect(() => {
    if (currentSummaryOption.isId && transcriptStatus === "Completed") {
      generateSummary();
    }
  }, [currentSummaryOption]);

  const onSelect = (value: currentSummaryOptionType) => {
    if (value?.id !== currentSummaryOption?.id) {
      setCurrentSummaryOption({
        ...value,
        isId: true,
      });
      setIsEditEnable(false);
    }
  };

  const onSave = async (editor: Editor | null) => {
    if (editor?.getHTML() && id && currentSummaryOption?.id) {
      const payload = {
        note: editor?.getHTML(),
        notetype_id: currentSummaryOption?.id,
      };
      if (payload?.note.trim().length > 0) {
        const { error } = await updateNotesApi(payload, id);
        if (!error) {
          setIsEditEnable(false);
          setSummary(payload?.note);
        }
      }
    }
  };

  const onCancel = () => {
    setIsEditEnable(false);
  };

  return (
    <div className="w-full px-[15px]">
      <div>
        <div className="flex items-end pt-2 mb-2">
          {/* {created_at && (
            <div>
              <h3 className="inline text-[20px]">Meeting Date: {created_at}</h3>
            </div>
          )} */}
          {/* <div>
            {currentSummaryOption?.isId && (
              <h2 className="inline text-[25px] font-semibold">
                {currentSummaryOption?.title}
              </h2>
            )}
          </div> */}
          {!isEditEnable && transcriptStatus === "Completed" && (
            <div className="flex items-center gap-2 z-[2]">
              <SelectMenu
                publishingOptions={summaryOptionTrue?.data}
                selected={currentSummaryOption}
                onSelect={onSelect}
                isLoading={isLoading}
                wantTickFromStatus={true}
                ulClassName="max-h-[248px] overflow-y-auto left-0 text-ellipsis"
                noOptionMessage={
                  is_shared ? "no generated notes found." : undefined
                }
              />
              {noteUpdatePermission &&
                currentSummaryOption?.isId  &&
                !isEditEnable &&
                !is_shared &&
                transcriptStatus?.toLowerCase() === "completed" &&
                !isLoading &&
                !isSharedLoading && (
                  <Edit
                    className={`${
                      currentSummaryOption?.isId
                        ? "cursor-pointer"
                        : "pointer-events-none opacity-50"
                    }`}
                    onClick={() => setIsEditEnable(true)}
                  />
                )}
            </div>
          )}
        </div>
        <div className={`flex flex-wrap ${isLoading && "justify-center"}`}>
          {/* <TranscriptSummary
            summaryData={summary}
            isLoading={isLoading}
            transcriptStatus={transcriptStatus}
          /> */}
          <>
            {transcriptStatus !== "Completed" ? (
              <>
                <div className="flex justify-center items-center w-full">
                  <h3 className="text-lg sm:text-sm">
                    {transcriptStatus === "In process" && (
                      <>Your transcription in process, wait a while...</>
                    )}
                    {transcriptStatus === "Pending" && (
                      <>Your transcription in Queue, wait a while...</>
                    )}
                    {(transcriptStatus === "Failed" ||
                      transcriptStatus === "Rejected") && (
                      <>Your transcription is Failed.</>
                    )}
                  </h3>
                </div>
              </>
            ) : (
              <>
                {currentSummaryOption?.isId ? (
                  <Note
                    isEdit={isEditEnable}
                    // setIsEdit={setIsEditEnable}
                    content={summary}
                    isLoading={isLoading}
                    EditorContentClassName="w-[680px] lg:w-auto"
                    onSave={onSave}
                    mainDivClassName={`w-full ${
                      isEditEnable ? "" : "mt-[15px]"
                    }`}
                    isLoadingSave={isNoteLoading}
                    needAlert={true}
                    onCancel={onCancel}
                    editDivClassName={'fixed bottom-0 left-0 right-0'}
                    contentId={currentSummaryOption?.id ?? 1}
                    hasUpdatePermission={
                      is_shared ? false : noteUpdatePermission
                    }
                    needCopyBtn={true}
                  />
                ) : (
                  <div className="flex justify-center items-center w-full">
                    <h3 className="text-lg sm:text-sm sm:mt-3">
                      Choose AI options to {is_shared ? "see" : "produce"} notes.
                    </h3>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Summary;
