// ** hooks **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const PATIENT_API_BASE_PATH = "/patients";
const MEETING_FOLDER_API_BASE_PATH = "/meeting_folder";

export const useMeetingFolderListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getMeetingFolderListApi = async (data: object) => {
    return callApi(`${MEETING_FOLDER_API_BASE_PATH}/get_all_folder`, data);
  };

  return { getMeetingFolderListApi, isLoading, isError, isSuccess };
};

export const useAddMeetingFolderAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addMeetingFolderApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${MEETING_FOLDER_API_BASE_PATH}/add_folder`, data, config);
  };
  return { addMeetingFolderApi, isLoading, isError, isSuccess };
};

export const useGetMeetingFolderAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getMeetingFolderApi = async (id: number) => {
    return callApi(`${MEETING_FOLDER_API_BASE_PATH}/get_folder/${id}`);
  };

  return { getMeetingFolderApi, isLoading, isError, isSuccess };
};

export const useUpdateMeetingAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateMeetingAPI = async (data: object, id: number) => {
    const response = await callApi(
      `${MEETING_FOLDER_API_BASE_PATH}/update_folder/${id}`,
      data
    );
    return response;
  };

  return { updateMeetingAPI, isLoading, isError, isSuccess };
};

export const useGetAppointmentAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAppointmentApi = async (data: object) => {
    return callApi(
      `${PATIENT_API_BASE_PATH}/get_all_appointment_by_patient`,
      data
    );
  };

  return { getAppointmentApi, isLoading, isError, isSuccess };
};

export const useMeetingTranscriptListAPI = (id: number | string) => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getMeetingTranscriptGetAPI = async (data: object) => {
    return callApi(
      `${MEETING_FOLDER_API_BASE_PATH}/folder_transcripts/${id}`,
      data
    );
  };

  return { getMeetingTranscriptGetAPI, isLoading, isError, isSuccess };
};
