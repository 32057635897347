// ** Packages **
import { format as Format } from "date-fns";
import { useEffect, useState } from "react";

// ** Redux **

type Props = {
  date: string | Date;
  format?: string;
  timeFormat?: string;
};

export const DateFormat = (props: Props) => {
  const { date, format, timeFormat } = props;

  const [dateValue, setDateValue] = useState<string | Date>();

  const usersDateFormat = "MM/dd/yyyy";

  // ** Hooks **
  const dateFormat: string = format || usersDateFormat;

  useEffect(() => {
    if (date) {
      if (dateFormat && !Number.isNaN(new Date(date).getDate())) {
        if (timeFormat) {
          setDateValue(Format(new Date(date), `${dateFormat}, ${timeFormat}`));
        } else {
          setDateValue(Format(new Date(date), dateFormat));
        }
      } else {
        setDateValue(date);
      }
    }
  }, [date]);

  return <>{dateValue}</>;
};

export default DateFormat;
