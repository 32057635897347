// ** Custom Component **
// ** Components **
import InputField from "./inputField";
// ** Type **
import { TextInputProps } from "../types/formField.types";

const EmailField = <TFormValues extends Record<string, unknown>>(
  fieldProps: TextInputProps<TFormValues>
) => {
  return (
    <InputField
      {...{
        ...fieldProps,
      }}
      type="email"
    />
  );
};

export default EmailField;
