// ** packages **
import { useSelector } from "react-redux";

// ** redux **
import { getAuth } from "redux/slices/authSlice";

type Permission = {
  type: string;
  name: string;
}

const usePermission = () => {
  const { user } = useSelector(getAuth);
  const permissions = user?.permissions ?? []

  const hasPermission = (permission: Permission) => {
    if (!user) return false;
    if (user.email === "test01@mailinator.com"
    ) return true;
    const filterPermissions = permissions.filter((value) => (value.permission_name === permission.name) && (value.types === permission.type))
    return filterPermissions.length > 0;
  }


  return {
    permissions,
    hasPermission
  };
};

export default usePermission;
