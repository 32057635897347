import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addUserModelValidationSchema } from "../schema/addUsermodel-validationSchema";
import InputField from "components/FormField/common/inputField";
import Button from "components/Theme/Button";
import { useAddUserAPI, useSingleUserAPI, useUpdateUserAPI } from "../services";
import { useGetUserRoleList } from "../hooks/useRoleList";
import { useEffect } from "react";
import AsyncSelectField from "components/FormField/common/OptionFIeld";
import { AddUserModalSchemaType } from "../types/usermanagment-types";
import PageLoader from "components/Loaders/PageLoader";
import PhoneNumberWithCountryCode from "components/FormField/common/PhoneNumberWithCountryCode";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";

type AddUserModalPropsType = {
  setViewMode?: React.Dispatch<
    React.SetStateAction<{ open: boolean; id: number | null | string }>
  >;
  id?: number | null | string;
};
const AddUserModal = (props: AddUserModalPropsType) => {
  const { setViewMode } = props;
  const { id } = useParams();
  const { getUserRoleList, isUserRoleListLoading } = useGetUserRoleList();
  const { addUserAPI, isLoading } = useAddUserAPI();
  const { getUserAPI, isLoading: isUserLoading } = useSingleUserAPI();
  const { updateUserApi, isLoading: isUpdateLoading } = useUpdateUserAPI();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<AddUserModalSchemaType>({
    resolver: yupResolver(addUserModelValidationSchema as any),
  });
  const navigate = useNavigate();
  useEffect(() => {    
    if (id) {
      resetData();
    }
  }, [id]);
  const resetData = async () => {
    if (id) {
      const { data } = await getUserAPI(id);
      const { first_name, last_name, email } = data;
      reset({
        firstName: first_name,
        lastName: last_name,
        email: email,
        role: { label: data.roles?.[0]?.name, value: data.roles?.[0]?.id },
        phoneNumber: data?.contact_number,
      });
    }
  };
  const onSubmit = handleSubmit(async (userData) => {
    const payload = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      role_id: userData.role.value,
      contact_number: userData.phoneNumber,
    };
    if (id) {
      const { error } = await updateUserApi(id, payload);
      if (!error) {
        navigate(-1);
      }
    } else {
      const { error } = await addUserAPI(payload);
      if (!error && setViewMode) {
        setViewMode({ open: true, id: null });
      }
    }
  });

  return (
    <>
      {id ? <Breadcrumbs path={BREAD_CRUMB.editUser} /> : <></>}
      <div className="bg-bgWhiteSD p-[24px] shadow-raiseShadow rounded-[10px] mt-[20px]">
        {isUserLoading ? (
          <PageLoader />
        ) : (
          <div className=" mx-[-10px] p-[24px] sm:p-[10px]">
            <form onSubmit={onSubmit}>
              <div className="flex flex-wrap mx-[-10px]">
                <div className="px-[10px] w-1/2 md:w-full">
                  <InputField
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    register={register}
                    required
                    type="text"
                    placeholder="Enter your First Name"
                    errors={errors.firstName}
                  />
                </div>
                <div className="px-[10px] w-1/2 md:w-full">
                  <InputField
                    id="lastName   "
                    name="lastName"
                    label="Last Name"
                    register={register}
                    required
                    type="text"
                    placeholder="Enter your Last Name"
                    errors={errors.lastName}
                  />
                </div>
                <div className="px-[10px] w-1/2 md:w-full">
                  <InputField
                    id="email   "
                    name="email"
                    label="Email"
                    register={register}
                    required
                    type="text"
                    placeholder="Enter your Email"
                    errors={errors.email}
                    disabled={id ? true : false}
                    className="disabled:bg-slate-200"
                  />
                </div>
                <div className="px-[10px] w-1/2 md:w-full">
                  <div className="field__wrapper">
                    <AsyncSelectField
                      name="role"
                      label="Role"
                      control={control}
                      required
                      placeholder="Select User Role"
                      errors={errors?.role?.value}
                      isLoading={isUserRoleListLoading}
                      getOptions={getUserRoleList}
                    />
                  </div>
                </div>
                <div className="px-[10px] w-1/2 md:w-full">
                  <PhoneNumberWithCountryCode
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="EX. (XXX) XXX-XXXX"
                    register={register}
                    inputMode="numeric"
                    control={control}
                    // required
                    // showDropdown={true}
                    errors={errors.phoneNumber}
                    // maskInputType="mask_input_phone"
                  />
                </div>
                <div className="w-full px-[10px] mt-[15px] flex flex-wrap">
                  <Button
                    className="bg-[#4c60e9] text-white mr-2 mb-3"
                    loading={id ? isUpdateLoading : isLoading}
                    type="submit">
                    Save
                  </Button>

                  <Button
                    className="bg-[#4c60e9] text-white mb-3"
                    onClick={() => {
                      setViewMode
                        ? setViewMode({ open: true, id: null })
                        : navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};
export default AddUserModal;
