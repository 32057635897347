type AddPatientFormType = {
  name: string;
  email: string;
  contact_number: string | undefined;
  profile?: File | string;
};

export const generatePatientInfoFormData = (formVal: AddPatientFormType) => {
  const companyFormData = new FormData();
  Object.keys(formVal).forEach((val) => {
    const key = val as keyof typeof formVal;
    if (key === "profile" && formVal[key] !== undefined) {
      companyFormData.append(val, formVal[key] as File);
    } else if (formVal[key] !== undefined && formVal[key] !== null) {
      companyFormData.append(val, formVal[key]?.toString()?.trim() || "");
    }
  });
  return companyFormData;
};
