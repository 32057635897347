// ** packages **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const PATIENT_API_BASE_PATH = "/patients";

export const usePatientInformationAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPatientInformationAPI = async (id: number) => {
    return callApi(`${PATIENT_API_BASE_PATH}/${id}`);
  };

  return { getPatientInformationAPI, isLoading, isError, isSuccess };
};

export const useAddAppointmentAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addAppointmentApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${PATIENT_API_BASE_PATH}/add_appointment`, data, config);
  };
  return { addAppointmentApi, isLoading, isError, isSuccess };
};

export const useAppointmentDetailsGetAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAppointmentInformationAPI = async (id: any) => {
    return callApi(`${PATIENT_API_BASE_PATH}/appointment/${id}`);
  };

  return { getAppointmentInformationAPI, isLoading, isError, isSuccess };
};

export const useUpdateAppointmentAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateAppointmentApi = async (data: object, id: number) => {
    const response = await callApi(
      `${PATIENT_API_BASE_PATH}/update_appointment/${id}`,
      data
    );
    return response;
  };

  return { updateAppointmentApi, isLoading, isError, isSuccess };
};

export const useGetAllAppointmentAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAllAppointmentAPI = async (id: any, data: object) => {
    return callApi(`${PATIENT_API_BASE_PATH}/get_all_appointment/${id}`, data);
  };

  return { getAllAppointmentAPI, isLoading, isError, isSuccess };
};

export const useAppointmentScheduleDetailsGetAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAppointmentScheduleInformationAPI = async (id: any) => {
    return callApi(`${PATIENT_API_BASE_PATH}/appointment/${id}`);
  };

  return {
    getAppointmentScheduleInformationAPI,
    isLoading,
    isError,
    isSuccess,
  };
};
