// ** Component **
import { AsyncSelectGetOptionsType } from "components/FormField/types/formField.types";
import { useUserRoleAPI } from "../services";

// ** Service **

export const useGetUserRoleList = () => {
  // ** APIS **
  const { getUserRoleAPI, isLoading, isError } = useUserRoleAPI();

  const getUserRoleList: AsyncSelectGetOptionsType = async () => {
    const { data, error } = await getUserRoleAPI({});
    if (data && !isError && !error) {
      const Options = (data || []).map((item: { id: number; name: string }) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      return {
        option: Options,
        count: 0,
      };
    }
  };
  return {
    getUserRoleList,
    isUserRoleListLoading: isLoading,
  };
};
