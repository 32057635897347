// packages **
import { useNavigate } from "react-router-dom";
import AuthMainCard from "modules/Auth/components/AuthMainCard";
import AuthHeaderCard from "modules/Auth/components/AuthHeaderCard";
import AuthBodyCard from "modules/Auth/components/AuthBodyCard";
import Button from "components/Theme/Button";

// ** services **
import { useResendMailAPI } from "modules/Auth/services";

// ** constant **
import { PUBLIC_NAVIGATION } from "constants/navigation.constant";

const ResendMail = () => {
  const navigate = useNavigate();
  const { reSendMailAPI } = useResendMailAPI();
  const email = localStorage.getItem("UserMail");
  const reSendMail = async () => {
    if (email) {
      await reSendMailAPI({ email });
    }
  };
  return (
    <AuthMainCard>
      <AuthHeaderCard
        header=" Verify your email"
        subHeading=" Please check your email for the confirmation mail. Click the link
        in the email to verify your account."
      ></AuthHeaderCard>
      <AuthBodyCard>
        <p className="text-[16px] text-zinc-400 mb-[20px]">
          Didn't receive an email?{" "}
          <span
            className="min-h-[0px] p-0 text-primaryColor cursor-pointer hover:underline"
            onClick={() => reSendMail()}
          >
            Resend
          </span>
        </p>
        <Button
          className={`primary__Btn w-full `}
          onClick={() => {
            navigate(PUBLIC_NAVIGATION.login);
          }}
          children="Go to login"
        />
      </AuthBodyCard>
    </AuthMainCard>
  );
};
export default ResendMail;
