import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateType } from "../store";
import { logoutHelper } from "../../modules/Auth/helper/logout.helper";

export type Permission = {
  id: number;
  types: string;
  status: boolean;
  permission_name: string;
};

type AuthUserType = {
  roles: string | null;
  id: number | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: number | null;
  email: string | null;
  profile: string | null;
  is_super_administrator: boolean;
  permissions: Permission[] | null;
};

type AuthSliceType = {
  user?: Partial<AuthUserType | null>;
  isAuthenticated?: boolean;
  isAuthInitialized?: boolean;
  resendMailId?: string | null;
};

const initialState: AuthSliceType = {
  user: null,
  isAuthenticated: false,
  isAuthInitialized: false,
  resendMailId: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser(state: AuthSliceType, action: PayloadAction<AuthSliceType>) {
      state.user = action.payload.user;
    },
    setAuthenticated(
      state: AuthSliceType,
      action: PayloadAction<AuthSliceType>
    ) {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setAuthInitialized(state: AuthSliceType) {
      state.isAuthInitialized = true;
    },

    setLogoutData(state: AuthSliceType) {
      state.user = null;
      state.isAuthenticated = false;
      logoutHelper();
    },
  },
});
export const { reducer } = slice;
export const {
  setCurrentUser,
  setAuthenticated,
  setAuthInitialized,
  setLogoutData,
} = slice.actions;

export const getAuth = (state: RootStateType) => state.auth;

export const getIsAuthenticated = (state: RootStateType) =>
  state.auth.isAuthenticated;
export const getResendMailId = (state: RootStateType) =>
  state.auth.resendMailId;
