// ** Packages **
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { Suspense } from "react";

// ** Redux **
import { RootStateType } from "redux/store";

// ** Constants **
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "constants/navigation.constant";
import Layout from "components/Layout";
// ** packages **
import PageLoader from "components/Loaders/PageLoader";
import { getAuth } from "redux/slices/authSlice";

type Props = {
  children: JSX.Element;
};

const RequiresAuth = (props: Props) => {
  const { children } = props;

  // ** Hooks **
  const location = useLocation();
  const { isAuthenticated, user } = useSelector(getAuth);

  if (!isAuthenticated || !user) {
    return <Navigate to={PUBLIC_NAVIGATION.login} state={{ from: location }} />;
  }

  if (user?.is_super_administrator) {
    return (
      <Navigate
        to={PRIVATE_NAVIGATION.adminDashboard.view}
        state={{ from: location }}
      />
    );
  }

  return (
    <Layout>
      {" "}
      <Suspense fallback={<PageLoader />}>{children}</Suspense>
    </Layout>
  );
};

export default RequiresAuth;
