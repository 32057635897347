type userFileViewPropsType = {
  fieldName: string | null;
  fieldValue: string | null | number | React.ReactNode;
  className?: string;
};
const UserFileView = (props: userFileViewPropsType) => {
  const { fieldName, fieldValue, className } = props;
  return (
    <div className="flex md:flex-col mb-[7px]">
      <span className="inline-block text-[16px] text-textSecondary md:w-fit w-[130px] relative pr-[10px] mr-[10px] before:content-[':'] before:text-[16px] before:text-textSecondary before:absolute before:top-0 before:right-0">
        {fieldName}
      </span>
      <span
        className={` inline-block text-[16px] ${className} text-textDark md:w-full w-[calc(100%_-_140px)] break-all`}
      >
        {fieldValue}
      </span>
    </div>
  );
};
export default UserFileView;
