// ** packages **
import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Edit, Eye } from "react-feather";

// ** components **
import { Modal } from "components/Theme/Modal";
import SelectField from "components/FormField/common/SelectField";
import Label from "components/FormField/common/Label";
import InputField from "components/FormField/common/inputField";
import TextAreaField from "components/FormField/common/TextAreaField";

// ** services **
import {
  useGetCustomPromptAPI,
  useUpdateCustomPromptAPI,
  useAddCustomPromptAPI,
  useDraftCustomPromptAPI,
} from "modules/Patient/sub-modules/Summary/services";
import { useGetPromptTypeAPI } from "modules/Setting/sub-modules/PersonalSetting/services";

// ** schema **
import { createCustomPromptWithTypeSchema } from "modules/Patient/sub-modules/Summary/schema/createCustomPrompt.validation.schema";

// ** types **
import {
  PromptFormType,
  TypeofPromptType,
  currentDraftStatusType,
  currentStateType,
} from "../types/prompt.types";
import PageLoader from "components/Loaders/PageLoader";

// ** hooks **
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { isEqual } from "lodash";
import { getAuth } from "redux/slices/authSlice";
import { useSelector } from "react-redux";
import MultiSelect, { MultiSelectOptionTypes } from "components/FormField/common/MultiSelect";

const PromptForm = (props: PromptFormType) => {
  const { isOpen, setIsOpen, refreshTable, setPromptStatus } = props;
  const getPromptPromptApi = useGetPromptTypeAPI();
  const getPromptApi = useGetCustomPromptAPI();
  const { hasPermission } = usePermission();
  const saveIntervalRef = useRef<any>(null);
  const [currentDraftStatus, setCurrentDraftStatus] =
    useState<currentDraftStatusType>({ id: null, status: false });
  const currentDraftId = useRef<number | null>(null);
  const lastFormValues = useRef({
    name: "",
    prompt: "",
    descriptions: "",
    types: [],
  });
  const lastDatabaseValue = useRef<currentStateType>({
    id: null,
    name: "",
    prompt: "",
    descriptions: "",
    types: { label: null, value: null },
  });
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const { user } = useSelector(getAuth);
  const is_superAdmin = user?.is_super_administrator;
  const customPromptUpdateRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.UPDATE
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(createCustomPromptWithTypeSchema),
    defaultValues: { name: "", prompt: "", descriptions: "", types: [] },
  });

  const onEdit = async (promptId: number) => {
    const { data, error } = await getPromptApi.getCustomPromptApi(promptId);
    if (!error && data) {
      setValue("name", data?.name);
      setValue("prompt", data?.prompt);
      setValue("descriptions", data?.descriptions);
      const currentSelectedOption = promptType?.filter(
        (prompt) => prompt.value === data?.types
      );
      if (data?.types) {
        setValue(
          "types",
          currentSelectedOption?.[0] ? currentSelectedOption?.[0] : []
        );
      }
      lastFormValues.current = {
        name: data?.name,
        descriptions: data?.descriptions,
        prompt: data?.prompt,
        types: currentSelectedOption?.[0]
          ? currentSelectedOption?.[0]
          : ([] as any),
      };

      lastDatabaseValue.current = {
        id: data?.id,
        is_draft: data?.is_draft,
        name: data?.name,
        descriptions: data?.descriptions,
        prompt: data?.prompt,
        types: currentSelectedOption?.[0]
          ? currentSelectedOption?.[0]
          : ([] as any),
      };
      currentDraftId.current = promptId;
      setIsDraft(data?.is_draft);
      if (promptId !== isOpen?.id) {
        setIsOpen({
          open: true,
          id: promptId,
          isDefault: data?.static,
          viewOnly: true,
        });
      }
    }
  };

  const [promptType, setPromptType] = useState<TypeofPromptType[]>([
    { value: "", label: "" },
  ]);
  const [showError, setShowError] = useState(false)
  const [selectedType, setSelectedType] = useState<MultiSelectOptionTypes[] | any>([]);

  const getPromptTypes = async () => {
    const { data, error } = await getPromptPromptApi.getGetPromptTypeApi();
    if (!error && data) {
      setPromptType(data);
    }
  };

  useEffect(() => {
    getPromptTypes();
    return () => {
      setCurrentDraftStatus({ id: null, status: false });
      clearInterval(saveIntervalRef?.current);
    };
  }, []);

  useEffect(() => {
    if (isOpen?.open && !isOpen?.viewOnly && !isOpen?.isDefault) {
      setCurrentDraftStatus({ id: null, status: true });
      currentDraftId.current = isOpen?.id;
      startSaveInterval();
    } else {
      if (saveIntervalRef?.current) {
        clearInterval(saveIntervalRef?.current);
        setCurrentDraftStatus({ id: null, status: false });
      }
    }
    if (isOpen?.id) {
      onEdit(isOpen?.id);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!currentDraftStatus?.status) {
      clearInterval(saveIntervalRef.current);
    }
  }, [currentDraftStatus?.status]);

  const updatePromptApi = useUpdateCustomPromptAPI();
  const addPromptApi = useAddCustomPromptAPI();
  const draftPromptApi = useDraftCustomPromptAPI();

  const saveAsDraft = async () => {
    const rawData = getValues();

    if (!isEqual(rawData, lastFormValues?.current)) {
      const formData = new FormData();

      for (const [key, value] of Object.entries(rawData)) {
        if (key === "types") {
          formData.append(key, rawData?.types?.value);
        } else {
          formData.append(key, String(value));
        }
      }
      if (currentDraftId?.current) {
        formData.append("id", String(currentDraftId?.current));
      }
      const { data, error } = await draftPromptApi.draftCustomPromptApi(
        formData
      );
      if (!error && data) {
        setCurrentDraftStatus((prevStatus) => ({
          ...prevStatus,
          id: data?.id,
          status: true,
        }));
        currentDraftId.current = data?.id;
        lastFormValues.current = rawData;
      }
    }
  };

  const startSaveInterval = () => {
    saveIntervalRef.current = setInterval(saveAsDraft, 10000); // 10 seconds interval
  };

  const stateFormReset = (error: any, data: any) => {
    if (!error && data) {
      setIsOpen({ open: false, id: null, isDefault: false, viewOnly: true });
      reset();
      refreshTable();
    }
  };

  const onSubmit = handleSubmit(async (submittedData) => {
    if (selectedType?.length === 0) {
      setShowError(true)
    }
    const formData = new FormData();

    for (const [key, value] of Object.entries(submittedData)) {
      if (key === "types") {
        formData.append(key, submittedData?.types?.value);
      } else {
        formData.append(key, String(value));
      }
    }
    if (currentDraftStatus?.status && currentDraftStatus?.id) {
      formData.append("id", String(currentDraftStatus?.id));
      setCurrentDraftStatus({ id: null, status: false });
      currentDraftId.current = null;
    }
    if (isOpen?.id) {
      const { data, error } = await updatePromptApi.updateCustomPromptApi(
        formData,
        String(isOpen?.id)
      );
      stateFormReset(error, data);
    } else {
      const { data, error } = await addPromptApi.addCustomPromptApi(formData);
      stateFormReset(error, data);
    }
  });

  const onClose = () => {
    const lastDBValue = Object.assign({}, lastDatabaseValue.current);
    delete lastDBValue.id;
    delete lastDBValue.is_draft;

    if (
      !isOpen?.id &&
      !isEqual(getValues(), {
        name: "",
        prompt: "",
        descriptions: "",
        types: [],
      })
    ) {
      setCurrentDraftStatus({ id: null, status: false });
      setPromptStatus({
        visible: true,
        id: currentDraftId?.current,
        method: "draft",
        status: null,
        currentState: getValues(),
        lastDbState: null,
      });
      currentDraftId.current = null;
    } else if (isOpen?.id && !isEqual(getValues(), lastDBValue)) {
      setPromptStatus({
        visible: true,
        id: currentDraftId?.current,
        method: "draft update",
        status: null,
        currentState: getValues(),
        lastDbState: lastDatabaseValue.current,
      });
    }
    setCurrentDraftStatus({ id: null, status: false });
    setIsOpen({
      open: false,
      id: null,
      isDefault: false,
      viewOnly: true,
    });
    reset();
  };

  return (
    <div className="w-full px-[15px]">
      {isOpen.open && (
        <Modal
          onSubmit={isOpen?.viewOnly || (isOpen?.isDefault && !is_superAdmin) ? undefined : onSubmit}
          headerName={
            isOpen?.id
              ? isOpen?.viewOnly || (isOpen?.isDefault && !is_superAdmin)
                ? "View Prompt"
                : isDraft
                ? "Review & Save Prompt"
                : "Edit Prompt"
              : "Add Prompt"
          }
          modalWidth="800px"
          isLoading={updatePromptApi.isLoading}
          onClose={onClose}
          onCancel={onClose}
        >
          {getPromptApi.isLoading ? (
            <PageLoader pageLoaderClassName="h-[604px]" />
          ) : (
            <form onSubmit={onSubmit}>
              {customPromptUpdateRole && isOpen?.id && (
                <div className="flex justify-end">
                  {isOpen?.viewOnly ? (
                    <Edit
                      className="text-xs cursor-pointer"
                      onClick={() => {
                        setIsOpen({ ...isOpen, viewOnly: false });
                      }}
                    />
                  ) : (
                    <></>
                    // <Eye
                    //   className="text-xs cursor-pointer"
                    //   onClick={() => {
                    //     setIsOpen({ ...isOpen, viewOnly: true });
                    //   }}
                    // />
                  )}
                </div>
              )}
              <InputField
                name="name"
                label="Title"
                register={register}
                placeholder="Enter Prompt Title"
                required
                type="text"
                errors={errors.name}
                disabled={(isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly}
                className={
                  (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly ? "opacity-50" : ""
                }
              />
              <div className="form__group">
                <div className="field__wrapper">
                  <Label required={true} label="Type of prompt" />
                  <div
                    className={
                      (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                        ? "opacity-50 field__inner__wrapper"
                        : "field__inner__wrapper"
                    }
                  >
                    {/* <MultiSelect options={promptType} setShowError={setShowError} selectedValue={selectedType} setSelectedValue={setSelectedType} /> */}
                    <SelectField
                      control={control}
                      name="types"
                      options={promptType}
                      disabled={(isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly}
                    />
                    <div className="icon__wrapper"></div>
                  </div>
                  {errors?.types && (
                    <p className="error__message">
                      {errors?.types?.message as string}
                    </p>
                  )}
                </div>
              </div>
              <TextAreaField
                id="description"
                name="descriptions"
                label="Description"
                register={register}
                placeholder="Enter Prompt Description"
                required
                errors={errors.descriptions}
                className={
                  (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                    ? "opacity-50 h-20"
                    : "h-20"
                }
                disabled={(isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly}
              />
              <TextAreaField
                id="prompt"
                name="prompt"
                label="Prompt"
                register={register}
                placeholder="Enter Prompt"
                required
                errors={errors.prompt}
                className={
                  (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                    ? "opacity-50 h-52"
                    : "h-52"
                }
                disabled={(isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly}
              />
            </form>
          )}
        </Modal>
      )}
    </div>
  );
};

export default PromptForm;
