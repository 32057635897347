import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addUserModelValidationSchema } from "../schema/addUsermodel-validationSchema";
import InputField from "components/FormField/common/inputField";
import Button from "components/Theme/Button";
import { useAddUserAPI } from "../services";
import { useGetUserRoleList } from "../hooks/useRoleList";
import AsyncSelectField from "components/FormField/common/OptionFIeld";
import { AddUserModalSchemaType } from "../types/usermanagment-types";
import PhoneNumberWithCountryCode from "components/FormField/common/PhoneNumberWithCountryCode";
import { useNavigate } from "react-router-dom";

type AddUserModalPropsType = {
    setViewMode?: React.Dispatch<
        React.SetStateAction<{ open: boolean; id: number | null | string }>
    >;
    id?: number | null | string;
    email: string | null;
};
const AddNewUserModal = (props: AddUserModalPropsType) => {
    const { setViewMode, email } = props;
    const { getUserRoleList, isUserRoleListLoading } = useGetUserRoleList();
    const { addUserAPI, isLoading } = useAddUserAPI();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<AddUserModalSchemaType>({
        resolver: yupResolver(addUserModelValidationSchema as any),
        defaultValues: {
            email: email ? email : ""
        }
    });
    const navigate = useNavigate();

    const onSubmit = handleSubmit(async (userData) => {
        const payload = {
            first_name: userData.firstName,
            last_name: userData.lastName,
            email: userData.email,
            role_id: userData.role.value,
            contact_number: userData.phoneNumber,
        };
        const { error } = await addUserAPI(payload);
        if (!error && setViewMode) {
            setViewMode({ open: false, id: null });
        }

    });

    return (
        <>
            <div className="bg-bgWhiteSD p-[24px] shadow-raiseShadow rounded-[10px] mt-[20px]">

                <div className=" mx-[-10px] p-[24px] sm:p-[10px]">
                    <form onSubmit={onSubmit}>
                        <div className="flex flex-wrap mx-[-10px]">
                            <div className="px-[10px] w-1/2 md:w-full">
                                <InputField
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    register={register}
                                    required
                                    type="text"
                                    placeholder="Enter your First Name"
                                    errors={errors.firstName}
                                />
                            </div>
                            <div className="px-[10px] w-1/2 md:w-full">
                                <InputField
                                    id="lastName   "
                                    name="lastName"
                                    label="Last Name"
                                    register={register}
                                    required
                                    type="text"
                                    placeholder="Enter your Last Name"
                                    errors={errors.lastName}
                                />
                            </div>
                            <div className="px-[10px] w-1/2 md:w-full">
                                <InputField
                                    id="email   "
                                    name="email"
                                    label="Email"
                                    value={email ? email : ""}
                                    disabled={email ? true : false}
                                    register={register}
                                    required
                                    type="text"
                                    placeholder="Enter your Email"
                                    errors={errors.email}
                                    className="disabled:bg-slate-200"
                                />
                            </div>
                            <div className="px-[10px] w-1/2 md:w-full">
                                <div className="field__wrapper">
                                    <AsyncSelectField
                                        name="role"
                                        label="Role"
                                        control={control}
                                        required
                                        placeholder="Select User Role"
                                        errors={errors?.role?.value}
                                        isLoading={isUserRoleListLoading}
                                        getOptions={getUserRoleList}
                                    />
                                </div>
                            </div>
                            <div className="px-[10px] w-1/2 md:w-full">
                                <PhoneNumberWithCountryCode
                                    name="phoneNumber"
                                    label="Phone Number"
                                    placeholder="EX. (XXX) XXX-XXXX"
                                    register={register}
                                    inputMode="numeric"
                                    control={control}
                                    // required
                                    // showDropdown={true}
                                    errors={errors.phoneNumber}
                                // maskInputType="mask_input_phone"
                                />
                            </div>
                            <div className="w-full px-[10px] mt-[15px] flex flex-wrap">
                                <Button
                                    className="bg-[#4c60e9] text-white mr-2 mb-3"
                                    loading={isLoading}
                                    type="submit">
                                    Save
                                </Button>

                                <Button
                                    className="bg-[#4c60e9] text-white mb-3"
                                    onClick={() => {
                                        setViewMode
                                            ? setViewMode({ open: false, id: null })
                                            : navigate(-1);
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </>
    );
};
export default AddNewUserModal;
