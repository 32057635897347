// ** Component **
import { AsyncSelectLoadOptions } from "components/AsyncSelectServer";
import { useMeetingFolderListAPI } from "modules/MeetingFolder/services";
import { usePatientListAPI } from "modules/Patient/services";

interface Patient {
  id: number;
  name: string;
}

export const useGetSideBarList = (note_type: "patient" | "meeting") => {
  const {
    getPatientListApi,
    isLoading: isPatientLoading,
    isError: isPatientError,
  } = usePatientListAPI();
  const {
    getMeetingFolderListApi,
    isLoading: isMeetingLoading,
    isError: isMeetingError,
  } = useMeetingFolderListAPI();

  const loadOptions: AsyncSelectLoadOptions = async (searchInput, callback) => {
    try {
      const payload = {
        params: {
          page: 1,
          perPage: 10,
          search: searchInput || null,
        },
      };

      const response =
        note_type === "patient"
          ? await getPatientListApi(payload)
          : await getMeetingFolderListApi(payload);

      if (
        response?.data &&
        !isPatientError &&
        !isMeetingError &&
        !response?.error
      ) {
        const formattedOptions = (response?.data.data || []).map(
          (item: Patient) => ({
            label: item.name,
            value: item.id,
          })
        );
        callback(formattedOptions);
      } else {
        callback([]);
      }
    } catch (error) {
      callback([]);
    }
  };

  return {
    loadOptions,
    isListLoading: isPatientLoading || isMeetingLoading,
    getOptionsListApi:
      note_type === "patient" ? getPatientListApi : getMeetingFolderListApi,
  };
};
