import { IconInputProps } from ".";

export const PatientPlusIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 15.9375C30 16.1862 29.9012 16.4246 29.7254 16.6005C29.5496 16.7763 29.3111 16.875 29.0625 16.875H27.1875V18.75C27.1875 18.9987 27.0887 19.2371 26.9129 19.413C26.7371 19.5888 26.4986 19.6875 26.25 19.6875C26.0014 19.6875 25.7629 19.5888 25.5871 19.413C25.4113 19.2371 25.3125 18.9987 25.3125 18.75V16.875H23.4375C23.1889 16.875 22.9504 16.7763 22.7746 16.6005C22.5988 16.4246 22.5 16.1862 22.5 15.9375C22.5 15.6889 22.5988 15.4504 22.7746 15.2746C22.9504 15.0988 23.1889 15 23.4375 15H25.3125V13.125C25.3125 12.8764 25.4113 12.638 25.5871 12.4621C25.7629 12.2863 26.0014 12.1875 26.25 12.1875C26.4986 12.1875 26.7371 12.2863 26.9129 12.4621C27.0887 12.638 27.1875 12.8764 27.1875 13.125V15H29.0625C29.3111 15 29.5496 15.0988 29.7254 15.2746C29.9012 15.4504 30 15.6889 30 15.9375ZM16.875 18.4782C18.3446 17.5628 19.4762 16.1937 20.0984 14.578C20.7207 12.9623 20.7998 11.1879 20.3238 9.52324C19.8477 7.85857 18.8425 6.39422 17.4601 5.35174C16.0778 4.30926 14.3935 3.74536 12.6621 3.74536C10.9307 3.74536 9.24643 4.30926 7.86406 5.35174C6.48169 6.39422 5.47646 7.85857 5.00044 9.52324C4.52443 11.1879 4.60353 12.9623 5.22578 14.578C5.84803 16.1937 6.97957 17.5628 8.44921 18.4782C6.02929 19.2704 3.85195 20.7504 2.10117 22.834C1.98655 22.9703 1.91309 23.1363 1.8894 23.3128C1.8657 23.4893 1.89275 23.6688 1.96737 23.8305C2.04198 23.9921 2.16109 24.1292 2.31076 24.2256C2.46042 24.3221 2.63445 24.3739 2.81249 24.375H22.5C22.6786 24.3752 22.8536 24.3244 23.0043 24.2285C23.155 24.1325 23.2752 23.9956 23.3507 23.8336C23.4261 23.6717 23.4538 23.4916 23.4303 23.3145C23.4069 23.1374 23.3334 22.9707 23.2184 22.834C21.4664 20.7504 19.2891 19.2704 16.875 18.4782Z"
        fill="#4b5c6b"
      />
    </svg>
  );
};
