import {
  PhoneNumberError,
  RegisterSchemaError,
} from "constants/formErrorMessage.constant";
import * as yup from "yup";
import type { CountryCode } from "libphonenumber-js/types";
import { phoneCountryJson } from "constants/countryCodeJson.constant";
import "yup-phone-lite";

export const basicSchema = {
  phone: yup
    .string()
    .test("phoneNumber", PhoneNumberError.valid, (value) => {
      if (value !== undefined && value && value.split(" ").length > 1) {
        const countryCode: string | undefined = value
          ?.split(" ")[0]
          .substring(1, value?.split(" ")[0].length)
          .toString();
        let countryShortCode: CountryCode | CountryCode[] = "US";
        if (countryCode) countryShortCode = phoneCountryJson[countryCode];

        return yup.string().phone(countryShortCode).isValid(value);
      }
      return true;
    })
    .transform((value, originalVal) => {
      if (originalVal && value.split(" ").length === 1) {
        return null;
      }
      return originalVal;
    }),
};

export const personalInformationSchema = yup.object().shape({
  firstName: yup.string().trim().required(RegisterSchemaError.firstName),
  lastName: yup.string().trim().required(RegisterSchemaError.lastName),
  phoneNumber: basicSchema.phone.required(PhoneNumberError.required),
  email: yup.string().email().required(RegisterSchemaError.email.valid),
});
