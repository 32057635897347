import { useState } from "react";
import AsyncSelect from "react-select/async";
import { Option } from "components/FormField/types/formField.types";
import Label from "components/FormField/common/Label";
import {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
} from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { debounce } from "lodash";

export type AsyncSelectLoadOptions = (
  searchInput: string,
  callback: (options: SelectOption[]) => void
) => void;

export interface SelectOption {
  label: string;
  value: number;
}
type AsyncSelectServerPropsType = {
  apiCall: (data: object) => Promise<{
    data?: any;
    error?: any;
  }>;
  isLoading?: boolean;
  loadOptions: (
    searchInput: string,
    callback: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void
  ) => Promise<OptionsOrGroups<Option, GroupBase<Option>>> | void;
  onCustomChange?: (data: SingleValue<Option> | MultiValue<Option>) => void;
  labelName?: string;
  staticOptions?: Option[];
  defaultSelectedValue?: Option[] | [];
  isMultiple?: boolean;
  selectRef?: React.RefObject<Select<Option, boolean, GroupBase<Option>>>;
  labelClass?: string;
  labelTextClass?: string;
};

const AsyncSelectServer = (props: AsyncSelectServerPropsType) => {
  const {
    apiCall,
    isLoading = false,
    loadOptions,
    onCustomChange,
    labelName,
    staticOptions = [],
    defaultSelectedValue = [],
    isMultiple = false,
    selectRef,
    labelClass = "",
    labelTextClass = "font-medium",
  } = props;
  const [options, setOptions] = useState<Option[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const getOptions = async (page: number) => {
    const { data, error } = await apiCall({ params: { page: page } });
    if (data && !error) {
      const formattedOptions = (data.data || []).map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      if (page === 1) {
        if (staticOptions.length > 0) {
          setOptions([...staticOptions, ...formattedOptions]);
        } else {
          setOptions(formattedOptions);
        }
      } else {
        setOptions([...options, ...formattedOptions]);
      }
      setTotal(data?.count || 0);
      setPage(page + 1);
    }
  };

  const onMenuScrollToBottom = (e: any) => {
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
      total > options.length
    ) {
      getOptions(page);
    }
  };
  const loadSuggestions = debounce(loadOptions, 300);
  return (
    <>
      <div className="field__wrapper">
        <div className="select__SD field__wrapper mb-0">
          {labelName && (
            <Label
              id={labelName}
              label={labelName}
              labelClass={labelClass}
              required={true}
              labelTextClass={labelTextClass}
            />
          )}
          <AsyncSelect
            loadOptions={loadSuggestions}
            isClearable
            defaultOptions={options}
            inputId={labelName}
            onFocus={() => getOptions(1)}
            isLoading={isLoading}
            classNamePrefix="select__SD"
            className="basic-single"
            onMenuScrollToBottom={onMenuScrollToBottom}
            onChange={(selectedOption) => {
              onCustomChange?.(selectedOption);
            }}
            defaultValue={defaultSelectedValue}
            isMulti={isMultiple}
            ref={selectRef}
          />
        </div>
      </div>
    </>
  );
};

export default AsyncSelectServer;
