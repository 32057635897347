import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import PromptListPage from "modules/Admin/sub-module/AdminPrompt/components/PromptListPage";

const PromptSettingUser = () => {
  return (
    <div>
      <Breadcrumbs path={BREAD_CRUMB.PromptsSetting} />
      <div className="bg-bgWhiteSD view-prompt-modal rounded-[10px] shadow-raiseShadow p-[24px] sm:p-[12px] pb-[16px] mt-[20px]">
        <PromptListPage />
      </div>
    </div>
  );
};
export default PromptSettingUser;
