import DropdownWithIcons, {
  OptionExportType,
} from "components/DropDownWithIcon";

import { ExportFilePropsType } from "../types/transcription.types";
import { PERMISSIONS } from "constants/Permissions.constant";
import SvgIcon from "components/Icon/SvgIcon";
import { useExportTranscriptAPI } from "../services";
import usePermission from "hooks/usePermission";
import { useState } from "react";

const ExportFile = (props: ExportFilePropsType) => {
  const {
    transcript,
    onSideBarClose,
    setOpen,
    is_shared = false,
    shared_id,
  } = props;
  
  const exportApi = useExportTranscriptAPI();
  const { hasPermission } = usePermission();
  const [includeNotes, setIncludeNotes] = useState<boolean>(false);

  const exportFile = async (type: string) => {
    if (type !== "mp3") {
      const exportData = {
        export_type: type,
        transcript_id: transcript.id,
        with_notes: includeNotes,
        shared_token: is_shared ? shared_id : null,
      };
      const verify_token:string | null = localStorage.getItem("verify_token")

      const { data, error } = await exportApi.exportTranscriptAPI(exportData, verify_token);
      if (!error && data) {
        const linkSource = `data:${data?.mimetype};base64,${data?.file}`;
        const downloadLink = document.createElement("a");
        const fileName = `${transcript.file_name}.${type}`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    } else {
      const downloadLink = document.createElement("a");
      downloadLink.href = transcript.media;
      downloadLink.click();
    }
  };

  const options: OptionExportType[] = [
    {
      label: "Download PDF",
      value: "pdf",
      icon: "PdfIcon",
    },
    {
      label: "Download DOCX",
      value: "docx",
      icon: "DocxIcon",
    },
    {
      label: "Download TXT",
      value: "txt",
      icon: "TextFileIcon",
    },
    {
      label: "Download SRT",
      value: "srt",
      icon: "SrtFileIcon",
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-lg mb-2 font-semibold">Export</h3>
      <DropdownWithIcons options={options} onClickFun={exportFile} />
      {!transcript?.is_public && (
        <div className="flex items-center gap-1 mb-[8px]">
          <input
            id="showTimeStamp"
            className="custom-check !ml-[2px]"
            type="checkbox"
            checked={includeNotes}
            onChange={(e) => setIncludeNotes(e.target?.checked)}
          />
          <label htmlFor="showTimeStamp" className="cursor-pointer">
            Include AI notes
          </label>
        </div>
      )}
      <h3 className="text-lg my-2 font-semibold">Others Options</h3>
      <div className="flex mb-3 group download-area">
        <SvgIcon className="w-6 h-6 download-icon" iconName="DownloadIcon" />
        <div className="cursor-pointer ml-2 " onClick={() => exportFile("mp3")}>
          <span className="group-hover:text-primaryColor transition-all duration-500">
            Download AUDIO
          </span>
          <small className="block">
            {(parseInt(transcript?.size) / 1024 ** 2).toFixed(2)} MB
          </small>
        </div>
      </div>
      {hasPermission(PERMISSIONS.NOTES.DELETE) && !is_shared && (
        <div className="flex mb-4 group trash-area">
          <SvgIcon className="w-6 h-6 trash-icon" iconName="TrashIcon" />
          <div
            className="cursor-pointer ml-2 group-hover:text-red-500 transition-all duration-500"
            onClick={() => {
              setOpen(true);
              onSideBarClose();
            }}
          >
            Delete File
          </div>
        </div>
      )}
    </div>
  );
};
export default ExportFile;
