import { useMemo } from "react";

type RenderOptionsProps = {
  filedArray: {
    label: string;
    onClick: () => void;
    Icon?: JSX.Element;
    isDisable?: boolean;
  }[];
  close: () => void;
};

export const renderOptions = (props: RenderOptionsProps) => {
  const { close, filedArray } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const indexedFiledArray = useMemo(() => {
    return filedArray.map((field, index) => ({ ...field, index }));
  }, [filedArray]);
  return (
    <ul className="tippy__dropdown__ul">
      {indexedFiledArray?.map((option) => {
        return (
          <div
            className="item"
            key={`_${option.index}`}
            onClick={() => {
              if (!option.isDisable) {
                option.onClick();
              }
              close();
            }}
          >
            <div className={`${option.isDisable ? "disabled" : ""} item__link`}>
              <span className="item__text">{option.label}</span>
            </div>
          </div>
        );
      })}
    </ul>
  );
};
