// ** Packages **
import React, { Suspense } from "react";
import { RouteObjType } from "routes";
// ** components **
import PageLoader from "components/Loaders/PageLoader";
// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import PermissionGuard from "modules/Auth/components/PermissionGuard";
import { PERMISSIONS } from "constants/Permissions.constant";
import UserDetailView from "./sub-modules/UserManagement/components/UserDetail";
import AddUserModal from "./sub-modules/UserManagement/components/AddUserModal";
import PromptSettingUser from "./sub-modules/PromptSettings";
import PlaybookSettingUser from "./sub-modules/PlaybookSetting";

const applySuspense = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route: RouteObjType) => ({
    ...route,
    element: <Suspense fallback={<PageLoader />}>{route.element}</Suspense>,
  }));
};

// ** Setting  Sub Route **

const PersonalSetting = React.lazy(
  () => import("./sub-modules/PersonalSetting")
);

// ** usermanagment  Sub Route **
const UserManagement = React.lazy(() => import("./sub-modules/UserManagement"));

// ** role and permission sub route **
const RoleAndPermission = React.lazy(
  () => import("./sub-modules/RoleAndPermission")
);

const SettingRoutes = applySuspense([
  {
    path: PRIVATE_NAVIGATION.setting.personalInformation.view,
    element: <PersonalSetting />,
  },
  {
    path: PRIVATE_NAVIGATION.setting.userManagement.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.USER.READ}>
        <UserManagement />
      </PermissionGuard>
    ),
  },

  {
    path: PRIVATE_NAVIGATION.setting.userManagement.detail.view(),
    element: (
      <PermissionGuard permission={PERMISSIONS.USER.READ}>
        <UserDetailView />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.setting.userManagement.edit.view(),
    element: (
      <PermissionGuard permission={PERMISSIONS.USER.READ}>
        <AddUserModal />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.setting.rolesAndPermissions.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.ROLE.READ}>
        <RoleAndPermission />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.setting.prompt.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.CUSTOM_PROMPT.READ}>
        <PromptSettingUser />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.setting.playbook.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.PLAYBOOK.READ}>
        <PlaybookSettingUser />
      </PermissionGuard>
    ),
  },
]);

export default SettingRoutes;
