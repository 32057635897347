import { IconInputProps } from ".";

export const SingleManIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8582 24.9609C25.0055 21.7594 22.0969 19.5129 18.7172 18.5601C20.3603 17.7249 21.6743 16.3606 22.4472 14.6872C23.22 13.0138 23.4068 11.1289 22.9774 9.33637C22.5479 7.54385 21.5273 5.9482 20.08 4.80671C18.6328 3.66523 16.8432 3.04443 15 3.04443C13.1567 3.04443 11.3672 3.66523 9.91996 4.80671C8.4727 5.9482 7.45206 7.54385 7.02261 9.33637C6.59317 11.1289 6.77995 13.0138 7.55283 14.6872C8.32572 16.3606 9.63967 17.7249 11.2828 18.5601C7.90312 19.5117 4.99452 21.7582 3.14179 24.9609C3.09092 25.0409 3.05676 25.1304 3.04137 25.224C3.02597 25.3176 3.02967 25.4133 3.05223 25.5054C3.07479 25.5975 3.11575 25.6841 3.17265 25.76C3.22954 25.8358 3.30119 25.8994 3.38329 25.9469C3.46539 25.9943 3.55625 26.0247 3.65038 26.0361C3.74452 26.0476 3.84 26.0398 3.93107 26.0134C4.02215 25.987 4.10694 25.9424 4.18034 25.8824C4.25374 25.8223 4.31424 25.7481 4.3582 25.664C6.60937 21.7746 10.5867 19.4531 15 19.4531C19.4133 19.4531 23.3906 21.7746 25.6418 25.664C25.6857 25.7481 25.7462 25.8223 25.8196 25.8824C25.893 25.9424 25.9778 25.987 26.0689 26.0134C26.16 26.0398 26.2555 26.0476 26.3496 26.0361C26.4437 26.0247 26.5346 25.9943 26.6167 25.9469C26.6988 25.8994 26.7704 25.8358 26.8273 25.76C26.8842 25.6841 26.9252 25.5975 26.9478 25.5054C26.9703 25.4133 26.974 25.3176 26.9586 25.224C26.9432 25.1304 26.9091 25.0409 26.8582 24.9609ZM8.20312 11.25C8.20312 9.90568 8.60175 8.59158 9.3486 7.47384C10.0954 6.3561 11.157 5.48492 12.3989 4.97049C13.6409 4.45605 15.0075 4.32144 16.326 4.5837C17.6445 4.84596 18.8555 5.4933 19.8061 6.44386C20.7567 7.39442 21.404 8.60551 21.6663 9.92397C21.9285 11.2424 21.7939 12.6091 21.2795 13.851C20.765 15.093 19.8939 16.1545 18.7761 16.9014C17.6584 17.6482 16.3443 18.0469 15 18.0469C13.198 18.0447 11.4705 17.3279 10.1963 16.0537C8.92208 14.7795 8.20529 13.052 8.20312 11.25Z"
        fill="black"
      />
    </svg>
  );
};
