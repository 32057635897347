import { Editor } from "@tiptap/core";
export type CommandTypes = {
  editor: Editor;
  range: any;
};

export type CommandListType = {
  id: number;
  icon: string;
  title: string;
  command: ({ editor, range }: CommandTypes) => void;
};

export const Commands: CommandListType[] = [
  {
    id: 0,
    icon: "H1Icon",
    title: "Heading 1",
    command: ({ editor, range }: CommandTypes) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 1 })

        .run();
    },
  },
  {
    id: 1,
    icon: "H2Icon",
    title: "Heading 2",
    command: ({ editor, range }: CommandTypes) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 2 })

        .run();
    },
  },
  {
    id: 2,
    icon: "H3Icon",
    title: "Heading 3",
    command: ({ editor, range }: CommandTypes) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 3 })
        .run();
    },
  },
  {
    id: 3,
    icon: "H4Icon",
    title: "Heading 4",
    command: ({ editor, range }: CommandTypes) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 4 })
        .run();
    },
  },
  {
    id: 4,
    icon: "ParagraphIcon",
    title: "Paragraph",
    command: ({ editor, range }: CommandTypes) => {
      editor.chain().focus().deleteRange(range).setNode("paragraph").run();
    },
  },
  {
    id: 5,
    icon: "BulletLineIcon",
    title: "Bulleted list",
    command: ({ editor, range }: CommandTypes) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run();
    },
  },
  {
    id: 6,
    icon: "OrderListIcon",
    title: "Numbered list",
    command: ({ editor, range }: CommandTypes) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run();
    },
  },
  {
    id: 7,
    icon: "CodeBlockIcon",
    title: "Code",
    command: ({ editor, range }: CommandTypes) => {
      editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
    },
  },
  {
    id: 8,
    icon: "BulletLineIcon",
    title: "To-do list",
    command: ({ editor, range }: CommandTypes) => {
      editor.chain().focus().deleteRange(range).toggleTaskList().run();
    },
  },
];
