// **  import packages ** //
import { useMemo } from "react";
import { Link } from "react-router-dom";

import "./styles/style.css";

interface Props {
  path: {
    trails: { title: string; path: string, state?: any; }[];
    title: string;
  };
}

export let BREADCRUMB_DATA : { title: string; path: string, state?: any; }[] = []

const Breadcrumbs = (props: Props) => {
  const { path } = props;

  const indexedData = useMemo(() => {
    return path.trails.map((trail, index) => ({ ...trail, index }));
  }, [path.trails]);

  BREADCRUMB_DATA = indexedData

  return (
    <div className="breadcrumbs__wrapper">
      <div className="breadcrumbs">
        {indexedData.map((trail) => (
          <Link key={trail.index} to={trail.path} state={trail?.state} className="breadcrumbLink">
            {trail.title}
          </Link>
        ))}
        <span className="breadcrumbLink active break-all">{path.title}</span>
      </div>
    </div>
  );
};

export default Breadcrumbs;
