const MainLoader = () => {
  return (
    <>
      <div className="bg-[#2e32341a] backdrop-blur-[8px] h-screen w-screen fixed top-0 left-0 z-[15] ">
        <div className="flex items-center justify-center w-full h-full">
          <div className="w-[70px] h-[70px] p-[10px] rounded-full bg-bgWhiteSD shadow-raiseShadow flex items-center justify-center">
            <div className="page__round__loader w-full h-full rounded-full bg-transparent border-[4px] border-l-gray-200 border-r-gray-200 border-t-primaryColor border-b-primaryColor animate-small__loader__sd"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainLoader;
