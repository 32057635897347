// ** Packages **
import { Navigate, useLocation } from "react-router-dom";

// ** Constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import usePermission from "hooks/usePermission";
import React from "react";

type Props = {
  children: JSX.Element | React.ReactNode;
  permission?: {
    type: string;
    name: string;
  };
};

const PermissionGuard = (props: Props) => {
  const { children, permission } = props;
  const location = useLocation();
  const { hasPermission } = usePermission();
  if (permission && !hasPermission(permission)) {
    return (
      <Navigate
        to={PRIVATE_NAVIGATION.dashboard.view}
        state={{ from: location }}
      />
    );
  }
  return <> {children}</>;
};

export default PermissionGuard;
