// ** hooks **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const _ADMIN_NOTES_API_BASE_PATH = "/notes";

export const usePromptListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPromptListApi = async (data: object) => {
    return callApi(`${_ADMIN_NOTES_API_BASE_PATH}/get_all_prompt`, data);
  };

  return { getPromptListApi, isLoading, isError, isSuccess };
};

export const useDeletePromptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deletePromptApi = async (id: number | string) => {
    const response = await callApi(
      `${_ADMIN_NOTES_API_BASE_PATH}/delete_prompt/${id}`,
      {}
    );
    return response;
  };

  return { deletePromptApi, isLoading, isError, isSuccess };
};

export const useStatusPromptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const statusPromptApi = async (id: number | string) => {
    const response = await callApi(
      `${_ADMIN_NOTES_API_BASE_PATH}/activate_prompt/${id}`,
      {}
    );
    return response;
  };

  return { statusPromptApi, isLoading, isError, isSuccess };
};

export const useStatusDefaultPromptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const statusDefaultPromptApi = async (id: number | string) => {
    const response = await callApi(
      `${_ADMIN_NOTES_API_BASE_PATH}/update_prompt/${id}`,
      {}
    );
    return response;
  };

  return { statusDefaultPromptApi, isLoading, isError, isSuccess };
};


export const usePlaybookTemplatesList = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPlaybookListApi = async (data: object, is_active: boolean = false, playbooks_type = []) => {    
    return callApi(`${_ADMIN_NOTES_API_BASE_PATH}/get_all_template?is_active=${is_active}&types=${playbooks_type}`, data);
  };

  return { getPlaybookListApi, isLoading, isError, isSuccess };
};

export const useDeletePlaybookAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deletePlaybookApi = async (id: number | string) => {
    const response = await callApi(
      `${_ADMIN_NOTES_API_BASE_PATH}/delete_playbook/${id}`,
      {}
    );
    return response;
  };

  return { deletePlaybookApi, isLoading, isError, isSuccess };
};

export const useAddTemplatePlaybookAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addTemplatePlaybookAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${_ADMIN_NOTES_API_BASE_PATH}/add_playbook`, data, config);
  };
  return { addTemplatePlaybookAPI, isLoading, isError, isSuccess };
};

export const useUpdateTemplatePlaybookAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateTemplatePlaybookAPI = async (
    data: object,
    id: number | string,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(
      `${_ADMIN_NOTES_API_BASE_PATH}/update_playbook/${id}`,
      data,
      config
    );
  };
  return { updateTemplatePlaybookAPI, isLoading, isError, isSuccess };
};

export const useGetPlaybookTemplateAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPlaybookTemplateApi = async (id: number | string) => {
    return callApi(`${_ADMIN_NOTES_API_BASE_PATH}/get_playbooks_by_id/${id}`);
  };

  return { getPlaybookTemplateApi, isLoading, isError, isSuccess };
};

export const useUpdateStatusTemplatePlaybookAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateStatusTemplatePlaybookAPI = async (
    data: object,
    id: number | string,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(
      `${_ADMIN_NOTES_API_BASE_PATH}/update_status_playbook/${id}`,
      data,
      config
    );
  };
  return { updateStatusTemplatePlaybookAPI, isLoading, isError, isSuccess };
};

export const usePromptOrderChangeAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const getPromptOrderChangeApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${_ADMIN_NOTES_API_BASE_PATH}/reorder_prompt`, data, config);
  };

  return { getPromptOrderChangeApi, isLoading, isError, isSuccess };
};
