import AlertModal from "components/modal/AlertModal";
import { useCallback, useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";

interface Props {
  isDirtyCondition: boolean;
  onSave?: () => void;
  saveBtnLoading?: boolean;
  submitLoading?: boolean;
  submitButtonText?: string;
  promptMessage?: string;
  setDiscardSaveLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  dirtyFields?: any;
  saveFunction?: any;
}
const RouteChangeConformationModal = ({
  isDirtyCondition,
  onSave,
  saveBtnLoading,
  submitButtonText = "Save",
  promptMessage,
  setDiscardSaveLoading,
  dirtyFields,
  submitLoading,
  saveFunction
}: Props) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const confirmNavigation = useCallback(async () => {
    if (dirtyFields && Object.keys(dirtyFields)?.length !== 0) {
      setShowPrompt(false);
    } else {
      await onSave?.();
      if (setDiscardSaveLoading) {
        setDiscardSaveLoading(false);
      }
      setShowPrompt(false);
      if (blocker.state === "blocked") {
        blocker.proceed();
      }
    }
  }, [showPrompt]);

  const onSecondaryButton = async () => {
    saveFunction && saveFunction();
    if (dirtyFields && Object.keys(dirtyFields)?.length !== 0) {
      setShowPrompt(false);
    } else if (blocker.state === "blocked") {
      blocker.proceed();
    }
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirtyCondition && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setShowPrompt(true);
    }
  }, [blocker, isDirtyCondition]);

  return (
    <AlertModal
      visible={showPrompt}
      onClose={cancelNavigation}
      onCancel={cancelNavigation}
      secondaryButtonText={"Discard"}
      onSecondaryButton={onSecondaryButton}
      showFooter={true}
      submitButtonText="Yes"
      showCrossIcon={true}
    >
      <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
        {promptMessage ||
          `The changes you have made might not be saved, do you want to Discard ?`}
      </p>
    </AlertModal>
  );
};

export default RouteChangeConformationModal;
