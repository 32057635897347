import "./App.css";
import "react-phone-input-2/lib/style.css";

// ** Routes **
import Routes from "./routes";
import useAuthGuard from "./modules/Auth/hooks/useAuthGuard";
import PageLoader from "components/Loaders/PageLoader";
import { useSelector } from "react-redux";
import { getAuth } from "redux/slices/authSlice";

function App() {
  const { user, isAuthenticated } = useSelector(getAuth)
  const { isLoading, isAuthInitialized } = useAuthGuard();
  return isLoading || !isAuthInitialized || (isAuthenticated && !user) ? (
    <>
      <PageLoader pageLoaderClassName="min-h-[100vh]" />
    </>
  ) : (
    <>
      <Routes />
    </>
  );
}

export default App;
