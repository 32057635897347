import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import DataTable, { TableColumn, SortOrder } from "react-data-table-component";
type FilterParamsType = {
  page: number;
  perPage: number;
  sortField: string;
  sortOrder: string;
  search: string;
};
type dataTableReactPropsType = {
  columns: any;
  getData:
  | ((params: FilterParamsType) => Promise<{
    rowData: Array<any>;
    rowCount: number;
    has_next?: boolean;
  }>)
  | undefined;
  paginationProps?: boolean;
  loader: boolean;
  default_sortColumn?: string;
  default_asc?: boolean;
  className?: string;
  setTableLength: null | React.Dispatch<React.SetStateAction<number>>;
};

type SortHandler = (
  selectedColumn: TableColumn<any>,
  sortDirection: SortOrder,
  sortedRows: { id: number; first_name: string }[]
) => void;

export type isDefaultPromptType = {
  id: number | string | null;
  isDefault: boolean | null;
}

export type TableRefType = {
  refreshData(): void;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
};
const DataTableReact = forwardRef<TableRefType, dataTableReactPropsType>(
  (props, ref) => {
    const {
      columns,
      getData,
      default_sortColumn,
      default_asc = true,
      paginationProps = true,
      className = "",
      setTableLength = null,
    } = props;

    const [params, setParams] = useState({
      page: 1,
      perPage: 22,
      sortField: default_sortColumn ? default_sortColumn : "",
      sortOrder: default_asc ? "asc" : "desc",
      search: "",
    });
    const [totalData, setTotalData] = useState<{
      data: any[];
      rowCount: number;
      has_next: boolean;
    }>({ data: [], rowCount: 0, has_next: false });
    const myDataRef = useRef(totalData?.data?.length);
    const hasNext = useRef<boolean>(totalData?.has_next);
    let TableElement: any = null;
    let TableElementBody: any = null;
    const tableWrapper = useRef<HTMLInputElement | null>(null)
    const myRefTotal = useRef(totalData?.rowCount);
    const handleSort: SortHandler = (selectedColumn, sortDirection) => {
      if (selectedColumn.sortField) {
        setParams({
          ...params,
          sortField: selectedColumn.sortField,
          sortOrder: sortDirection,
        });
      }
    };

    const handlePageChange = (page: number) => {
      if (page > params.page && (totalData?.data?.length < myRefTotal.current || hasNext.current)) {
        setParams({ ...params, page });
      }
    };

    const handlePerRowsChange = (perPage: number) => {
      setParams({ ...params, perPage, page: 1 });
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchVal = e.target.value.trim();
      setParams({ ...params, search: searchVal, page: 1 });
    };
    const dataTable = async () => {
      if (getData) {
        hasNext.current = false
        const responseData = await getData(params);
        const { rowData, rowCount } = responseData;
        if (rowData) {         
          const data: any[] = params.page === 1 ? rowData : [...totalData.data, ...rowData]
          myDataRef.current = data?.length;
          myRefTotal.current = rowCount;
          const count: number = rowCount;
          if(responseData?.has_next){
            hasNext.current = responseData?.has_next ?? false
          }
          setTotalData({ data: data, rowCount: count, has_next: responseData?.has_next ?? false});
          if (setTableLength !== null) {
            setTableLength(rowData?.length)
          }          
        }
      }
    };



    const InfiniteScrollFn = (event: any) => {
      const bottom = event?.target?.scrollHeight - event?.target?.scrollTop === event?.target?.clientHeight;      
      if (bottom && (myDataRef?.current < myRefTotal?.current || hasNext?.current)) {
        handlePageChange(params.page + 1)
      }
    }
    useEffect(() => {
      return (() => {
        TableElementBody && TableElementBody?.removeEventListener("scroll", InfiniteScrollFn)
        TableElement && TableElement?.removeEventListener("scroll", InfiniteScrollFn)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
      if (!TableElement) {
        TableElement = tableWrapper?.current?.children[0]?.children[0]?.children[0];
        TableElementBody = tableWrapper?.current?.getElementsByClassName("rdt_TableBody")[0];
        TableElement && TableElement?.addEventListener("scroll", (e: any) => InfiniteScrollFn(e))
        TableElementBody && TableElementBody?.addEventListener("scroll", (e: any) => InfiniteScrollFn(e))
      }
    },[totalData])

    useEffect(() => {
      dataTable();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useImperativeHandle(ref, () => ({
      refreshData: () => { setParams({ ...params, page: 1 }); },
      onChange: (e) => onChange(e),
    }));
    
    const dataColumns = columns.filter((item:any)=>{
      return item && item
    })
    
    return (
      <div ref={tableWrapper}>
        <DataTable
          columns={dataColumns}
          data={totalData.data}
          // pagination={paginationProps}
          // paginationServer
          // onChangePage={handlePageChange}
          // onChangeRowsPerPage={handlePerRowsChange}
          sortServer
          // paginationTotalRows={totalData.rowCount}
          persistTableHead={true}
          onSort={handleSort}
          className={className}
          // progressPending={loader}
          // progressComponent={<MainLoader />}
          // subHeader
          responsive
          fixedHeader={true}
        //subHeaderComponent={subHeaderComponentMemo}
        />
      </div>
    );
  }
);

export default memo(DataTableReact);
