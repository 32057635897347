import SelectField from "components/FormField/common/SelectField";
import SelectMenu from "components/SelectMenu";
import Tabs from "components/SelectMenu2";
import Button from "components/Theme/Button";
import { tabsType } from "modules/Patient/sub-modules/Transcription/types/transcription.types";
import { useEffect, useState } from "react";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type TabPropsType = {
  tabs: tabsType[];
  currentTabValue: string;
  onChangeTab: (value: string) => void;
};

const Tab = (props: TabPropsType) => {
  const { currentTabValue, tabs, onChangeTab } = props;

  return (
    <div className="">
      <div className="hidden md:block w-full">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <Tabs
          tabs={tabs}
          currentTabValue={currentTabValue}
          onChangeTab={onChangeTab}
        />
        {/* <select
          id="tabs"
          name="tabs"
          className={classNames(
            tabs?.find((tab) => tab?.value === currentTabValue)?.name ===
              currentTabValue
              ? "bg-indigo-100 text-indigo-700"
              : "text-gray-500 hover:text-gray-700 ",
            "px-3 py-2 text-sm font-medium cursor-pointer border rounded-lg w-full"
          )}
          defaultValue={
            tabs.find((tab) => tab?.value === currentTabValue)?.name
          }
          onChange={(e) => onChangeTab(e?.target?.value)}
        >
          {tabs?.map((tab) => (
            <option
              key={tab?.value}
              value={tab?.value}
              disabled={tab?.disabled}
            >
              {tab?.name}
            </option>
          ))}
        </select> */}
      </div>
      <div className="md:hidden">
        <nav className="flex md:flex-wrap space-x-4 md:gap-y-2" aria-label="Tabs">
          {tabs?.map((tab) => (
            <Button
              key={tab?.name}
              className={classNames(
                tab?.value === currentTabValue
                  ? "bg-indigo-100 text-indigo-700"
                  : "text-gray-500 hover:text-gray-700 ",
                tab?.disabled ? "opacity-50 pointer-events-none" : "",
                "px-3 py-2 text-sm font-medium cursor-pointer rounded-lg "
              )}
              onClick={() => onChangeTab(tab.value)}
              // disabled={tab?.disabled}
            >
              {tab?.name}
              {tab?.disabled && tab?.disableMessage && (
                <>
                  <br />
                  <small>{tab?.disableMessage}</small>
                </>
              )}
            </Button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tab;
