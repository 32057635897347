import React, { useState, useEffect, useMemo } from "react";
type stopWatchPropsType = {
  isRunningTime: boolean;
  isPausedTime: boolean;
};
const StopWatch = (props: stopWatchPropsType) => {
  const { isRunningTime, isPausedTime } = props;
  const [elapsedTime, setElapsedTime] = useState(0);
  const [pauseTime, setPauseTime] = useState(0);
  const [value, setValue] = useState([0, 1, 2, 3]);
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunningTime && !isPausedTime) {
      intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunningTime, isPausedTime]);
  useEffect(() => {
    if (isPausedTime) {
      setPauseTime(elapsedTime);
    }
  }, [isPausedTime, elapsedTime]);

  useMemo(() => {
    if (elapsedTime >= value[value.length - 1]) {
      if (value.length > 0) {
        value.shift();
      }
      value.push(elapsedTime);
    }
    setValue(value);
  }, [elapsedTime]);

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <>
      <div className="text-center font-[500]">
        {formatTime(isPausedTime ? pauseTime : elapsedTime)}
      </div>
      {/* <div className="audiowaves">
        <div className="wavesvalue">
          {value?.map((item, index) => (
            <div className="" key={index}>{item}</div>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default StopWatch;
