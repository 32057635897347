import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";
const AUTH_API_BASE_PATH = "auth";
const NOTES_API_BASE_PATH = "notes";
export const useProfileUpdateAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const profileUpdateApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/update_profile`, data, config);
  };
  return { profileUpdateApi, isLoading, isError, isSuccess };
};

export const useGetTranscriptModel = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getTranscriptModelApi = async (data: object) => {
    return callApi(`${AUTH_API_BASE_PATH}/get_model `, data);
  };

  return { getTranscriptModelApi, isLoading, isError, isSuccess };
};

export const useTranscriptModelUpdateAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const transcriptModelUpdateApi = async (payload: object) => {
    const response = await callApi(
      `${AUTH_API_BASE_PATH}/update_model`,
      payload
    );
    return response;
  };

  return { transcriptModelUpdateApi, isLoading, isError, isSuccess };
};

export const useGetPromptTypeAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getGetPromptTypeApi = async () => {
    return callApi(`${NOTES_API_BASE_PATH}/get_prompt_type`);
  };

  return { getGetPromptTypeApi, isLoading, isError, isSuccess };
};

export const useGetAllNoteTypeAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getNoteTypesApi = async () => {
    return callApi(`${NOTES_API_BASE_PATH}/get_all_notetype`);
  };

  return { getNoteTypesApi, isLoading, isError, isSuccess };
};