// ** Packages **
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Suspense } from "react";
// **  component **
import PageLoader from "components/Loaders/PageLoader";
// ** Redux **
import { getAuth } from "redux/slices/authSlice";
// ** constant **

import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
const RequiresUnAuth = () => {
  // ** Hooks **
  const { isAuthenticated } = useSelector(getAuth);

  if (isAuthenticated) {
    return <Navigate to={PRIVATE_NAVIGATION.dashboard.view} />;
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Outlet />
    </Suspense>
  );
};

export default RequiresUnAuth;
