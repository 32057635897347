import { IconInputProps } from ".";

export const MicrophoneIcon = ({ className }: IconInputProps) => {
  return (
    <svg className={className ?? ""} width="28" height="42" viewBox="0 0 28 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.9833 20.75C27.9833 20.2917 27.6083 19.9167 27.15 19.9167C26.6917 19.9167 26.3167 20.2917 26.3167 20.75C26.3167 27.55 20.7833 33.075 13.9917 33.075C7.19167 33.075 1.66667 27.5417 1.66667 20.75C1.66667 20.2917 1.29167 19.9167 0.833333 19.9167C0.375 19.9167 0 20.2917 0 20.75C0 28.1834 5.83333 34.2667 13.1583 34.7V40.1667H5.80833C5.35 40.1667 4.975 40.5417 4.975 41C4.975 41.4584 5.35 41.8334 5.80833 41.8334H22.175C22.6333 41.8334 23.0083 41.4584 23.0083 41C23.0083 40.5417 22.6333 40.1667 22.175 40.1667H14.825V34.7C22.15 34.2667 27.9833 28.1834 27.9833 20.75Z" fill="#788896"/>
    <path d="M17.2583 9.87502C17.2583 10.3334 17.6333 10.7084 18.0917 10.7084H22.4583V13.8584H18.0917C17.6333 13.8584 17.2583 14.225 17.2583 14.6917C17.2583 15.15 17.6333 15.525 18.0917 15.525H22.4583V18.6667H18.0917C17.6333 18.6667 17.2583 19.0417 17.2583 19.5C17.2583 19.9584 17.6333 20.3334 18.0917 20.3334H22.4583V20.75C22.4583 25.4084 18.6667 29.2084 14 29.2084C9.33332 29.2084 5.54166 25.4084 5.54166 20.75V20.3334H9.90832C10.3667 20.3334 10.7417 19.9584 10.7417 19.5C10.7417 19.0417 10.3667 18.6667 9.90832 18.6667H5.54166V15.525H9.90832C10.3667 15.525 10.7417 15.15 10.7417 14.6917C10.7417 14.225 10.3667 13.8584 9.90832 13.8584H5.54166V10.7084H9.90832C10.3667 10.7084 10.7417 10.3334 10.7417 9.87502C10.7417 9.41669 10.3667 9.04169 9.90832 9.04169H5.54166V8.62502C5.54166 3.96669 9.33332 0.166687 14 0.166687C18.6667 0.166687 22.4583 3.96669 22.4583 8.62502V9.04169H18.0917C17.6333 9.04169 17.2583 9.41669 17.2583 9.87502Z" fill="#788896"/>
  </svg>
  );
};
