import { useEffect, useState } from "react";
import {
  NAME_BADGE_COLOR_COMBINATIONS,
  generateFirstLetter,
} from "components/Badge/Constants";
import { customRandomNumberGenerator } from "utils";

type Props = {
  NoNameLetterWrapperClass?: string;
  NoNameLetterClass?: string;
  FirstName?: string;
  LastName?: string;
  color?: string;
};

const NameBadge = (props: Props) => {
  const {
    NoNameLetterWrapperClass,
    NoNameLetterClass,
    FirstName,
    LastName,
    color,
  } = props;

  // ** States **
  const [combination, setCombination] = useState({
    bgColor: "",
    Color: "",
  });

  useEffect(() => {
    if (color) {
      const matchColor = NAME_BADGE_COLOR_COMBINATIONS.filter(
        (e) => e.Color === color
      )?.[0];
      if (matchColor) {
        setCombination(matchColor);
      }
    } else {
      const randomNumberGenerate = customRandomNumberGenerator(
        NAME_BADGE_COLOR_COMBINATIONS.length
      );
      setCombination(NAME_BADGE_COLOR_COMBINATIONS[randomNumberGenerate]);
    }
  }, [FirstName, LastName]);

  return (
    <>
      <div
        style={{ backgroundColor: `var(--${combination.bgColor})` }}
        className={`noName__letter  ${NoNameLetterWrapperClass}`}
      >
        <span
          style={{ color: `var(--${combination.Color})` }}
          className={`noName__letter__text ${NoNameLetterClass}`}
        >
          {generateFirstLetter(FirstName)}
          {generateFirstLetter(LastName || LastName?.slice(1))}
        </span>
      </div>
    </>
  );
};

export default NameBadge;
