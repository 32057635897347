import { IconInputProps } from ".";

export const ContentListIcon = ({ className }: IconInputProps) => {
  return (
    <svg className={className ?? ""} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V17.13C16 18.7869 14.6569 20.13 13 20.13H3C1.34315 20.13 0 18.7869 0 17.13V3ZM3 3.6842C3 3.13192 3.44772 2.6842 4 2.6842H12C12.5523 2.6842 13 3.13192 13 3.6842V7.05263C13 7.60491 12.5523 8.05263 12 8.05263H4C3.44772 8.05263 3 7.60491 3 7.05263V3.6842ZM3.67105 9.84216C3.30044 9.84216 3 10.1426 3 10.5132C3 10.8838 3.30044 11.1843 3.67105 11.1843H12.3289C12.6996 11.1843 13 10.8838 13 10.5132C13 10.1426 12.6996 9.84216 12.3289 9.84216H3.67105ZM3 13.6447C3 13.2741 3.30044 12.9736 3.67105 12.9736H12.3289C12.6996 12.9736 13 13.2741 13 13.6447C13 14.0153 12.6996 14.3157 12.3289 14.3157H3.67105C3.30044 14.3157 3 14.0153 3 13.6447ZM3.67105 16.1052C3.30044 16.1052 3 16.4057 3 16.7763C3 17.1469 3.30044 17.4473 3.67105 17.4473H9.33836C9.70897 17.4473 10.0094 17.1469 10.0094 16.7763C10.0094 16.4057 9.70897 16.1052 9.33836 16.1052H3.67105Z" fill="#4b5c6b"/>
    </svg>
    
  );
};
