import { IconInputProps } from ".";

export const DashboardIcon = ({ className }: IconInputProps) => {
  return (
    <svg className={className ?? ""} width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="2" height="29" fill="#4B5C6B"/>
      <rect x="8" y="14" width="2" height="15" fill="#4B5C6B"/>
      <rect x="16" y="7" width="2" height="22" fill="#4B5C6B"/>
      <rect x="24" y="22" width="2" height="7" fill="#4B5C6B"/>
    </svg>
  );
};
