import { useEffect } from "react";
import { createPortal } from "react-dom";

// ** CSS **
import "./css/actionModal.css";
import Button from "components/Theme/Button";

type Props = {
  visible: boolean;
  children: React.ReactNode;
  contentClass?: string;
  onClose?: () => void;
  showFooter?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitLoading?: boolean;
  width?: number;
  cancelButtonText?: string;
  cancelButtonClass?: string;
  submitButtonText?: string;
  submitButtonClass?: string;
  customIcon?: any;
  showCrossIcon?: boolean;
  showCancelButton?: boolean;
  onSecondaryButton?: () => void;
  secondaryLoading?: boolean;
  secondaryButtonText?: string;
  secondaryButtonClass?: string;
};

const AlertModal = (props: Props) => {
  const {
    visible = false,
    children,
    contentClass = "",
    showFooter = true,
    submitLoading = false,
    width = 500,
    cancelButtonText = "Cancel",
    cancelButtonClass = "bg-[#4c60e9] text-white",
    submitButtonText = "Save",
    submitButtonClass = "bg-[#4c60e9] text-white",
    customIcon = "deleteFillSD",
    showCrossIcon = false,
    showCancelButton = true,
    secondaryButtonClass = "primary__Btn",
    secondaryButtonText,
    secondaryLoading,
    onSecondaryButton,
  } = props;

  const { onClose, onCancel, onSubmit } = props;

  useEffect(() => {
    const classExist = document.body.classList.contains("active");
    const modalOpenTarget = document.getElementsByTagName("html");
    if (visible && !classExist) {
      modalOpenTarget[0]?.classList.add("modal-open");
    }
    return () => {
      modalOpenTarget[0]?.classList.remove("modal-open");
    };
  });

  return visible ? (
    createPortal(
      <div className={`action__modal__wrapper__SD ${!visible ? "hidden" : ""}`}>
        <div className="action__modal__overlay__SD" onClick={onClose} />

        <div
          className={`action__modal__content__wrapper ${contentClass}`}
          style={{ width: `${width}px` }}
        >
          {showCrossIcon && (
            <button className="modal__close__btn" onClick={onClose}>
              9
            </button>
          )}

          {customIcon ? <></> : null}
          {children}
          {showFooter && (
            <div className="action__btn__wrapper">
              {showCancelButton && <Button
                className={`min-w-[100px] bg-[#4c60e9] text-white`}
                onClick={onCancel}
              >
                {cancelButtonText}
              </Button>
              }
              {onSecondaryButton && (
                <Button
                  className={`${submitButtonClass} min-w-[100px] ml-[12px]`}
                  onClick={onSecondaryButton}
                  loading={secondaryLoading}
                >
                  {secondaryButtonText}
                </Button>
              )}
              {onSubmit && (
                <Button
                  className={`${submitButtonClass} min-w-[100px] ml-[12px]`}
                  onClick={onSubmit}
                  loading={submitLoading}
                >
                  {submitButtonText}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>,
      document.body
    )
  ) : (
    <></>
  );
};

export default AlertModal;
