// ** packages **
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Clock } from "react-feather";

// ** components **
import Button from "components/Theme/Button";
import Spinner from "components/Loaders/Spinner";
import SvgIcon from "components/Icon/SvgIcon";

// ** types **
import {
  CustomPlayerPropsType,
  OnProgressType,
} from "../types/transcription.types";

const CustomPlayer = (props: CustomPlayerPropsType) => {
  const {
    currentTime,
    setCurrentTime,
    media_url,
    textToAudioClickChange,
    redo,
    undo,
    historyIndex,
    historyOfTranscriptReplace,
  } = props;
  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [volume, setVolume] = useState<number>(1);
  const [time, settime] = useState(0);
  const [endTime, setEndTime] = useState<number>(0);
  const playerRef = useRef<ReactPlayer | null>(null);
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    if (textToAudioClickChange) {
      playerRef.current?.seekTo(
        new Date(`1970-01-01 ${textToAudioClickChange}.000Z`).getTime() / 1000
      );
    }
  }, [textToAudioClickChange]);

  const handleProgress = (progress: { played: number }) => {
    if (playing) {
      setPlayed(progress.played);
    }
  };

  const handleSeekChange = (value: number) => {
    setPlayed(value);
  };

  const handleSeekMouseDown = () => {
    setPlaying(false);
  };

  const handleSeekMouseUp = (value: number) => {
    setPlaying(true);
    playerRef.current?.seekTo(value);
  };

  const handlePlaybackSpeedChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPlaybackSpeed(parseFloat(e.target.value));
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleSkipForward = () => {
    const currentTime = playerRef.current?.getCurrentTime() || 0;
    playerRef.current?.seekTo(
      Math.min(currentTime + 10, playerRef.current?.getDuration())
    );
  };

  const handleSkipBackward = () => {
    const currentTime = playerRef.current?.getCurrentTime() || 0;
    playerRef.current?.seekTo(Math.max(0, currentTime - 10));
  };

  const audioOnProgress = (e: OnProgressType) => {
    setCurrentTime(e.playedSeconds * 1000);
  };

  const formatTime = (milliseconds: number): string => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes % 60).padStart(2, "0");
    const formattedSeconds = String(seconds % 60).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div className="px-[15px] lg:w-full w-[60%] my-5">
      <ReactPlayer
        ref={(ref) => (playerRef.current = ref)}
        url={media_url}
        playing={playing}
        controls={false}
        volume={volume}
        playbackRate={playbackSpeed}
        onProgress={(e) => {
          audioOnProgress(e);
          handleProgress(e);
        }}
        seek={time}
        onReady={(reactPlayer) => {
          setEndTime(reactPlayer.getDuration());
        }}
        progressInterval={500}
        width={"100%"}
        height={"0px"}
        onEnded={handlePlayPause}
      />
      <div className="flex flex-col lg:items-start items-end -mx-[15px] sm:items-center">
        <div className="flex xs:flex-col flex-wrap px-[15px] items-start sm:items-center sm:justify-center">
          <div className="flex mr-4 xs:mr-0 mb-3">
            <div className="text-center">
              <Button
                className="p-0"
                onClick={undo}
                disabled={historyIndex === 0}
              >
                <SvgIcon className="w-4 h-4" iconName="UndoIcon" />
              </Button>
              <p className="text-[10px] mt-[3px]">Undo</p>
            </div>
            <div className="text-center ml-2">
              <Button
                className="p-0"
                onClick={redo}
                disabled={
                  historyIndex === historyOfTranscriptReplace.length - 1
                }
              >
                <SvgIcon className="w-4 h-4" iconName="RedoIcon" />
              </Button>
              <p className="text-[10px] mt-[3px]">Redo</p>
            </div>
          </div>
          <div className="px-[15px] border-r border-[#e5e5eb] border-l mb-3">
            <div className="flex items-center">
              <Clock className="mr-1 w-4 h-4" />
              <select
                className="text-[13px] cursor-pointer"
                value={playbackSpeed}
                onChange={handlePlaybackSpeedChange}
              >
                <option value={0.5}>0.5x</option>
                <option value={1}>1x</option>
                <option value={1.5}>1.5x</option>
                <option value={2}>2x</option>
              </select>
            </div>
            <p className=" text-[10px] text-center mt-[6px]">Playback Rate</p>
          </div>
          <div className="flex items-start flex-wrap pl-[15px] xs:pl-0 mb-3">
            <div className="mr-2 text-center">
              <Button onClick={handleSkipBackward} className="p-0">
                <SvgIcon className="w-5 h-5" iconName="SkipBackIcon" />
              </Button>
              <p className="text-[10px]">-10 sec</p>
            </div>
            <Button onClick={handlePlayPause} className="p-0 mr-2">
              {playing ? (
                <SvgIcon className="w-6 h-6" iconName="PauseIcon" />
              ) : (
                <SvgIcon className="w-6 h-6" iconName="PlayIcon2" />
              )}
            </Button>
            <div className="text-center">
              <Button onClick={handleSkipForward} className="p-0">
                <SvgIcon className="w-5 h-5" iconName="SkipForwardIcon" />
              </Button>
              <p className="text-[10px]">+10 sec</p>
            </div>
          </div>

          {/* <div className="flex items-center px-[15px]">
            {volume ? <Volume2 /> : <VolumeX />}
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={volume}
              onChange={handleVolumeChange}
              className="accent-black ml-1"
            />
          </div> */}
        </div>
        <div className="pr-[15px] pl-[30px] lg:pl-[15px] md:min-w-0 min-w-[250px]">
          <input
            type="range"
            className="accent-black w-full"
            min={0}
            max={1}
            step="any"
            value={played}
            onMouseDown={handleSeekMouseDown}
            onMouseUp={() => handleSeekMouseUp(played)}
            onChange={(e) => handleSeekChange(parseFloat(e.target.value))}
          />
          <div className="flex items-center text-sm">
            {formatTime(currentTime)} /{" "}
            {endTime ? (
              formatTime(endTime * 1000)
            ) : (
              <Spinner smallSpinnerClassName={`mr-[10px]`} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPlayer;
