// ** packages **
import { RowData } from "components/DataTable/components/DraggableTable";
import { useEffect, useMemo, useState } from "react";
import { firstCharUpperCase } from "utils";
import { format as Format } from "date-fns";
import TippyDropdown from "components/TippyDropDown";
import { renderOptions } from "components/TippyDropDown/helper";
import { MoreVertical } from "react-feather";
import store from "redux/store";
import { PERMISSIONS } from "constants/Permissions.constant";
import usePermission from "hooks/usePermission";
import DateFormat from "components/DateFormat";
import TranscriptionStatus from "components/Badge/TranscriptionStatus";
import TableCellRender from "components/DataTable/components/TableCellRender";

const PromptColumnDraggable = (props: any) => {
    const { onChangeStatus, setIsOpen } = props;
    const storeData = store.getState();
    const is_super_user = storeData?.auth?.user?.is_super_administrator;
    const { hasPermission } = usePermission();
    const customPromptUpdateRole = hasPermission(
        PERMISSIONS.CUSTOM_PROMPT.UPDATE
    );
    const customPromptDeletePermission = hasPermission(
        PERMISSIONS.CUSTOM_PROMPT.DELETE
    );

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const handleResize = () => {
        if (
            (window.innerWidth > 1300 && windowSize < 1300) ||
            (window.innerWidth < 1300 && windowSize >= 1300)
        ) {
            setWindowSize(window.innerWidth);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowSize]);


    const columnDefs = useMemo(
        () => windowSize > 1300 ? [
            {
                name: "name",
                label: "Name",
                mainClass: "py-3 text-left text-sm font-medium cursor-pointer",
                childClass: "brJIkt",
                showField: true,
                sortAllow: false,
                cell: (row: RowData) => (
                    <div className="flex items-center whitespace-break-spaces break-all">
                        {row?.name}
                        {row?.is_draft && (
                            <p className="text-[12px] font-normal text-white bg-[#1ac01a] py-1 px-2 rounded-full ml-2">
                                Draft
                            </p>
                        )}
                    </div>
                )

            },
            {
                name: "type",
                label: "Type",
                mainClass: "py-3 text-left text-sm font-medium cursor-pointer",
                childClass: "brJIkt",
                showField: true,
                sortAllow: false,
                cell: (row: RowData) => (
                    <div>
                        {row?.types === "Summary" ? "Patient" : firstCharUpperCase(row?.types)}
                    </div>
                )
            },
            {
                name: "created_date",
                label: "Created Date",
                mainClass: "py-3 text-left text-sm font-medium cursor-pointer",
                childClass: "brJIkt",
                showField: true,
                sortAllow: false,
                cell: (row: RowData | any) => (
                    <div >{Format(new Date(row?.created_at), "MM/dd/yyyy")}</div>
                )
            },
            {
                name: "created_by",
                label: "Created By",
                mainClass: "py-3 text-left text-sm font-medium cursor-pointer",
                childClass: "brJIkt",
                showField: true,
                sortAllow: false,
                cell: (row: RowData) => (
                    <div className="truncate">
                        {row?.static ? "System Default" : (row?.created?.email || "-")}
                    </div>
                )
            },
            {
                name: "status",
                label: "Status",
                mainClass: "py-3 text-left text-sm font-medium cursor-pointer flex justify-center",
                childClass: "brJIkt",
                showField: true,
                sortAllow: false,
                cell: (row: RowData) => (
                    <div className={`text-center ${row?.is_deactivate ? 'text-red-600' : 'text-blue-500'}`}>
                        {firstCharUpperCase(row?.is_deactivate ? "Inactive" : "Active")}
                    </div>
                )
            },
            {
                name: "action",
                label: "Action",
                mainClass: "py-3 text-left text-sm font-medium cursor-pointer flex justify-start",
                childClass: "brJIkt",
                showField: true,
                sortAllow: false,
                cell: (row: RowData) => {
                    const promptAdd: {
                        label: string;
                        onClick: () => void;
                    }[] = [];

                    ((row?.static && !is_super_user) && row?.static) ? <></> : promptAdd.push({
                        label:
                            row?.is_draft && customPromptUpdateRole
                                ? "Save Prompt"
                                : "View Prompt",
                        onClick: () => {
                            setIsOpen({
                                open: true,
                                id: row?.id,
                                isDefault: row?.static ?? false,
                                viewOnly: row?.is_draft ? !customPromptUpdateRole : true,
                            });
                        },
                    });
                    customPromptUpdateRole &&
                        !row?.is_draft &&
                        promptAdd.push({
                            label: row?.is_deactivate ? "Active" : "Inactive",
                            onClick: () => {
                                onChangeStatus(
                                    String(row?.id),
                                    "status",
                                    row?.is_deactivate ? "Active" : "Inactive",
                                    row?.static
                                );
                            },
                        });

                    customPromptDeletePermission &&
                        (!row?.static || is_super_user) &&
                        promptAdd.push({
                            label: "Delete Prompt",
                            onClick: () => {
                                onChangeStatus(row?.id, "delete", null, row?.static);
                            },
                        });
                    return (
                        <TippyDropdown
                            content={({ close }) =>
                                renderOptions({ close, filedArray: promptAdd })
                            }
                        >
                            <MoreVertical />
                        </TippyDropdown>
                    );
                }
            }
        ] : [
            {
                name: "name",
                label: "Name",
                mainClass: "py-3 text-left text-sm font-medium cursor-pointer",
                childClass: "brJIkt",
                showField: true,
                isWindowSizeTwo: true,
                sortAllow: false,
                cell: (row: RowData) => (
                    <TableCellRender
                        className="flex overflow-hidden md:w-[60vw]"
                        cellRowData={
                            <>
                                <div
                                    key={row?.id}
                                    className="container mx-auto px-4 py-8 cursor-pointer"
                                >
                                    <div className="flex gap-2 items-center">
                                        <span className="font-medium text-gray-900 truncate">{row.name}</span>
                                        {row?.is_draft && (
                                            <p className="text-[12px] font-normal text-white bg-[#1ac01a] py-1 px-2 rounded-full ml-2">
                                                Draft
                                            </p>
                                        )}
                                        &#x2022;<div className={`text-center ${row?.is_deactivate ? 'text-red-600' : 'text-blue-500'}`}>
                                            {firstCharUpperCase(row?.is_deactivate ? " Inactive" : " Active")}
                                        </div>
                                        &#x2022;<div>
                                            {row?.types === "Summary" ? "Patient" : firstCharUpperCase(row?.types)}
                                        </div>
                                    </div>
                                    <div className="lg:ml-0 lg:mt-1">
                                        <div className="flex gap-2 truncate mt-1 text-gray-500">
                                            {row?.static ?<span>System Default</span> : (row?.created && <span>Uploaded by {firstCharUpperCase(row?.created?.first_name) + " " + firstCharUpperCase(row?.created?.last_name)}</span>)}
                                            &#x2022;{" "}{row.created_at && <DateFormat date={row.created_at} format="dd MMM yyyy" />}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />
                )
            },
            {
                name: "action",
                label: "Action",
                mainClass: "py-3 text-left text-sm font-medium cursor-pointer flex justify-start",
                childClass: "brJIkt",
                showField: true,
                isWindowSizeTwo: true,
                sortAllow: false,
                cell: (row: RowData) => {
                    const promptAdd: {
                        label: string;
                        onClick: () => void;
                    }[] = [];

                    ((row?.static && !is_super_user) && row?.static) ? <></> : promptAdd.push({
                        label:
                            row?.is_draft && customPromptUpdateRole
                                ? "Save Prompt"
                                : "View Prompt",
                        onClick: () => {
                            setIsOpen({
                                open: true,
                                id: row?.id,
                                isDefault: row?.static ?? false,
                                viewOnly: row?.is_draft ? !customPromptUpdateRole : true,
                            });
                        },
                    });
                    customPromptUpdateRole &&
                        !row?.is_draft &&
                        promptAdd.push({
                            label: row?.is_deactivate ? "Active" : "Inactive",
                            onClick: () => {
                                onChangeStatus(
                                    String(row?.id),
                                    "status",
                                    row?.is_deactivate ? "Active" : "Inactive",
                                    row?.static
                                );
                            },
                        });

                    customPromptDeletePermission &&
                        (!row?.static || is_super_user) &&
                        promptAdd.push({
                            label: "Delete Prompt",
                            onClick: () => {
                                onChangeStatus(row?.id, "delete", null, row?.static);
                            },
                        });
                    return (
                        <TippyDropdown
                            content={({ close }) =>
                                renderOptions({ close, filedArray: promptAdd })
                            }
                        >
                            <MoreVertical />
                        </TippyDropdown>
                    );
                }
            }
        ], [windowSize]);
    return columnDefs;
};

export default PromptColumnDraggable;




