import { IconInputProps } from ".";

export const JustifyalignIcon = ({ className }: IconInputProps) => {
  return (
    <svg className={className ?? ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 18H21V16H3V18ZM3 11V13H21V11H3ZM3 6V8H21V6H3Z" fill="black"/>
    </svg>

  )
}
