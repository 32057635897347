import { IconInputProps } from ".";

export const StopIcon = ({ className }: IconInputProps) => {
  return (
    <svg className={className ?? ''} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30.8036" cy="30.8036" r="24.375" fill="#D2455C" />
    <rect x="21.4286" y="21.4286" width="18.2143" height="18.2143" fill="white" />
  </svg>
  )
}
