import { IconInputProps } from ".";

export const H4Icon = ({ className }: IconInputProps) => {
  return (

    <svg className={className ?? ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_248_954)">
        <path d="M2.55114 12.5227V19H0.427557V4.45455H2.52273V9.86648H2.65767C2.91335 9.27936 3.30398 8.81297 3.82955 8.46733C4.35511 8.12169 5.04167 7.94886 5.8892 7.94886C6.63731 7.94886 7.29072 8.10275 7.84943 8.41051C8.41288 8.71828 8.84848 9.17756 9.15625 9.78835C9.46875 10.3944 9.625 11.152 9.625 12.0611V19H7.50142V12.3168C7.50142 11.5166 7.29545 10.8963 6.88352 10.456C6.47159 10.0109 5.89867 9.78835 5.16477 9.78835C4.66288 9.78835 4.21307 9.89489 3.81534 10.108C3.42235 10.321 3.11222 10.6335 2.88494 11.0455C2.66241 11.4527 2.55114 11.9451 2.55114 12.5227ZM12.125 16.1591V14.3835L18.4105 4.45455H19.8097V7.06818H18.9219L14.4261 14.1847V14.2983H23.0412V16.1591H12.125ZM19.0213 19V15.6193L19.0355 14.8097V4.45455H21.1165V19H19.0213Z" fill="#343A40"/>
        </g>
        <defs>
        <clipPath id="clip0_248_954">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  )
}
