import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "../SelectMenu";

type TabPropsType = {
    tabs: tabsType[];
    currentTabValue: string;
    onChangeTab: (value: string) => void;
};

export type tabsType = {
    name: string;
    value: string;
    disabled?: boolean;
    disableMessage?: string;
};

const Tabs = ({ tabs, currentTabValue, onChangeTab }: TabPropsType) => {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | any>(null);

    const handleClickOutside = (event: MouseEvent | any) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node) &&
            event?.target.id !== "x-selectMenu-button"
        ) {
            setOpen(false);            
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleTabClick = (value: string) => {
        onChangeTab(value);
        setOpen(false);
    };

    return (
        <div className="relative inline-flex">
            <button
                type="button"
                id="x-selectMenu-button"
                className={`px-3 inline-flex py-2 min-w-[120px] justify-between text-sm font-medium rounded-lg bg-indigo-100 text-indigo-700 cursor-pointer ${tabs.find((tab) => tab.value === currentTabValue)?.name ===
                    currentTabValue
                    ? ""
                    : "hover:text-gray-700"
                    }`}
                onClick={() => {setOpen(!open);
                }}
            >
                {tabs?.find((tab) => tab?.value === currentTabValue)?.name}
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {open && <ul
                className={`absolute z-10 mt-10 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 origin-top-right w-full`}
                ref={dropdownRef}
            >
                {tabs?.map((tab) => (
                    <li
                        key={tab?.value}
                        className={`text-gray-900 w-full grid grid-flow-row cursor-pointer select-none p-2 hover:bg-indigo-100 break-all text-sm font-medium ${tab.disabled ? "text-gray-500 cursor-not-allowed" : ""
                            } ${tab?.value === currentTabValue && "text-indigo-600 bg-indigo-200"}`}
                        onClick={() => (tab.disabled ? undefined : handleTabClick(tab.value))}
                    >
                        <span className="row-1">{tab.name}</span>
                        {tab.disabled && <span className="ml-2 text-gray-500 text-sm row-2">{tab.disableMessage}</span>}
                    </li>
                ))}
            </ul>}
        </div>
    );
};

export default Tabs;
