// ** packages **
import { useAxiosGet } from "hooks/useaxios";

const TRANSCRIPT_API_BASE_PATH = "/transcript";

export const useAllTranscriptListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAllTranscriptListAPI = async (data: object) => {
    return callApi(`${TRANSCRIPT_API_BASE_PATH}/get_all_transcript`, data);
  };

  return { getAllTranscriptListAPI, isLoading, isError, isSuccess };
};