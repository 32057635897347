import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const NOTE_API_BASE_PATH = "/notes";

export const useSummaryGenerateAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const summaryGenerateApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${NOTE_API_BASE_PATH}/generate_notes`, data, config);
  };
  return { summaryGenerateApi, isLoading, isError, isSuccess };
};

export const useSharedSummaryGetAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const sharedSummaryGetApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${NOTE_API_BASE_PATH}/get_shared_notes`, data, config);
  };
  return { sharedSummaryGetApi, isLoading, isError, isSuccess };
};

export const useSummaryTypeGetAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getSummaryTypeApi = async (id: number | string) => {
    return callApi(`${NOTE_API_BASE_PATH}/get_notestype/${id}`);
  };

  return { getSummaryTypeApi, isLoading, isError, isSuccess };
};

export const useSharedSummaryTypeGetAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getSharedSummaryTypeApi = async (token: string, verify_token?: string | null) => {
    return callApi(`${NOTE_API_BASE_PATH}/get_shared_notestype/${token}?verify_token=${verify_token}`);
  };

  return { getSharedSummaryTypeApi, isLoading, isError, isSuccess };
};

export const useAddCustomPromptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addCustomPromptApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${NOTE_API_BASE_PATH}/add_custom_prompt`, data, config);
  };
  return { addCustomPromptApi, isLoading, isError, isSuccess };
};

export const useUpdateCustomPromptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateCustomPromptApi = async (
    data: object,
    id: number | string,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(
      `${NOTE_API_BASE_PATH}/edit_custom_prompt/${id}`,
      data,
      config
    );
  };
  return { updateCustomPromptApi, isLoading, isError, isSuccess };
};

export const useGetCustomPromptAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getCustomPromptApi = async (id: number | string) => {
    return callApi(`${NOTE_API_BASE_PATH}/get_custom_prompt/${id}`);
  };

  return { getCustomPromptApi, isLoading, isError, isSuccess };
};

export const useDraftCustomPromptAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const draftCustomPromptApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${NOTE_API_BASE_PATH}/save_draft_prompt`, data, config);
  };
  return { draftCustomPromptApi, isLoading, isError, isSuccess };
};

export const useUpdateNotesAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateNotesApi = async (
    data: object,
    id: number | string,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${NOTE_API_BASE_PATH}/save_note/${id}`, data, config);
  };
  return { updateNotesApi, isLoading, isError, isSuccess };
};

export const useGetPlayBookAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPlayBookApi = async (id: number | string) => {
    return callApi(`${NOTE_API_BASE_PATH}/get_playbooks/${id}`);
  };

  return { getPlayBookApi, isLoading, isError, isSuccess };
};

export const useUpdatePlayBookAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updatePlaybookApi = async (
    data: object,
    id: number | string,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${NOTE_API_BASE_PATH}/update_playbook/${id}`, data, config);
  };
  return { updatePlaybookApi, isLoading, isError, isSuccess };
};
