// packages **
import { useState } from "react";

// components **
import VerifyEmail from "./ResendMail";
import RegisterDetail from "./RegisterDetail";

// ** constant **
import { REGISTER_STEP } from "modules/Auth/constant";

const RegisterForm = () => {
  const [currentStep, setCurrentStep] = useState<string>(
    REGISTER_STEP.REGISTER_FORM
  );

  const renderSection = (section: string) => {
    if (REGISTER_STEP.VERIFY_EMAIL === section) {
      return <VerifyEmail />;
    } else {
      return <RegisterDetail setCurrentStep={setCurrentStep} />;
    }
  };

  return renderSection(currentStep);
};
export default RegisterForm;
