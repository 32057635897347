// ** packages **
import { useAxiosGet, useAxiosPost } from "hooks/useaxios";

const TRANSCRIPT_API_BASE_PATH = "/transcript";

export const useRecentTranscriptListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getRecentTranscriptListAPI = async (data: object) => {
    return callApi(`${TRANSCRIPT_API_BASE_PATH}/recent_transcripts`, data);
  };

  return { getRecentTranscriptListAPI, isLoading, isError, isSuccess };
};

export const useSingleTranscriptGetAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getSingleTranscriptGetAPI = async (id: any) => {
    return callApi(`${TRANSCRIPT_API_BASE_PATH}/get_transcript/${id}`);
  };

  return { getSingleTranscriptGetAPI, isLoading, isError, isSuccess };
};

export const useSharedTranscriptGetAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();

  const getSharedTranscriptGetAPI = async (data: object) => {
    return callApi(`${TRANSCRIPT_API_BASE_PATH}/shared_transcript`, data);
  };

  return { getSharedTranscriptGetAPI, isLoading, isError, isSuccess };
};
