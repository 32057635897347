// ** packages **
import { useRef, useState } from "react";
import { Plus, Search } from "react-feather";
import { debounce } from "lodash";
// ** components **
import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Theme/Button";
import AddPatientForm from "./AddPatientForm";
import DataTableReact, { TableRefType } from "components/DataTable";
// ** hooks **
import usePatientColumn from "../hooks/usePatientColumn";
// ** services **
import { usePatientListAPI } from "../services";
// ** constants **
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "constants/navigation.constant";
import { useNavigate } from "react-router-dom";
import SelectMenu, { optionsType } from "components/SelectMenu";
import { NoteOptions } from "modules/DashBoard/sub-modules/Notes";
import NoteSideBar from "modules/DashBoard/sub-modules/Notes/components/NoteSideBar";

const PatientTable = () => {
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isSideOpen, setIsSideOpen] = useState<boolean>(false);
  const tableRef = useRef<TableRefType>(null);
  const columnDefs = usePatientColumn();
  const { getPatientListApi, isLoading } = usePatientListAPI();
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const createPatientPermission = hasPermission(PERMISSIONS.PATIENT.CREATE);
  const createNotesPermission = hasPermission(PERMISSIONS.NOTES.CREATE);
  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getPatientListApi({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: params.search,
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }
    return tableData;
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tableRef.current?.onChange?.(e);
  };

  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  const onSelect = (value: optionsType) => {
    if (value?.title && value?.title.toLowerCase() !== "patient") {
      navigate(PRIVATE_NAVIGATION.notes.view, {
        state: {
          note_type: value?.title?.toLowerCase(),
          navHighlighter: value?.title?.toLowerCase(),
        },
      });
    }
  };
  return (
    <div>
      <NoteSideBar
        setIsSideOpen={setIsSideOpen}
        tableRef={tableRef}
        isSideOpen={isSideOpen}
        note_type={"patient"}
      />
      <div>
        <Breadcrumbs path={BREAD_CRUMB.AllPatient} />
      </div>
      <div className="flex md:flex-col items-center md:items-start justify-between mt-[15px] mb-[15px]">
        <div className="form__group">
          <div className="field__wrapper mb-0">
            <div className="field__inner__wrapper field__has__icon icon__position__left">
              <input
                className="input__DSD"
                type="text"
                placeholder="Search"
                onChange={debounce(onSearchChange, 300)}
              />
              <div className="icon__wrapper">
                <Search />
              </div>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-col sm:items-start pr-[10px] ml-2 gap-2 items-end">
          {createPatientPermission && (
            <Button
              className="btn_with_color_fill"
              onClick={() => {
                setOpenForm(true);
              }}
            >
              Add Patient
            </Button>
          )}
          <SelectMenu
            publishingOptions={NoteOptions}
            onSelect={onSelect}
            title={createNotesPermission ? "New Note" : undefined}
            titleIcon={
              createNotesPermission ? (
                <Plus className="inline mx-1 size-sm" />
              ) : undefined
            }
            wantCheckInTitle={!createNotesPermission}
            needHover={false}
            selected={{ id: null, title: "patient" }}
            wantTickFromStatus={false}
            onTextClick={() => setIsSideOpen(true)}
            titleDivClass="cursor-pointer"
          />
        </div>
      </div>
      {openForm && hasPermission(PERMISSIONS.PATIENT.CREATE) && (
        <AddPatientForm setIsOpen={setOpenForm} refreshData={refreshTable} />
      )}
      <div className="reactDataTable__DSD patientlist">
        <DataTableReact
          setTableLength={null}
          ref={tableRef}
          columns={columnDefs}
          loader={isLoading}
          getData={(params: {
            page: number;
            perPage: number;
            sortField: string;
            sortOrder: string;
            search: string;
          }) => getTableData(params)}
        />
      </div>
    </div>
  );
};
export default PatientTable;
