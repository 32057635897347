// packages **
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// ** components **
import AuthHeaderCard from "modules/Auth/components/AuthHeaderCard";
import AuthMainCard from "modules/Auth/components/AuthMainCard";
import { REGISTER_STEP } from "modules/Auth/constant";
import AuthBodyCard from "modules/Auth/components/AuthBodyCard";
import InputField from "components/FormField/common/inputField";
import EmailField from "components/FormField/common/EmailField";
import PasswordField from "components/FormField/common/PasswordField";
import Button from "components/Theme/Button";

// ** services **
import { useRegisterAPI } from "modules/Auth/services";

// ** validation-schema **
import { registerSchema } from "../validation-schema";

// ** types **
import { RegisterFormField } from "../types";

// ** constant **
import { PUBLIC_NAVIGATION } from "constants/navigation.constant";

type propsType = {
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
};

const RegisterDetail = (props: propsType) => {
  // ** custom hook */
  const { setCurrentStep } = props;
  const navigate = useNavigate();
  const { registerAPI, isLoading: registerLoading } = useRegisterAPI();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormField>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = handleSubmit(async (registerData: RegisterFormField) => {
    const registerUserData = {
      first_name: registerData.firstName,
      last_name: registerData.lastName,
      email: registerData.email,
      password: registerData.password,
    };

    const { data, error }: any = await registerAPI(registerUserData);
    if (data && !error) {
      localStorage.setItem("UserMail", data.email);
      setCurrentStep(REGISTER_STEP.VERIFY_EMAIL);
    }
  });

  return (
    <AuthMainCard>
      <AuthHeaderCard
        header="Create Account"
        subHeading="Please provide necessary Details to Complete the Sign Up Process"
      ></AuthHeaderCard>

      <AuthBodyCard>
        <form className="" onSubmit={onSubmit}>
          <InputField
            id="firstName"
            name="firstName"
            label="First Name"
            register={register}
            required
            type="text"
            placeholder="Enter your first name"
            errors={errors.firstName}
          />
          <InputField
            id="lastName"
            name="lastName"
            label="Last Name"
            register={register}
            required
            type="text"
            placeholder="Enter your last name"
            errors={errors.lastName}
          />

          <EmailField
            id="email"
            name="email"
            type="text"
            label=" Email address"
            register={register}
            required
            errors={errors.email}
            placeholder="Enter your email"
          />
          <PasswordField
            id="password"
            name="password"
            type="password"
            label="Password"
            register={register}
            required
            errors={errors.password}
            placeholder="Enter your password"
          />
          <PasswordField
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            required
            errors={errors.confirmPassword}
            placeholder="Enter your confirm password"
            register={register}
          />
          <div className="submit__btn__wrapper">
            <Button
              type="submit"
              loading={registerLoading}
              className={`primary__Btn w-full`}
            >
              Register
            </Button>
          </div>
        </form>
        <div className="flex justify-center mt-[22px]">
          <p className="text-textSecondary">
            Already have an account?
            <span
              onClick={() => {
                navigate(PUBLIC_NAVIGATION.login);
              }}
              className="inline-block ml-[5px] cursor-pointer text-primaryColor hover:underline"
            >
              Login
            </span>
          </p>
        </div>
      </AuthBodyCard>
    </AuthMainCard>
  );
};
export default RegisterDetail;
