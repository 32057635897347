// ** packages **
import { useSelector } from "react-redux";

// ** Redux **
import { getPopupToast } from "redux/slices/toastSlice";

import { Tv } from "react-feather";

const PopupMessage = () => {
  const toastMessage = useSelector(getPopupToast);
  return (
    <>
      {toastMessage.length ? (
        <div className="fixed top-[10px] left-[50%] translate-x-[-50%] z-[99]">
          {toastMessage.map((toast) => (
            <div
              className="flex items-center bg-white rounded-[10px] shadow-raiseShadow py-[10px] px-[15px]"
              key={toast.id}
            >
              <Tv className="text-red-600" />
              <span className="text inline-block ml-[12px]">
                {toast.message}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PopupMessage;
