// ** packages **
import * as yup from "yup";
// ** error message  **
import {
  PhoneNumberError,
  addPatientModelSchemaError,
} from "constants/formErrorMessage.constant";
import { basicSchema } from "modules/Setting/sub-modules/PersonalSetting/schema/personalInformation-validation";

export const addPatientSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(addPatientModelSchemaError.name.name)
    .min(3, addPatientModelSchemaError.name.minLengthName),

  phoneNumber: basicSchema.phone.required(PhoneNumberError.required).optional().nullable(),
  email: yup
    .string()
    .email(addPatientModelSchemaError.email.valid)
    .required(addPatientModelSchemaError.email.required),
  patient_image: yup.mixed<File | string >().nullable(),
});
