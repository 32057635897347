import { IconInputProps } from ".";

export const PendingIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4375 8.86406V4.6875C23.4375 4.19022 23.24 3.71331 22.8883 3.36167C22.5367 3.01004 22.0598 2.8125 21.5625 2.8125H8.4375C7.94022 2.8125 7.46331 3.01004 7.11167 3.36167C6.76004 3.71331 6.5625 4.19022 6.5625 4.6875V8.90625C6.56313 9.19722 6.63118 9.48409 6.76131 9.74434C6.89144 10.0046 7.0801 10.2312 7.3125 10.4063L13.4379 15L7.3125 19.5938C7.0801 19.7688 6.89144 19.9954 6.76131 20.2557C6.63118 20.5159 6.56313 20.8028 6.5625 21.0938V25.3125C6.5625 25.8098 6.76004 26.2867 7.11167 26.6383C7.46331 26.99 7.94022 27.1875 8.4375 27.1875H21.5625C22.0598 27.1875 22.5367 26.99 22.8883 26.6383C23.24 26.2867 23.4375 25.8098 23.4375 25.3125V21.1359C23.4368 20.8461 23.3693 20.5603 23.2403 20.3007C23.1112 20.0412 22.9241 19.8149 22.6934 19.6395L16.5551 15L22.6934 10.3594C22.9242 10.1843 23.1114 9.95823 23.2405 9.69885C23.3696 9.43947 23.437 9.15378 23.4375 8.86406ZM8.4375 4.6875H21.5625V8.86406L20.8863 9.375H9.06211L8.4375 8.90625V4.6875ZM15 13.8281L11.5629 11.25H18.4066L15 13.8281ZM21.5625 25.3125H8.4375V21.0938L14.0625 16.875V19.6875C14.0625 19.9361 14.1613 20.1746 14.3371 20.3504C14.5129 20.5262 14.7514 20.625 15 20.625C15.2486 20.625 15.4871 20.5262 15.6629 20.3504C15.8387 20.1746 15.9375 19.9361 15.9375 19.6875V16.8844L21.5625 21.1359V25.3125Z"
        fill="black"
      />
    </svg>
  );
};
