import { IconInputProps } from ".";

export const FailedIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_44_76)">
        <path
          d="M15.0001 0.00012207C6.72901 0.00012207 0 6.72903 0 15C0 23.271 6.72901 29.9999 15.0001 29.9999C23.2711 29.9999 30 23.271 30 15C30 6.72903 23.2711 0.00012207 15.0001 0.00012207ZM15.0001 27.5409C8.0849 27.5409 2.459 21.9152 2.459 15C2.459 8.08492 8.0849 2.45912 15.0001 2.45912C21.9152 2.45912 27.541 8.08492 27.541 15C27.541 21.9152 21.9152 27.5409 15.0001 27.5409Z"
          fill="black"
        />
        <path
          d="M9.43613 20.5638C9.64958 20.7773 9.92926 20.884 10.209 20.884C10.4887 20.884 10.7685 20.7772 10.9819 20.5638L14.9999 16.5457L19.0181 20.5638C19.2315 20.7773 19.5112 20.884 19.7909 20.884C20.0707 20.884 20.3503 20.7772 20.5638 20.5638C20.9907 20.137 20.9907 19.4449 20.5638 19.0181L16.5456 15L20.5637 10.9819C20.9905 10.5551 20.9905 9.86297 20.5637 9.43617C20.1369 9.00932 19.4449 9.00932 19.018 9.43617L14.9999 13.4543L10.9818 9.43617C10.555 9.00932 9.86287 9.00932 9.43607 9.43617C9.00922 9.86302 9.00922 10.5551 9.43607 10.9819L13.4542 15L9.43607 19.0181C9.00927 19.4449 9.00927 20.137 9.43613 20.5638Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_44_76">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
