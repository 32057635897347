import { IconInputProps } from ".";

export const VoiceNotesDarkIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
      className="fill-black"
        d="M27.1875 11.25C27.1875 10.7527 26.99 10.2758 26.6383 9.92418C26.2867 9.57254 25.8098 9.375 25.3125 9.375H21.5625V5.625C21.5625 5.12772 21.365 4.65081 21.0133 4.29917C20.6617 3.94754 20.1848 3.75 19.6875 3.75H4.6875C4.19022 3.75 3.71331 3.94754 3.36167 4.29917C3.01004 4.65081 2.8125 5.12772 2.8125 5.625V20.625C2.81305 20.8014 2.86334 20.974 2.9576 21.1231C3.05186 21.2722 3.18626 21.3916 3.34537 21.4678C3.50447 21.5439 3.68182 21.5736 3.85704 21.5534C4.03227 21.5333 4.19825 21.4641 4.33594 21.3539L8.4375 18.0469V21.5625C8.4375 22.0598 8.63504 22.5367 8.98668 22.8883C9.33831 23.24 9.81522 23.4375 10.3125 23.4375H21.2801L25.6641 26.9789C25.8299 27.1131 26.0366 27.1867 26.25 27.1875C26.4986 27.1875 26.7371 27.0887 26.9129 26.9129C27.0887 26.7371 27.1875 26.4986 27.1875 26.25V11.25ZM22.2012 21.7711C22.0353 21.6369 21.8286 21.5633 21.6152 21.5625H10.3125V17.8125H19.6875C20.1848 17.8125 20.6617 17.615 21.0133 17.2633C21.365 16.9117 21.5625 16.4348 21.5625 15.9375V11.25H25.3125V24.2871L22.2012 21.7711Z"
        fill="#9eadba"
      />
    </svg>
  );
};
