// ** packages **
import SvgIcon from "components/Icon/SvgIcon";

type RecordingButtonPropType = {
  deleteRecording: () => void;
  stopRecording: () => void;
  pauseResumeRecording: () => void;
  restartRecording: () => void;
  isPaused: boolean;
  isRecording?: boolean;
  className?: string;
};
const RecordingButtonPage = (props: RecordingButtonPropType) => {
  const {
    isPaused,
    isRecording,
    stopRecording,
    pauseResumeRecording,
    restartRecording,
    deleteRecording,
    className = "",
  } = props;
  return (
    <div className="lg:mt-4 w-full text-center">
      <div className={` ${className} `}>
        <div
          onClick={stopRecording}
          className="min-w-10 h-10 bg-[#abb5bf] rounded flex items-center group justify-center cursor-pointer"
        >
          <SvgIcon className="w-6 h-6 group-hover:scale-110 group-hover:duration-150" iconName="StopIcon2" />
        </div>
        <div
          onClick={pauseResumeRecording}
          className="min-w-10 w-full sm:w-auto h-10 bg-primaryColor rounded flex items-center justify-center group p-[2px] cursor-pointer"
        >
          {isRecording && !isPaused && (
            <SvgIcon className="w-6 h-6 group-hover:scale-110 group-hover:duration-150 pause-icon" iconName="PauseIcon" />
          )}
          {isPaused && <SvgIcon className="w-4 h-6 group-hover:scale-110 group-hover:duration-150 play-icon" iconName="PlayIcon" />}
        </div>
        <div
          onClick={restartRecording}
          className="min-w-10 h-10 bg-[#abb5bf] rounded flex items-center group justify-center p-[2px] cursor-pointer"
        >
          <SvgIcon className="group-hover:scale-110 group-hover:duration-150 redo-icon" iconName="RedoIcon" />
        </div>
        <div
          onClick={deleteRecording}
          className="min-w-10 h-10 bg-[#abb5bf] rounded flex items-center group justify-center p-[2px] cursor-pointer"
        >
          <SvgIcon className="group-hover:scale-110 group-hover:duration-150" iconName="TrashSolidIcon" />
        </div>
      </div>
    </div>
  );
};
export default RecordingButtonPage;
