export const PROFILE_ALLOWED_MIME_TYPES = Object.freeze([
  "image/png",
  "image/jpg",
  "image/jpeg",
]);

export const PATIENT_ALLOWED_MIME_TYPES = Object.freeze([
  // Images
  "image/png",
  "image/jpg",
  "image/jpeg",

  // Documents
  "application/pdf", // PDF documents
  "application/msword", // Microsoft Word (.doc)
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (.docx)
  "text/plain", // Plain text (.txt)
  "application/wps-office.docx", // Word (.docx)
]);
