// ** packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ** redux **
import { RootStateType } from "redux/store";

export type CommonSliceType = {
  sidebarIsCollapse: boolean;
};

const initialState: CommonSliceType = {
  sidebarIsCollapse: true,
};

const slice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSidebarIsCollapse(
      state: CommonSliceType,
      action: PayloadAction<boolean>
    ) {
      state.sidebarIsCollapse = action.payload;
    },
  },
});

export const { reducer } = slice;

export const { setSidebarIsCollapse } = slice.actions;

export const getSidebarIsCollapse = (state: RootStateType) =>
  state.common?.sidebarIsCollapse;

export default slice;
