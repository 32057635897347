import { IconInputProps } from ".";

export const MicrophoneOffIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9833 24.75C38.9833 24.2917 38.6083 23.9167 38.15 23.9167C37.6917 23.9167 37.3167 24.2917 37.3167 24.75C37.3167 31.55 31.7833 37.075 24.9917 37.075C18.1917 37.075 12.6667 31.5417 12.6667 24.75C12.6667 24.2917 12.2917 23.9167 11.8333 23.9167C11.375 23.9167 11 24.2917 11 24.75C11 32.1834 16.8333 38.2667 24.1583 38.7V44.1667H16.8083C16.35 44.1667 15.975 44.5417 15.975 45C15.975 45.4584 16.35 45.8334 16.8083 45.8334H33.175C33.6333 45.8334 34.0083 45.4584 34.0083 45C34.0083 44.5417 33.6333 44.1667 33.175 44.1667H25.825V38.7C33.15 38.2667 38.9833 32.1834 38.9833 24.75Z"
        fill="#788896"
      />
      <path
        d="M28.2583 13.875C28.2583 14.3334 28.6333 14.7084 29.0917 14.7084H33.4583V17.8584H29.0917C28.6333 17.8584 28.2583 18.225 28.2583 18.6917C28.2583 19.15 28.6333 19.525 29.0917 19.525H33.4583V22.6667H29.0917C28.6333 22.6667 28.2583 23.0417 28.2583 23.5C28.2583 23.9584 28.6333 24.3334 29.0917 24.3334H33.4583V24.75C33.4583 29.4084 29.6667 33.2084 25 33.2084C20.3333 33.2084 16.5417 29.4084 16.5417 24.75V24.3334H20.9083C21.3667 24.3334 21.7417 23.9584 21.7417 23.5C21.7417 23.0417 21.3667 22.6667 20.9083 22.6667H16.5417V19.525H20.9083C21.3667 19.525 21.7417 19.15 21.7417 18.6917C21.7417 18.225 21.3667 17.8584 20.9083 17.8584H16.5417V14.7084H20.9083C21.3667 14.7084 21.7417 14.3334 21.7417 13.875C21.7417 13.4167 21.3667 13.0417 20.9083 13.0417H16.5417V12.625C16.5417 7.96669 20.3333 4.16669 25 4.16669C29.6667 4.16669 33.4583 7.96669 33.4583 12.625V13.0417H29.0917C28.6333 13.0417 28.2583 13.4167 28.2583 13.875Z"
        fill="#788896"
      />
      <rect
        x="10.3518"
        y="33.7062"
        width="36.1793"
        height="2.02471"
        transform="rotate(-39.3055 10.3518 33.7062)"
        fill="#788896"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};
