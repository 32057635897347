import {
  TranscriptDataType,
  tabsStateType,
  tabsType,
} from "../types/transcription.types";
import Tab from "components/Tab";
import SlideOverDialogTailWindUI from "components/Siderover/SlideOverDialogTailWindUI";
import PlayBook from "./PlayBook";
import Summary from "../../Summary";
import { convertUTCToLocal } from "utils";
import { useState } from "react";
import AlertModal from "components/modal/AlertModal";

type PatientNotePropsType = {
  tab: tabsStateType;
  setTab: React.Dispatch<React.SetStateAction<tabsStateType>>;
  transcript: TranscriptDataType;
  fileEdit: () => JSX.Element;
  transcriptTab: (transcriptionBodyClass?: string) => JSX.Element;
  shareTab: () => JSX.Element;
  isSideOpen: boolean;
  setIsSideOpen: React.Dispatch<React.SetStateAction<boolean>>;
  is_shared: boolean;
  history: any;
  onSave: () => void;
  onDiscard?: () => void;
};

const PatientNote = (props: PatientNotePropsType) => {
  const {
    tab,
    setTab,
    transcriptTab,
    fileEdit,
    transcript,
    shareTab,
    isSideOpen,
    setIsSideOpen,
    is_shared,
    history,
    onSave,
    onDiscard,
  } = props;

  const aiNoteTab: tabsType = {
    name: "Notes",
    value: "summary",
    disabled: false,
  };

  const publicTab: tabsType[] = [
    { name: "Transcription", value: "transcription", disabled: false },
    { name: "Export & Share", value: "share", disabled: false },
  ];

  const tabs: tabsType[] = [];

  
  tabs.push(aiNoteTab, ...publicTab);
  if (!is_shared) {
    tabs.push({
      name: "PlayBook",
      value: "playbook",
      disabled: false,
    });
  }
  const [needAlert, setNeedAlert] = useState<boolean>(false);

  const getCurrentTab = () => {
    if (tab?.summary && !isSideOpen) {
      return "summary";
    } else if (tab?.transcript && isSideOpen) {
      return "transcription";
    } else if (tab?.share && isSideOpen) {
      return "share";
    } else if (tab?.playbook && !isSideOpen) {
      return "playbook";
    } else {
      return "summary";
    }
  }

  const onTabChange = (e: string) => {
    if (e === "summary") {
      setTab({
        summary: true,
        transcript: false,
        share: false,
        playbook: false,
      });
    } else if (e === "transcription") {
      setTab((prevState) => ({
        ...prevState,
        transcript: true,
      }));
      setIsSideOpen(true);
    } else if (e === "share") {
      setTab((prevState) => ({
        ...prevState,
        share: true,
        transcript: false,
      }));
      setIsSideOpen(true);
    } else if (e === "playbook") {
      setTab({
        summary: false,
        transcript: false,
        share: false,
        playbook: true,
      });
    }
  };

  const onSideBarClose = () => {
    if (history?.length > 1) {
      setIsSideOpen(false);
      setNeedAlert(true)
    }
    else {
      setIsSideOpen(false);
      if (!is_shared && !(tab?.summary || tab?.playbook)) {
        setTab((prevState) => ({
          ...prevState,
          summary: true,
        }));
      }
    }
  };

  return (
    <div>
      {(needAlert || history?.length > 1) && (
        <AlertModal
          onCancel={() => { 
            setNeedAlert(false); 
            onDiscard && onDiscard()
          }}
          onSubmit={() => { onSave(); setNeedAlert(false) }}
          showFooter
          showCrossIcon={false}
          cancelButtonText="Discard"
          visible={needAlert}
          submitButtonText="Save"
        >
          <div className="text-center">The changes you have made in Transcript might not be saved, do you want to Discard ?</div>
        </AlertModal>
      )}
      <div className="flex flex-wrap -mx-[15px] mt-[20px]">
        <div className="w-full px-[15px]">
          <div className="flex lg:flex-col items-center lg:items-start mb-5 cursor-pointer">
            <div className="flex items-center lg:mb-4">
              <div
                className={`relative after:absolute after:h-8 after:w-[2px] after:bg-[#788896] after:top-1/2 after:-translate-y-1/2 after:-right-4 mr-8 sm:after:hidden sm:mr-0`}
              >
                {fileEdit()}
              </div>
            </div>

            <Tab
              tabs={tabs}
              currentTabValue={getCurrentTab()}
              onChangeTab={onTabChange}
            />
          </div>
        </div>
        {(
          <>
            {(tab.summary || tab?.transcript) && !tab?.playbook && (
              <Summary
                fileEdit={fileEdit()}
                transcriptStatus={transcript.status}
                noteType={transcript?.note_type}
                is_shared={transcript?.is_public !== undefined}
                created_at={transcript?.created_at && convertUTCToLocal(transcript?.created_at, true, "dd MMM yyyy") as string}
                is_editable={transcript?.is_editable}
              />
            )}
          </>
        )}

        {tab?.playbook && <PlayBook />}

        <SlideOverDialogTailWindUI
          open={isSideOpen}
          handleClose={onSideBarClose}
          title={`${tab?.transcript ? "Transcription" : "Export & Share"}`}
          DialogPanelClass={
            tab?.transcript
              ? "pointer-events-auto w-screen max-w-[60vw] md2:max-w-full"
              : "pointer-events-auto w-screen max-w-lg md2:max-w-full"
          }
        >
          {tab?.transcript
            ? transcriptTab(
              "overflow-y-auto 2xl:max-h-[62vh] 3xl:max-h-[70vh]"
            )
            : shareTab()
          }
        </SlideOverDialogTailWindUI>
      </div>
    </div>
  );
};

export default PatientNote;
