// ** packages **
import {
  AddUserModelSchemaError,
  PhoneNumberError,
} from "constants/formErrorMessage.constant";
import * as yup from "yup";
import { basicSchema } from "../../PersonalSetting/schema/personalInformation-validation";

export const addUserModelValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(3, `First name ${AddUserModelSchemaError.firstName.minLengthName}`)
    .matches(/^[a-zA-Z\s]+$/, AddUserModelSchemaError.firstName.onlyAlphanumeric)
    .required(AddUserModelSchemaError.firstName.first_name),
  lastName: yup
    .string()
    .trim()
    .min(3, `Last name ${AddUserModelSchemaError.firstName.minLengthName}`)
    .matches(/^[a-zA-Z\s]+$/, AddUserModelSchemaError.firstName.onlyAlphanumeric)
    .required(AddUserModelSchemaError.lastName.last_name),
  email: yup.string().email().required(AddUserModelSchemaError.email.valid),

  role: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required("Role is required field"),
  }),
  phoneNumber: basicSchema.phone.required(PhoneNumberError.required).optional().nullable(),
});
