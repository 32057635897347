import { useEffect, useState } from "react";
import { User } from "react-feather";

// ** components **
import Spinner from "components/Loaders/Spinner";
// import "./style/avatar.css";
import NameBadge from "components/Badge";
import { srcFilePath } from "utils";

import "./styles/style.css";

type Props = {
  imgPath?: string | File | null;
  avatarWrapperClassName?: string;
  avatarInnerWrapperClassName?: string;
  imgClassName?: string;
  imgElementClass?: string;
  imgLoaderWrapperClass?: string;
  noImgIconWrapperClass?: string;
  NoNameLetterWrapperClass?: string;
  NoNameLetterClass?: string;
  OnlineStatusClass?: string;
  serverPath?: boolean;
  first_name?: string;
  last_name?: string;
  disableLoader?: boolean;
  color?: string;
  defaultType?: string;
  smallSpinnerClassName?: string;
  //   iconName?: IconTypes;
  height?: number;
  width?: number;
  onClickOnImage?: () => void;
};

// get file String and get file object url
const GetFilePath = (file: string | File, serverPath = false) => {
  if (typeof file === "string") {
    if (serverPath) {
      return `${file}`;
    }
  } else if (file) {
    return URL.createObjectURL(file);
  } else {
    return file;
  }
};

const ImageShow = (props: Props) => {
  const {
    imgPath = "",
    avatarWrapperClassName = "",
    avatarInnerWrapperClassName = "",
    imgClassName = "",
    imgElementClass = "",
    imgLoaderWrapperClass = "",
    noImgIconWrapperClass = "",
    NoNameLetterWrapperClass = "",
    NoNameLetterClass = "",
    OnlineStatusClass = "",
    serverPath = false,
    disableLoader = false,
    first_name,
    last_name,
    color,
    smallSpinnerClassName,
    onClickOnImage,
  } = props;

  // ** States **
  const [fetchError, setFetchError] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageURL, setImageURL] = useState<string | File>("");

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    setImageURL(imgPath || "");
  }, [imgPath]);

  const imgComponent = () => {
    return imageURL ? (
      <div
        className={`img__element ${imgElementClass}`}
        onClick={onClickOnImage}
      >
        <img
          className={`${!isImageLoaded ? "hidden" : "block"} ${imgClassName}`}
          src={`${srcFilePath(imageURL, serverPath)}`}
          alt=""
          onLoad={() => setIsImageLoaded(true)}
          onError={() => {
            setFetchError(true);
            setIsImageLoaded(true);
          }}
        />
      </div>
    ) : (
      <>
        <div
          className={`noImg__icon ${noImgIconWrapperClass}`}
          onClick={onClickOnImage}
        >
          <User />
        </div>
      </>
    );
  };

  return (
    <div className={`avatar__Img ${avatarWrapperClassName}`}>
      <div className={`inner__wrapper ${avatarInnerWrapperClassName}`}>
        {fetchError ? (
          <div className={`noImg__icon ${noImgIconWrapperClass}`}>
            <User />
          </div>
        ) : (
          (isLoading || (!disableLoader && !isImageLoaded && imageURL)) && (
            <div className={`img__loader__wrapper ${imgLoaderWrapperClass}`}>
              <div className="inner__wrapper">
                <Spinner smallSpinnerClassName={smallSpinnerClassName} />
              </div>
            </div>
          )
        )}

        {isMounted && !fetchError && !isLoading && (
          <>
            {!imageURL && (first_name || last_name) ? (
              <NameBadge
                NoNameLetterWrapperClass={NoNameLetterWrapperClass}
                NoNameLetterClass={NoNameLetterClass}
                FirstName={first_name}
                LastName={last_name}
                color={color}
              />
            ) : (
              imgComponent()
            )}
          </>
        )}
        <span className={`online__status hidden ${OnlineStatusClass}`} />
      </div>
    </div>
  );
};

export default ImageShow;
