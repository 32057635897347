// ** Packages **
import { Navigate, useLocation } from "react-router-dom";

// ** Constants **
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "constants/navigation.constant";
import React, { Suspense } from "react";
import { getAuth, getIsAuthenticated } from "redux/slices/authSlice";
import { useSelector } from "react-redux";
import Layout from "components/Layout";
import PageLoader from "components/Loaders/PageLoader";

type Props = {
  children: JSX.Element | React.ReactNode;
};

const AdminRoute = (props: Props) => {
  const { children } = props;
  const location = useLocation();
  const { user } = useSelector(getAuth);
  const isAuthenticated = useSelector(getIsAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to={PUBLIC_NAVIGATION.login} state={{ from: location }} />;
  }

  if (!user?.is_super_administrator) {
    return (
      <Navigate
        to={PRIVATE_NAVIGATION.dashboard.view}
        state={{ from: location }}
      />
    );
  }

  return (
    <Layout>
      <Suspense fallback={<PageLoader />}>{children}</Suspense>
    </Layout>
  );
};

export default AdminRoute;
