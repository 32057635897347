// ** packages **
import { useEffect, useRef, useState } from "react";
import { Mic } from "react-feather";
import { useNavigate } from "react-router-dom";

// ** components **
import Button from "components/Theme/Button";
import DraggableFileInput from "components/DraggableFileInput";
import AsyncSelectServer from "components/AsyncSelectServer";
import SlideOverDialogTailWindUI from "components/Siderover/SlideOverDialogTailWindUI";

// ** services **
import { useAddAudioRecordingAPI } from "modules/Patient/sub-modules/Recording/Services";

// ** constants **
import { PERMISSIONS } from "constants/Permissions.constant";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";

// ** hooks **
import usePermission from "hooks/usePermission";
import { useGetSideBarList } from "../hooks/useOptionList";

// ** types **
import { MultiValue, SingleValue } from "react-select";
import { TableRefType } from "components/DataTable";
import { Option } from "components/FormField/types/formField.types";
import AddPatientForm from "modules/Patient/components/AddPatientForm";
import { PatientDetailType } from "modules/Patient/types/patients.types";
import AddMeetingForm from "modules/MeetingFolder/components/AddMeetingForm";
import { getMeetingType } from "modules/MeetingFolder/types/meeting.types";
import { allowed_mimetype } from "modules/Patient/sub-modules/Recording/constant";

export type NoteSideBar = {
  setIsSideOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tableRef: React.RefObject<TableRefType>;
  isSideOpen: boolean;
  note_type: string;
};

const NoteSideBar = (props: NoteSideBar) => {
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const { setIsSideOpen, tableRef, isSideOpen, note_type } = props;
  const [file, setFile] = useState<FileList | undefined>();
  const [fileForSubmit, setFileForSubmit] = useState<FileList | undefined>();
  const [referenceId, setReferenceId] = useState<number | string | null>();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const { addAudioRecordingApi, isLoading } = useAddAudioRecordingAPI();
  const { loadOptions, isListLoading, getOptionsListApi } = useGetSideBarList(
    note_type === "patient" ? "patient" : "meeting"
  );
  const selectRef = useRef<any>();
  const patientCreatePermission = hasPermission(PERMISSIONS.PATIENT.CREATE);
  const patientReadPermission = hasPermission(PERMISSIONS.PATIENT.READ);
  const noteCreatePermission = hasPermission(PERMISSIONS.NOTES.CREATE);
  const formData = new FormData();

  useEffect(() => {
    if (fileForSubmit) {
      AudioRecord();
    }
  }, [fileForSubmit]);

  const handleFile = (e: any) => {
    setFileForSubmit(e);
  };
  const AudioRecord = async () => {
    if (fileForSubmit && !formData.get("media_url")) {

      if (fileForSubmit) {
        formData.append(
          "media_url",
          fileForSubmit?.[0],
          fileForSubmit?.[0]?.name
        );
        setFileForSubmit(undefined);
      }
      formData.append("model", "assemblyai");
      formData.append("note_type", note_type);

      if (note_type === "patient" || note_type === "meeting") {
        formData.append("patient_id", String(referenceId));
      }
      const { data, error } = await addAudioRecordingApi(formData);
      if (data && !error) {
        setIsSideOpen(false);
        setFile(undefined);
        setFileForSubmit(undefined);
        refreshTable();
      }
    }
  };

  useEffect(() => {
    if (isLoading) {
      const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
        const confirmationMessage =
          "The changes you have made might not be saved, do you want to Discard ?";
        (event || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      };

      window.addEventListener("beforeunload", beforeUnloadHandler);

      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [isLoading]);

  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  const onOptionChange = (data: SingleValue<Option> | MultiValue<Option>) => {
    const singleValueData = data as SingleValue<Option>;
    if (singleValueData?.value) {
      if (singleValueData?.value === "add") {
        setOpenForm(true);
      } else {
        setReferenceId(singleValueData?.value);
      }
    } else {
      setReferenceId(null);
    }
  };

  const onSideBarClosed = () => {
    setReferenceId(null);
    setIsSideOpen(false);
  };

  const onNewRecording = () => {
    if (note_type === "patient") {
      navigate(PRIVATE_NAVIGATION.patient.recording(String(referenceId)));
    } else if (note_type === "meeting") {
      navigate(PRIVATE_NAVIGATION.MeetingFolder.recording(String(referenceId)));
    } else {
      navigate(PRIVATE_NAVIGATION.recording.view, {
        state: { note_type: note_type, navHighlighter: note_type },
      });
    }
  };

  const getPatientDataOnSuccess = (data: PatientDetailType) => {
    if (data?.id && selectRef) {
      selectRef?.current?.commonProps?.setValue({
        label: data?.name,
        value: data?.id,
      });
    }
  };

  const getMeetingDataOnSuccess = (data: getMeetingType) => {
    if (data?.id && selectRef) {
      selectRef?.current?.commonProps?.setValue({
        label: data?.name,
        value: data?.id,
      });
    }
  };

  const getStaticOptions = () => {
    if (note_type === "patient" && patientCreatePermission) {
      return [{ label: "Add New Patient", value: "add" }];
    } else if (note_type === "meeting" && noteCreatePermission) {
      return [{ label: "Add New Meeting", value: "add" }];
    } else {
      return undefined;
    }
  };

  return (
    <SlideOverDialogTailWindUI
      open={isSideOpen}
      handleClose={onSideBarClosed}
      title="New Note"
    >
      {openForm && patientCreatePermission && note_type === "patient" && (
        <AddPatientForm
          setIsOpen={setOpenForm}
          refreshData={refreshTable}
          getDataOnSuccess={getPatientDataOnSuccess}
        />
      )}
      {openForm && patientCreatePermission && note_type === "meeting" && (
        <AddMeetingForm
          setIsOpen={setOpenForm}
          refreshData={refreshTable}
          getDataOnSuccess={getMeetingDataOnSuccess}
        />
      )}

      {noteCreatePermission && (
        <div className="w-full px-[15px]">
          <div className="bg-[#F2F5F7] border-[2px] border-[#C3CFD9]">
            <div className="text-end my-1 mr-1">
              {/* {patientCreatePermission && (
                <Button
                  className="bg-[#6558F5] text-white md:mt-4"
                  onClick={() => {
                    setOpenForm(true);
                  }}
                >
                  Add Patient
                </Button>
              )} */}
            </div>
            {(note_type === "patient" || note_type === "meeting") && patientReadPermission && (
              <div className="px-[10px] md:w-full">
                <AsyncSelectServer
                  apiCall={getOptionsListApi}
                  loadOptions={loadOptions}
                  isLoading={isListLoading}
                  onCustomChange={onOptionChange}
                  labelName={
                    note_type === "patient" ? "Patient Name" : "Meeting Name"
                  }
                  isMultiple={false}
                  staticOptions={getStaticOptions()}
                  selectRef={selectRef}
                />
              </div>
            )}
            <div className="w-full text-center my-7">
              <Button
                className={`hover:text-white py-4 min-w-[62%] border border-indigo-700 bg-indigo-700 text-white ${
                  !referenceId &&
                  (note_type === "patient" || note_type === "meeting")
                    ? "pointer-events-none opacity-50"
                    : ""
                }`}
                disabled={file ? true : false}
                onClick={onNewRecording}
              >
                <span className="flex items-center select-none">
                  <Mic className="mr-1 w-5 h-5" /> New Recording
                </span>
              </Button>
            </div>
            <div className="h-1 w-3/4 bg-[#788889] mx-auto mb-5"></div>
            <div className="m-3">
              <DraggableFileInput
                loading={isLoading}
                handleFile={handleFile}
                setFile={setFile}
                file={file}
                allowedTypes={allowed_mimetype}
                isMultiple={false}
                isSubmitDisabled={
                  !referenceId &&
                  (note_type === "patient" || note_type === "meeting")
                }
              />
            </div>
          </div>
        </div>
      )}
    </SlideOverDialogTailWindUI>
  );
};

export default NoteSideBar;
