import { IconInputProps } from ".";

export const RestartIcon = ({ className }: IconInputProps) => {
  return (
      <svg className={className ?? ''} width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4753 22.9818L11.4751 22.9822C10.9913 22.8107 10.4597 23.0641 10.2879 23.5482C10.1164 24.032 10.3698 24.5636 10.854 24.7354C11.3098 24.8965 11.8077 24.6813 12.0074 24.2516C12.2402 23.7509 11.9901 23.1644 11.4753 22.9818Z" fill="#788895"/>
        <path d="M8.84699 21.4846C8.45316 21.1549 7.86695 21.207 7.53718 21.6008C7.20748 21.9947 7.25937 22.5813 7.65325 22.9111C8.04708 23.2408 8.6339 23.1885 8.96347 22.795C9.017 22.7307 9.06033 22.6616 9.09385 22.5895C9.2671 22.2168 9.17714 21.7606 8.84699 21.4846Z" fill="#788895"/>
        <path d="M6.91507 19.1621C6.66202 18.7148 6.09476 18.5573 5.64767 18.8099C5.20096 19.0628 5.04303 19.6297 5.29607 20.0771C5.54866 20.5242 6.11592 20.6817 6.56301 20.4291C6.73847 20.3296 6.86949 20.1824 6.94848 20.0124C7.07078 19.7493 7.06841 19.4334 6.91507 19.1621Z" fill="#788895"/>
        <path d="M4.85163 15.5348C4.34443 15.6154 3.99917 16.0923 4.07982 16.5995C4.16089 17.1068 4.63762 17.4525 5.1445 17.3713C5.46177 17.3208 5.71573 17.1153 5.84174 16.8442C6.15303 16.1745 5.58969 15.4168 4.85163 15.5348Z" fill="#788895"/>
        <path d="M15.3212 24.7874C15.5369 24.3234 15.3357 23.7687 14.8699 23.5521C14.3717 23.3206 13.7809 23.5716 13.6002 24.0885C13.4419 24.5572 13.6611 25.0413 14.0859 25.2388C14.5403 25.45 15.1005 25.2623 15.3212 24.7874Z" fill="#788895"/>
        <path d="M16.5987 24.0423C16.479 23.5795 16.73 23.1015 17.1789 22.9368C19.2026 22.1943 20.8778 20.7063 21.8494 18.7602C23.9394 14.5737 22.2338 9.46742 18.0474 7.37738C15.0736 5.89278 11.5436 6.301 9.00233 8.30327L9.98736 9.09067C10.2148 9.27243 10.1547 9.63317 9.88064 9.73141L6.0697 11.0975C5.80897 11.191 5.54121 10.9769 5.57496 10.702L6.06818 6.68377C6.10365 6.39482 6.44228 6.25674 6.66967 6.43853L7.51794 7.11662C10.6359 4.44069 15.1213 3.83777 18.8781 5.71327C23.9942 8.26738 26.0678 14.4745 23.5135 19.591C22.3291 21.9633 20.2872 23.7772 17.8208 24.6824C17.3042 24.8721 16.7365 24.5751 16.5987 24.0423Z" fill="#788895"/>
        <path d="M10.3296 23.4497C10.4364 23.5985 10.5881 23.7176 10.7739 23.7835C11.2241 23.9426 11.7149 23.7342 11.9194 23.315C12.1077 23.5772 12.1561 23.9317 12.0075 24.2514C11.8078 24.6811 11.3099 24.8963 10.8541 24.7352C10.3333 24.5505 10.0839 23.9526 10.3296 23.4497Z" fill="#788895"/>
        <path d="M7.65336 22.9111C7.31849 22.6307 7.231 22.1647 7.41491 21.7893C7.82445 22.3582 8.69556 22.2874 9.00534 21.6546C9.20138 21.9265 9.23516 22.2857 9.09396 22.5895C9.06044 22.6616 9.01707 22.7307 8.96358 22.7951C8.63402 23.1885 8.0472 23.2408 7.65336 22.9111Z" fill="#788895"/>
        <path d="M5.29586 20.0772C5.13898 19.7999 5.14037 19.4766 5.27033 19.2107C5.68007 19.7823 6.55306 19.7052 6.86 19.0768C7.06361 19.3603 7.0858 19.7168 6.94833 20.0126C6.63213 20.6927 5.66915 20.7379 5.29586 20.0772Z" fill="#788895"/>
        <path d="M13.6002 24.0885C13.6125 24.0531 13.627 24.0192 13.643 23.9864C13.734 24.113 13.8568 24.2179 14.0057 24.2871C14.4546 24.4957 15.0065 24.3149 15.2326 23.8528C15.4219 24.117 15.4674 24.473 15.3212 24.7874C15.1005 25.2622 14.5402 25.4499 14.0859 25.2388C13.6611 25.0413 13.4419 24.5572 13.6002 24.0885Z" fill="#788895"/>
        <path d="M16.5987 24.0422C16.5389 23.8107 16.5717 23.5755 16.6756 23.3776C16.908 23.7205 17.3538 23.8805 17.7673 23.7288C20.2338 22.8236 22.2756 21.0097 23.4599 18.6373C24.2172 17.1206 24.5676 15.508 24.5527 13.9231C24.7455 15.8079 24.4233 17.7685 23.5135 19.5908C22.3291 21.9632 20.2873 23.7771 17.8208 24.6823C17.3042 24.8719 16.7365 24.575 16.5987 24.0422Z" fill="#788895"/>
      </svg>
  )
}
