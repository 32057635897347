// ** packages **
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// ** services **
import { useSharedTranscriptGetAPI } from "modules/DashBoard/sub-modules/RecentFiles/services";
import Transcription from "modules/Patient/sub-modules/Transcription";
import { TranscriptArrayType } from "modules/Patient/sub-modules/Transcription/types/transcription.types";
import PageLoader from "components/Loaders/PageLoader";
import AlertModal from "components/modal/AlertModal";
import InputField from "components/FormField/common/inputField";

const TranscriptShared = () => {
  const [transcriptData, setTranscriptData] = useState<{
    id: number;
    file_name: string;
    media: string;
    transcript: Array<TranscriptArrayType>;
    duration: string;
    size: string;
    status: string;
    is_editable: boolean;
  }>();
  const [password, setPassword] = useState<string | undefined>()
  const [showData, setShowData] = useState<boolean>(true)
  const verify_token:string | null = localStorage.getItem("verify_token")
  const [requiredPassword, setRequiredPassword] = useState<boolean>(false)
  const { id } = useParams();

  const { getSharedTranscriptGetAPI, isLoading } = useSharedTranscriptGetAPI();

  const TranscriptGet = async () => {
    if (id) {
      const { data, error } = await getSharedTranscriptGetAPI({
        sharing: id,
        password: password,
      });
      if (data && !error) {
        if (data?.is_public === false) {
          if (data?.is_verify) {
            setShowData(true)
            localStorage.setItem("verify_token", data.verify_token);
            setRequiredPassword(false)
            setTranscriptData(data);
          }
          else {
            setShowData(false)
            setRequiredPassword(true)
          }
          setPassword("")
        }
        else {
          setShowData(true)
          setRequiredPassword(false)
          setTranscriptData(data);
        }
      }
    }
  };

  useEffect(() => {
    TranscriptGet();
  }, []);

  return (
    <>
      {<>
        {isLoading ? (
          <PageLoader />
        ) : (
          transcriptData && <Transcription data={transcriptData} isAuthor={false} />
        )}
      </>}
      <AlertModal
        visible={requiredPassword}
        showCancelButton={false}
        onSubmit={() => { TranscriptGet() }}
        submitButtonText="Submit"
      >
        <p className="text-center mb-3">
          Enter Password for access
        </p>
        <InputField
          name="file_name"
          id="file_name"
          type="text"
          onChange={(e: any) => setPassword(e?.target?.value ?? "")}
          value={password}
          maxLength={30}
        />
      </AlertModal>
    </>
  );
};
export default TranscriptShared;
