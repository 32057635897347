import ImageShow from "components/Image";
import { X } from "react-feather";
// import ImageView from "./components/ImageView";

type FileUploadPropsType = {
  onFileChange?: React.ChangeEventHandler<HTMLInputElement>;
  image: string | File | null;
  setProfileImage?: React.Dispatch<React.SetStateAction<any>>;
  link?: boolean;
  uploadFile?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  onRemoveImage?: () => void;
};

const FileUpload = (props: FileUploadPropsType) => {
  const {
    onFileChange,
    image,
    setProfileImage,
    link,
    inputRef,
    uploadFile = true,
    onRemoveImage,
  } = props;

  return (
    <>
      <ImageShow
        imgPath={image}
        avatarWrapperClassName="w-[150px] h-[150px] md:w-[90px] md:h-[90px]"
        avatarInnerWrapperClassName="!rounded-full overflow-hidden border-[#6558f5] border"
      />
      {image && !link && (
        <button
          type="button"
          className="delete__Btn border-[2px] border-bgWhiteSD rounded-full bg-gray-500 p-[2px] w-[22px] h-[22px] flex items-center justify-center absolute top-[-4px] right-[-4px] hover:bg-bgBlackSD"
          onClick={() => {
            if (setProfileImage) {
              setProfileImage("");
            }
          }}
        >
          <X className="feather__icon text-bgWhiteSD" onClick={onRemoveImage} />
        </button>
      )}
      {uploadFile && (
        <div className="absolute bottom-[10px] right-[4px] bg-[#6558f5] w-8 h-8 md:bottom-[6px] md:right-[2px] md:w-[25px] md:h-[25px] group cursor-pointer flex items-center justify-center rounded-full upload__btn ">
          {inputRef ? (
            <input
              className="absolute top-0 left-0 w-full h-full z-[3] opacity-0 cursor-pointer"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={onFileChange}
              ref={inputRef}
            />
          ) : (
            <input
              className="absolute top-0 left-0 w-full h-full z-[3] opacity-0 cursor-pointer"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={onFileChange}
            />
          )}

          <span className="flex justify-center w-full text-center relative text-[14px] text-textSecondary cursor-pointer group-hover:text-textDark group-hover:underline">
            <svg
              width="87"
              height="78"
              viewBox="0 0 87 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 md:w-[12px] md:h-[12px]"
            >
              <path
                d="M60.2625 52.1755L65.0559 47.3821C65.8049 46.6331 67.1081 47.1574 67.1081 48.2359V70.0161C67.1081 73.9856 63.8875 77.2062 59.918 77.2062H7.19016C3.22059 77.2062 0 73.9856 0 70.0161V17.2883C0 13.3187 3.22059 10.0981 7.19016 10.0981H48.1591C49.2226 10.0981 49.7619 11.3863 49.0129 12.1503L44.2195 16.9437C43.9948 17.1684 43.6952 17.2883 43.3656 17.2883H7.19016V70.0161H59.918V53.0144C59.918 52.6998 60.0378 52.4002 60.2625 52.1755ZM83.7204 21.9469L44.3842 61.283L30.8428 62.781C26.9181 63.2154 23.5777 59.9049 24.0121 55.9503L25.5101 42.4089L64.8462 3.07273C68.2765 -0.357576 73.8189 -0.357576 77.2343 3.07273L83.7054 9.54387C87.1357 12.9742 87.1357 18.5316 83.7204 21.9469ZM68.9206 26.5755L60.2176 17.8725L32.3857 45.7193L31.2922 55.5009L41.0738 54.4074L68.9206 26.5755ZM78.6274 14.6369L72.1562 8.16575C71.542 7.5516 70.5384 7.5516 69.9392 8.16575L65.3106 12.7944L74.0137 21.4975L78.6423 16.8688C79.2415 16.2397 79.2415 15.2511 78.6274 14.6369Z"
                fill="white"
              />
            </svg>
          </span>
        </div>
      )}
    </>
  );
};
export default FileUpload;
