import SvgIcon from "components/Icon/SvgIcon";
import { useEffect, useState } from "react";

export type OptionExportType = {
  label: string;
  value: string;
  icon: string | number;
};

type DropdownWithIconsPropsType = {
  onClickFun: (value: string) => void;
  options: OptionExportType[];
};

const DropdownWithIcons = (props: DropdownWithIconsPropsType) => {
  const { options, onClickFun } = props;
  const [selectedOption, setSelectedOption] = useState<OptionExportType>({
    label: "",
    value: "",
    icon: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    selectedOption.value && onClickFun(selectedOption?.value);
  // eslint-disable-next-line
  }, [selectedOption])
  
  const handleClick = (option: OptionExportType) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="bg-white border border-gray-300 rounded-md px-4 py-2 flex items-center text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="mr-2 truncate max-w-full">
          Export as: {selectedOption?.label.length ? selectedOption?.label : "Select Type"}
        </span>
        <svg
          className="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <div
        className={`absolute mt-1 w-full rounded-md bg-white shadow-lg z-10 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <ul className="max-h-60 overflow-y-auto py-1 text-base">
          {options.map((option: any, index: number) => (
            <li
              key={index}
              className="cursor-pointer hover:bg-gray-100 px-4 py-2"
              onClick={() => handleClick(option)}
            >
              <div className="flex items-center my-2" key={index}>
                <SvgIcon className="w-6 h-6" iconName={option.icon} />
                <div
                  className="cursor-pointer ml-2"
                  onClick={() => handleClick(option)}
                >
                  {option.label}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownWithIcons;
