// ** Packages **
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// ** Components **
import Sidebar from "./components/Sidebar";
import { getSidebarIsCollapse } from "redux/slices/commonSlice";
import Header from "./components/Header";

// ** Redux **

import { getAuth } from "redux/slices/authSlice";

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const { children } = props;
  const sidebarIsCollapse = useSelector(getSidebarIsCollapse);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isSidebarClicked, setIsSidebarClicked] = useState<boolean>(false)
  useEffect(()=>{
    if(true){
      setIsSidebarClicked(false)
    }
  },[sidebarIsCollapse])
  const { user } = useSelector(getAuth);
  return (
    <div
      className={`main__wrapper ${sidebarIsCollapse ? "" : "sidebar__collapse"
        }${isSidebarClicked ? " close-sidemenu close-sidebar" : ""}`}
    >
      {/* <Header headerTitle={`${user?.firstName}`} profile={user?.profile} /> */}
      <div className="w-full">
        <Sidebar sidebarRef={sidebarRef} headerTitle={`${user?.firstName}`} profile={user?.profile} setIsSidebarClicked={setIsSidebarClicked} isSidebarClicked={isSidebarClicked}/>
        <div className="main__content__wrapper">
          <div className="px-[12px] sm:px-1">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
