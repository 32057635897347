// packages **
import { Edit } from "react-feather";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// ** components **
import PageLoader from "components/Loaders/PageLoader";
import UserFileView from "components/UserField/UserFieldView";
import ImageShow from "components/Image";
import Breadcrumbs from "components/Breadcrumbs";

// ** hook **
import usePermission from "hooks/usePermission";

// ** services **
import { useSingleUserAPI } from "../services";

// ** constant **

import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import { PERMISSIONS } from "constants/Permissions.constant";
import { firstCharUpperCase } from "utils";

const UserDetailView = () => {
  const { getUserAPI, isLoading: isUserLoading } = useSingleUserAPI();
  const { hasPermission } = usePermission();

  const userEditPermission = hasPermission(PERMISSIONS.USER.UPDATE);

  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const title = state;
  const [userDetail, setUserDetail] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    profile_image: "",
    role: "",
    id: null,
  });

  const getUserDetail = async () => {
    if (id) {
      const { data, error } = await getUserAPI(id);
      if (data && !error) {
        setUserDetail({
          firstName: data.first_name,
          lastName: data.last_name,
          phoneNumber: data.contact_number,
          email: data.email,
          profile_image: data.profile_image,
          role: data.roles?.[0]?.name,
          id: data?.id,
        });
      }
    }
  };
  useEffect(() => {
    getUserDetail();
  }, []);

  const editPersonalInformation = (id: string | null) => {
    navigate(PRIVATE_NAVIGATION.setting.userManagement.edit.view(String(id)));
  };
  return (
    <>
      {isUserLoading ? (
        <PageLoader pageLoaderClassName="h-[calc(100vh_-_92px)]" />
      ) : (
        <>
          <Breadcrumbs path={BREAD_CRUMB.viewUser} />
          <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow sm:p-[15px] p-[24px] mt-[20px]">
            <h2 className="text-[20px] sm:text-[18px] font-semibold text-textDark relative mb-[16px] sm:pr-8">
              {title}
              {userEditPermission && (
                <div
                  className="edit__btn w-[28px] h-[28px] p-[5px] rounded-[6px] absolute top-[0px] right-0 duration-300 cursor-pointer hover:bg-gray-200"
                  onClick={() => editPersonalInformation(userDetail.id)}
                >
                  <Edit className="feather__icon" />
                </div>
              )}
            </h2>
            <div className="mb-[18px] file__manager__onlyShow flex sm:flex-col items-center sm:items-start">
              <div className="inline-block relative">
                <ImageShow
                  avatarWrapperClassName="w-[60px] min-w-[40px] mr-3 h-[60px]"
                  avatarInnerWrapperClassName="!rounded-full"
                  imgPath={userDetail.profile_image}
                  first_name={userDetail?.firstName}
                  last_name={userDetail?.lastName}
                />
              </div>
              <div className="w-[calc(100%_-_61px)] pl-[15px] sm:pl-0 sm:w-full sm:mt-2">
                <h3 className="text-[16px] font-semibold text-textDark">
                  {userDetail?.firstName + " " + userDetail?.lastName}
                </h3>
                <p className="text-[14px] font-normal text-textSecondary">
                  {firstCharUpperCase(userDetail?.role)}
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mx-[-10px]">
              <div className="px-[10px] xl:w-1/2 lg:w-full w-1/3 mb-[12px] last:mb-0">
                <UserFileView
                  fieldName="First Name"
                  fieldValue={userDetail.firstName}
                />
              </div>
              <div className="px-[10px] xl:w-1/2 lg:w-full w-1/3 mb-[12px] last:mb-0">
                <UserFileView
                  fieldName="Last Name"
                  fieldValue={userDetail.lastName}
                />
              </div>
              <div className="px-[10px] xl:w-1/2 lg:w-full w-1/3 mb-[12px] last:mb-0">
                <UserFileView fieldName="Email" fieldValue={userDetail.email} />
              </div>
              <div className="px-[10px] xl:w-1/2 lg:w-full w-1/3 mb-[12px] last:mb-0">
                <UserFileView
                  fieldName="Phone Number"
                  fieldValue={userDetail.phoneNumber}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default UserDetailView;
