// ** packages **
import { AxiosRequestConfig } from "axios";
// ** hooks **
import { useAxiosDelete, useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const AUTH_API_BASE_PATH = "/auth";
export const useAddUserAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addUserAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/send_user_invitation`, data, config);
  };
  return { addUserAPI, isLoading, isError, isSuccess };
};

export const useUserRoleAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getUserRoleAPI = async (data: object) => {
    return callApi(`${AUTH_API_BASE_PATH}/get_all_roles`, data);
  };

  return { getUserRoleAPI, isLoading, isError, isSuccess };
};

export const useUserListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getUserListAPI = async (data: object) => {
    return callApi(`${AUTH_API_BASE_PATH}/get_all_users`, data);
  };

  return { getUserListAPI, isLoading, isError, isSuccess };
};

export const useSingleUserAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getUserAPI = async (id: number | string) => {
    return callApi(`${AUTH_API_BASE_PATH}/users/${id}`);
  };

  return { getUserAPI, isLoading, isError, isSuccess };
};

export const useUpdateUserAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updateUserApi = async (id: number | string, payload: object) => {
    const response = await callApi(
      `${AUTH_API_BASE_PATH}/users/${id}`,
      payload
    );
    return response;
  };

  return { updateUserApi, isLoading, isError, isSuccess };
};

export const useResendUserInvitationAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const resendUserInvitation = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(
      `${AUTH_API_BASE_PATH}/resend_user_invitation`,
      data,
      config
    );
  };
  return { resendUserInvitation, isLoading, isError, isSuccess };
};

export const useDeleteUserAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosDelete();

  const deleteUserAPI = async (id: number | string) => {
    return callApi(`${AUTH_API_BASE_PATH}/delete_user/${id}`);
  };

  return { deleteUserAPI, isLoading, isError, isSuccess };
};
