// ** packages **
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// ** store **
import store from "./redux/store";
// ** axios **
import { setupAxios } from "./base-axios";
// components
import Toast from "components/Toast";
import PopupMessage from "components/Popup";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

setupAxios(store);

root.render(
  <Provider store={store}>
    <Toast />
    <PopupMessage />
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
