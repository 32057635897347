// ** packages **

import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  removePopupToast,
  removeToast,
  setPopupToast,
  setToast,
} from "redux/slices/toastSlice";

import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record";

let wavesurfer: WaveSurfer, record: RecordPlugin;

type AudioRecordHooksPropsType = {
  setOpen: React.Dispatch<
    React.SetStateAction<{
      recordingButton: boolean;
      transcript: boolean;
      defaultPage: boolean;
      recordingStart: boolean;
      microPhoneErrorAlert: boolean;
    }>
  >;
};

const useAudioRecorder = (props: AudioRecordHooksPropsType) => {
  const dispatch = useDispatch();
  const { setOpen } = props;
  const [isTestingMicrophone, setIsTestingMicrophone] = useState(false);
  const [microphoneInputDevice, setMicrophoneInputDevice] = useState<
    string | undefined
  >(undefined);
  // const [progressTimer, setProgressTimer] = useState("");
  const progressTimer = useRef<string>("");
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [audioRecording, setAudioRecording] = useState<Blob | undefined>();

  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const visualizationCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const initializeAudioContext = () => {
    const audioContext: AudioContext = new window.AudioContext();
    audioContextRef.current = audioContext;

    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    analyserRef.current = analyser;
  };

  const initializeWaveSurfer = () => {
    if (wavesurfer) {
      wavesurfer.destroy();
    }
    wavesurfer = WaveSurfer.create({
      container: "#mic",
      waveColor: "rgb(200, 0, 200)",
      progressColor: "rgb(100, 0, 100)",
      barGap: 3,
      barWidth: 3,
      plugins: [
        RecordPlugin.create({
          scrollingWaveform: true,
          renderRecordedAudio: false,
        }),
      ],
    });

    // Initialize the Record plugin
    record = wavesurfer.registerPlugin(
      RecordPlugin.create({
        scrollingWaveform: true,
        renderRecordedAudio: false,
      })
    );

    record.on("record-progress", (time: any) => {
      updateProgress(time);
    });
  };

  const updateProgress = (time: number) => {
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
    // setProgressTimer(formattedTime);
    progressTimer.current = formattedTime;
  };

  const cleanupAudioContext = () => {
    const audioContext = audioContextRef.current;
    const analyser = analyserRef.current;
    const stream = streamRef.current;

    if (audioContext) {
      audioContext.close();
    }

    if (analyser) {
      analyser.disconnect();
    }

    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }

    audioContextRef.current = null;
    analyserRef.current = null;
    streamRef.current = null;
  };

  const startTestingMicrophone = async () => {
    try {
      cleanupAudioContext();

      initializeAudioContext();

      const audioContext = audioContextRef.current;
      const analyser = analyserRef.current;

      if (audioContext && analyser && microphoneInputDevice) {
        if (microphoneInputDevice) {
          try {
            // Create a stream for the current input device
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: {
                deviceId: {
                  exact: microphoneInputDevice,
                },
                echoCancellation: false,
                noiseSuppression: false,
                sampleRate: 44100,
              },
            });

            // Create a source and connect it to the analyser
            const source = audioContext.createMediaStreamSource(stream);
            source.connect(analyser);

            // Create a specific audio output destination
            const audioDestination =
              audioContext.createMediaStreamDestination();

            // Connect the analyser to the custom audio output destination
            analyser.connect(audioDestination);

            // Use the custom audio output destination as the source
            analyser.connect(audioContext.destination);

            // Set up visualization
            visualize();

            setIsTestingMicrophone(true);
            if (!isTestingMicrophone) {
              const toastId = new Date().getTime();
              dispatch(
                setPopupToast({
                  message: `Microphone in use for testing audio connection`,
                  type: "popup",
                  id: toastId,
                })
              );
              setTimeout(() => {
                dispatch(removePopupToast({ id: toastId }));
              }, 2000);
            }
          } catch (error) {
            console.error("Error starting microphone tests:", error);
          }
        }
      }
    } catch (error) {
      console.error("Error starting microphone tests:", error);
    }
  };

  const stopTestingMicrophone = () => {
    cleanupAudioContext();
    setIsTestingMicrophone(false);
  };

  const visualize = () => {
    const analyser = analyserRef.current;
    const canvas = visualizationCanvasRef.current;

    if (!analyser || !canvas) return;

    const canvasContext = canvas.getContext("2d");
    if (!canvasContext) return;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    analyser.getByteFrequencyData(dataArray);

    canvasContext.clearRect(0, 0, canvas.width, canvas.height);

    const barWidth = 3;
    const halfCanvasHeight = canvas.height / 2;

    let barHeight;
    let x = (canvas.width - barWidth * bufferLength) / 2; // Center the bars on the x-axis

    // Draw line on x-axis
    canvasContext.fillStyle = "rgb(100, 0, 100)";
    canvasContext.fillRect(0, halfCanvasHeight - 1, canvas.width, 2);

    for (let i = 0; i < bufferLength; i++) {
      barHeight = dataArray[i];

      // Draw the upper half of the bar
      canvasContext.fillStyle = "rgb(200, 0, 200)";
      canvasContext.fillRect(x, halfCanvasHeight, barWidth, -barHeight / 2);

      // Draw the lower half of the bar
      canvasContext.fillStyle = "rgb(200, 0, 200)";
      canvasContext.fillRect(x, halfCanvasHeight, barWidth, barHeight / 2);

      x += barWidth + 5;
    }

    requestAnimationFrame(visualize);
  };

  const startRecording = async () => {
    initializeWaveSurfer();
    record.startRecording({ deviceId: microphoneInputDevice }).then(() => {
      setOpen({
        recordingButton: true,
        transcript: false,
        defaultPage: false,
        recordingStart: false,
        microPhoneErrorAlert: false,
      });
      setIsRecording(true);
    }).catch((error) => {
      // Handle error
      const toastId = new Date().getTime();
      dispatch(
        setToast({
          message: "Microphone Permission denied",
          type: "error",
          id: toastId,
        })
      );
      setTimeout(() => {
        dispatch(removeToast({ id: toastId }));
      }, 2000);
    });
  };

  const stopRecording = () => {
    if (record?.isRecording() || record?.isPaused()) {
      // Render recorded audio
      record.on("record-end", (blob: Blob) => {
        setAudioRecording(blob);
      });
      record.stopRecording();
      setIsRecording(false);
      setIsPaused(false);
      stopTestingMicrophone();
    }
  };

  const pauseResumeRecording = () => {
    if (record?.isPaused()) {
      record.resumeRecording();
    } else {
      record?.pauseRecording();
    }
    setIsPaused(!isPaused);
  };

  const restartRecording = () => {
    setIsRecording(false);
    setIsPaused(false);
    initializeWaveSurfer();
    startRecording();
  };

  const deleteRecording = () => {
    if (wavesurfer) {
      wavesurfer.destroy();
    }
    setIsPaused(false);
    // setProgressTimer("");
    progressTimer.current = ""
    setIsRecording(false);
    setAudioRecording(undefined);

    setOpen({
      recordingButton: false,
      transcript: false,
      defaultPage: true,
      recordingStart: false,
      microPhoneErrorAlert: false,
    });
  };

  return {
    restartRecording,
    deleteRecording,
    isTestingMicrophone,
    isRecording,
    isPaused,
    startTestingMicrophone,
    stopTestingMicrophone,
    startRecording,
    audioRecording,
    stopRecording,
    setAudioRecording,
    pauseResumeRecording,
    microphoneInputDevice,
    setMicrophoneInputDevice,
    cleanupAudioContext,
    mediaRecorderRef,
    visualizationCanvasRef,
    progressTimer,
  };
};

export default useAudioRecorder;
