type Props = {
  pageLoaderClassName?: string;
  pageLoaderWrapperClassName?: string;
};

const PageLoader = (props: Props) => {
  const { pageLoaderClassName = "" } = props;
  return (
    // <div
    //   className={`flex items-center justify-center w-full py-[30px]  ${pageLoaderClassName}`}
    // >
    //   <div className="w-[70px] h-[70px] p-[10px] rounded-full bg-bgWhiteSD shadow-raiseShadow flex items-center justify-center">
    //     <div className="page__round__loader w-full h-full rounded-full bg-transparent border-[4px] border-l-gray-200 border-r-gray-200 border-t-primaryColor border-b-primaryColor animate-small__loader__sd"></div>
    //   </div>
    // </div>
    <div className="flex items-center justify-center w-full py-[30px] min-h-[100vh] absolute top-0 left-0 backdrop-blur-[1px]">
      <div className="w-[70px] h-[70px] flex items-center justify-center">
        <svg className="loader-svg" width="97" height="102" viewBox="0 0 97 102" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.5 57.2C1.65228 57.2 1 56.5477 1 55.7V36.7C1 35.7262 1.67794 35.0999 2.5 35.0999H59.3C60.173 35.0999 60.9 35.7772 60.9 36.7V55.7C60.9 56.4974 60.1993 57.2 59.3 57.2H2.5ZM94.1 57.2H75.1C74.2523 57.2 73.6 56.5477 73.6 55.7V36.7C73.6 35.7262 74.2779 35.0999 75.1 35.0999H94.1C94.973 35.0999 95.7 35.7772 95.7 36.7V55.7C95.7 56.4974 94.9993 57.2 94.1 57.2ZM94.1 1.69995C94.9993 1.69995 95.7 2.40249 95.7 3.19995V22.2C95.7 23.0477 94.9477 23.7999 94.1 23.7999H37.3C36.5025 23.7999 35.8 23.0993 35.8 22.2V3.19995C35.8 2.35224 36.4523 1.69995 37.3 1.69995H94.1ZM2.5 1.69995H21.5C22.4737 1.69995 23.1 2.37789 23.1 3.19995V22.2C23.1 23.073 22.4227 23.7999 21.5 23.7999H2.5C1.70254 23.7999 1 23.0993 1 22.2V3.19995C1 2.35224 1.65228 1.69995 2.5 1.69995Z" fill="#3A54AC" stroke="#3A54AC" strokeWidth="2" className="svg-elem-1"></path>
          <path d="M95.7 70.2V89C95.7 89.8233 94.9724 90.6 94 90.6H15.1C14.565 90.6 14.0384 90.7643 13.622 91.1643L2.94921 100.441L2.94666 100.443C2.20623 101.074 1 100.559 1 99.5V70.2C1 69.2276 1.77661 68.5 2.6 68.5H94C94.9477 68.5 95.7 69.2522 95.7 70.2Z" fill="#2C2E35" stroke="#2C2E35" strokeWidth="2" className="svg-elem-2"></path>
        </svg>
      </div>
    </div>
  );
};

export default PageLoader;
