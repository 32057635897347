import Button from "components/Theme/Button";
import { useState, DragEvent, ChangeEvent, useRef, useEffect } from "react";
import { File } from "react-feather";

type DraggableFileInputPropsType = {
  handleFile: (files: FileList | null) => void;
  allowedTypes: Array<string>;
  isMultiple: boolean;
  setFile: any;
  file: FileList | undefined;
  loading: boolean;
  isSubmitDisabled?: boolean;
};

const DraggableFileInput = (props: DraggableFileInputPropsType) => {
  const {
    handleFile,
    allowedTypes,
    isMultiple,
    setFile,
    file,
    loading,
    isSubmitDisabled = false,
  } = props;
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    if (files.length > 0 && files?.[0]?.size / 1024 / 1024 < 200) { 
      const validFiles = Array.from(files).filter((file) => {        
        if (file.type.startsWith('audio/') && allowedTypes.includes("audio/*")) {
          return true; // Allow all audio formats
        } else {          
          return allowedTypes.includes(file.type);
        }
      });

      if (validFiles.length === files.length) {
        setFile(files);
        setError("");
      } else {
        setError("Upload Valid file type");
      }
    } else {
      setError("File size should not be more than 200 mb");
    }
  };

  const onHandleFileChange = (files: FileList | null) => {
    if (files && files?.[0]?.size / 1024 / 1024 < 200 && files.length > 0) {
      const validFiles = Array.from(files).filter((file) => {        
        if (file.type.startsWith('audio/') && allowedTypes.includes("audio/*")) {
          return true; // Allow all audio formats
        } else {
          return allowedTypes.includes(file.type);
        }
      });

      if (validFiles.length === files.length) {
        setFile(files);
        setError("");
      } else {
        setError("Upload Valid file type");
      }
    } else {
      setError("File size should not be more than 200 mb");
    }
  };
  const deleteFile = () => {
    setFile(undefined);
  };

  useEffect(() => {
    (isSubmitDisabled === false) && setError("")
  }, [isSubmitDisabled])
  

  return (
    <div>
      {file ? (
        <div
          className={`upload__files__box relative w-full min-h-[302px] flex items-center flex-col justify-center rounded-[14px] text-center p-3 border-dashed border-[2px] border-black before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[14px] before:bg-[#D9DDE1] ${
            isDragOver ? "bg-gray-400" : ""
          }`}
        >
          <div className="flex items-center justify-center z-[9]">
            <div className="mr-3 break-all">{file?.[0].name}</div>
            <div
              className=" cursor-pointer min-w-[16px] w-4 h-4 rounded-full flex items-center group justify-center bg-black hover:bg-transparent border border-black"
              onClick={deleteFile}
            >
              <span className="rotate-45 text-white text-[12px] group-hover:text-black">
                +
              </span>
            </div>
          </div>
          <Button
            className={`mt-3 ${
              isSubmitDisabled ? "pointer-events-none opacity-50" : ""
            }`}
            loading={loading}
            onClick={() => handleFile(file)}
          >
            Submit
          </Button>
        </div>
      ) : (
        <div
          className={`upload__files__box overflow-hidden relative rounded-[14px] border-dashed border-[2px] border-black before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[14px] before:bg-black before:opacity-5 ${
            isDragOver ? "bg-gray-400" : ""
          }`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => inputRef.current?.click()}
        >
          <div className="relative z-[2] py-[20px] px-[20px] min-h-[302px] bg-[#D9DDE1]">
            <div className="file__icon !bg-white">
              <File />
            </div>
            <h3 className="text-[16px] font-semibold text-textDark text-center mb-[10px]">
              Drag & drop file
            </h3>
            <p className="text-[14px] text-center font-normal text-textSecondary max-w-[300px] mx-auto">
              Supports FLAC,MP3,MP4,Ogg,WebM,AMR or WAV file format and Not more
              than 200 mb
            </p>
            <div className="text-textSecondary text-[16px] text-center mt-[20px] mb-[20px] relative before:content-[''] before:absolute before:top-[11px] before:left-0 before:w-[calc(50%_-_20px)] before:h-[1px] before:bg-textSecondary after:content-[''] after:absolute after:top-[11px] after:right-0 after:w-[calc(50%_-_20px)] after:h-[1px] after:bg-textSecondary">
              OR
            </div>
            <div className="flex items-center justify-center mt-[15px]">
              <Button className="uploadFile__btn hover:bg-black" onClick={()=>{isSubmitDisabled && setError("Please select option first")}}>
                Upload Files
              </Button>
            </div>
            <input
              disabled={isSubmitDisabled}
              className="absolute top-0 left-0 w-full h-full z-[3]"
              type="file"
              multiple={isMultiple}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onHandleFileChange(event.target.files)
              }
              accept={allowedTypes.join(",")}
              hidden
              ref={inputRef}
            />
          </div>
        </div>
      )}
      {error && <p className="text-red-500">{error}</p>}{" "}
    </div>
  );
};

export default DraggableFileInput;
