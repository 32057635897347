import { IconInputProps } from ".";

export const H2Icon = ({ className }: IconInputProps) => {
  return (
    
    <svg className={className ?? ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_248_950)">
        <path d="M4.55114 12.5227V19H2.42756V4.45455H4.52273V9.86648H4.65767C4.91335 9.27936 5.30398 8.81297 5.82955 8.46733C6.35511 8.12169 7.04167 7.94886 7.8892 7.94886C8.63731 7.94886 9.29072 8.10275 9.84943 8.41051C10.4129 8.71828 10.8485 9.17756 11.1562 9.78835C11.4688 10.3944 11.625 11.152 11.625 12.0611V19H9.50142V12.3168C9.50142 11.5166 9.29545 10.8963 8.88352 10.456C8.47159 10.0109 7.89867 9.78835 7.16477 9.78835C6.66288 9.78835 6.21307 9.89489 5.81534 10.108C5.42235 10.321 5.11222 10.6335 4.88494 11.0455C4.66241 11.4527 4.55114 11.9451 4.55114 12.5227ZM14.4304 19V17.4091L19.3523 12.3097C19.8778 11.7557 20.3111 11.2704 20.652 10.8537C20.9976 10.4323 21.2557 10.0322 21.4261 9.65341C21.5966 9.27462 21.6818 8.87216 21.6818 8.44602C21.6818 7.96307 21.5682 7.5464 21.3409 7.19602C21.1136 6.84091 20.8035 6.56866 20.4105 6.37926C20.0175 6.18513 19.5748 6.08807 19.0824 6.08807C18.5616 6.08807 18.107 6.1946 17.7188 6.40767C17.3305 6.62074 17.0322 6.9214 16.8239 7.30966C16.6155 7.69792 16.5114 8.15246 16.5114 8.6733H14.4162C14.4162 7.78788 14.6198 7.01373 15.027 6.35085C15.4342 5.68797 15.9929 5.17424 16.7031 4.80966C17.4134 4.44034 18.2206 4.25568 19.125 4.25568C20.0388 4.25568 20.8438 4.43797 21.5398 4.80256C22.2405 5.1624 22.7874 5.65483 23.1804 6.27983C23.5734 6.90009 23.7699 7.60085 23.7699 8.3821C23.7699 8.92188 23.6681 9.44981 23.4645 9.96591C23.2656 10.482 22.9176 11.0573 22.4205 11.6918C21.9233 12.3215 21.232 13.0862 20.3466 13.9858L17.456 17.0114V17.1179H24.0043V19H14.4304Z" fill="#343A40"/>
        </g>
        <defs>
        <clipPath id="clip0_248_950">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  )
}
