// ** packages **
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactNode, useEffect, useState } from "react";
// ** components **
import InputField from "components/FormField/common/inputField";
import PageLoader from "components/Loaders/PageLoader";
import { Modal } from "components/Theme/Modal";
import FileUpload from "components/FileUpload";
import { PROFILE_ALLOWED_MIME_TYPES } from "modules/Setting/sub-modules/PersonalSetting/constant";
import PhoneNumberWithCountryCode from "components/FormField/common/PhoneNumberWithCountryCode";
// ** services **
import {
  useAddPatientAPI,
  useGetPatientAPI,
  useUpdatePatientAPI,
} from "../services/index";
// ** types **
import {
  AddPatientFormPropsType,
  AddPatientFormType,
} from "../types/patients.types";
// ** schema **
import { addPatientSchema } from "../schema/addPatientSchema-validation.ts";
// ** helper **
import { generatePatientInfoFormData } from "../helper";

const AddPatientForm = (props: AddPatientFormPropsType) => {
  const { setIsOpen, userID, refreshData, getDataOnSuccess } = props;
  const [profileImage, setProfileImage] = useState<string | File>("");

  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(addPatientSchema),
  });

  const { getPatientApi, isLoading: getPatientLoading } = useGetPatientAPI();
  const { addPatientApi, isLoading: addPatientLoading } = useAddPatientAPI();
  const { updatePatientAPI, isLoading: updateLoading } = useUpdatePatientAPI();

  const resetData = async () => {
    if (userID) {
      const { data } = await getPatientApi(userID);
      const { name, contact_number, email, profile } = data;
      reset({
        name: name,
        email: email,
        phoneNumber: contact_number,
      });
      setProfileImage(data?.profile);
    }
  };
  useEffect(() => {
    setValue("patient_image", profileImage);
  }, [profileImage]);

  useEffect(() => {
    if (userID) {
      resetData();
    }
  }, [userID]);

  const onSubmit = handleSubmit(async (userData) => {
    const updatedPatientData = {
      name: userData?.name,
      email: userData?.email,
      contact_number: userData?.phoneNumber,
      profile: profileImage,
    };

    if (userID) {
      const { data, error } = await updatePatientAPI(
        generatePatientInfoFormData(updatedPatientData),
        userID
      );
      if (data && !error) {
        if (setIsOpen) {
          setIsOpen(false);
        }
        if (refreshData) {
          refreshData();
        }
        if (getDataOnSuccess) {
          getDataOnSuccess(data);
        }
      }
    } else {
      const { data, error } = await addPatientApi(
        generatePatientInfoFormData(updatedPatientData)
      );
      if (data && !error) {
        if (setIsOpen) {
          setIsOpen(false);
        }
        if (refreshData) {
          refreshData();
        }
        if (getDataOnSuccess) {
          getDataOnSuccess(data);
        }
      }
    }
  });
  const onClose = () => {
    if (setIsOpen) {
      setIsOpen(false);
    }
  };
  const onFileChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (
    e
  ) => {
    const file = e.target.files?.[0];
    if (file && PROFILE_ALLOWED_MIME_TYPES.includes(file.type)) {
      if (file.size < 2 * 1024 * 1024) {
        clearErrors("patient_image");
        setProfileImage(file);
        setValue("patient_image", file);
      } else {
        setError("patient_image", {
          type: "custom",
          message: "Please upload image less than 2 MB",
        });
      }
    } else {
      setValue("patient_image", undefined);
      setError("patient_image", {
        type: "custom",
        message: "Only PNG,JPEG,JPG are allowed",
      });
    }
  };
  return (
    <Modal
      headerName={userID ? "View/Edit patient" : "Create  patient"}
      onSubmit={onSubmit}
      onClose={onClose}
      onCancel={onClose}
      isLoading={addPatientLoading || updateLoading}
    >
      {/* <CustomModal setOpen={setOpen} open={open} title={"add new patient"}> */}
      {getPatientLoading ? (
        <PageLoader pageLoaderClassName="h-[calc(100vh_-_92px)]" />
      ) : (
        <>
          <form onSubmit={onSubmit}>
            <div className="flex bg-bgWhiteSD shadow-raiseShadow p-[14px] rounded-[8px] mb-[14px] items-center">
              <div className="fileUploadBox inline-block shrink-0">
                <div className="inline-block relative">
                  <FileUpload
                    onFileChange={onFileChange}
                    setProfileImage={setProfileImage}
                    image={profileImage}
                  />
                </div>
              </div>
              <div className="text-[14px] font-normal text-textDark w-full pt-[12px] pl-[15px]">
                The patient picture must be uploaded in PNG, JPEG & JPG format
                and cant be more than 2 MBs in size
              </div>
            </div>
            {errors?.patient_image ? (
              <p className="text-red-500 text-size-[5px]">
                {errors?.patient_image.message as ReactNode}
              </p>
            ) : (
              ""
            )}
            <InputField
              id="firstName"
              name="name"
              label="Name"
              register={register}
              required
              type="text"
              placeholder="Enter your Name"
              errors={errors.name}
              className="disabled:bg-slate-200"
            />
            <InputField
              id="email"
              name="email"
              label="Email"
              register={register}
              required
              type="text"
              placeholder="Enter your Email"
              errors={errors.email}
              className="disabled:bg-slate-200"
            />

            <PhoneNumberWithCountryCode
              name="phoneNumber"
              label="phone"
              placeholder="EX. (XXX) XXX-XXXX"
              register={register}
              inputMode="numeric"
              required={false}
              control={control}
              errors={errors.phoneNumber}
            />
          </form>
        </>
      )}
      {/* </CustomModal> */}
    </Modal>
  );
};
export default AddPatientForm;
