import { PERMISSIONS } from "constants/Permissions.constant";

export const NAVIGATION_ITEM = [
  { path: "/", label: "Dashboard", icon: "DashboardIcon" },
  {
    path: "/patient",
    label: "Patient",
    icon: "PatientPlusIcon",
    permission: PERMISSIONS.PATIENT.READ,
    // subItems: [
    //   {
    //     label: "Patient",
    //     icon: "Table",
    //     path: "/patient",
    //   },
    //   // {
    //   //   label: "Appointments",
    //   //   icon: "Book",
    //   //   path: "/patient/appointment",
    //   // },
    // ],
  },
  {
    path: "/content",
    label: "Content",
    icon: "ContentListIcon",
    permission: PERMISSIONS.NOTES.READ,
  },
  {
    path: "/voice",
    label: "Voice",
    icon: "AmplifierVoiceIcon",
    permission: PERMISSIONS.NOTES.READ,
  },
  {
    path: "/folder",
    label: "Meeting",
    icon: "MeetingIcon",
    permission: PERMISSIONS.NOTES.READ,
  },
  // { path: "/transcription", label: "Transcription", icon: "FileText" },
  {
    path: "/setting/personalInformation",
    label: "Setting",
    icon: "SolidSettingIcon",
    subItems: [
      {
        label: "Personal Information",
        icon: "FilledOneManIcon",
        path: "/setting/personalInformation",
      },
      {
        label: "User Management",
        icon: "SolidDoubleManIcon",
        path: "/setting/user",
        permission: PERMISSIONS.USER.READ,
      },
      {
        label: "Role And Permission",
        icon: "SolidLockIcon",
        path: "/setting/rolesAndPermissions",
        permission: PERMISSIONS.ROLE.READ,
      },
      {
        label: "Prompts",
        icon: "Terminal",
        path: "/setting/prompts",
        permission: PERMISSIONS.CUSTOM_PROMPT.READ,
      },
      {
        label: "Playbooks",
        icon: "PlayIcon",
        path: "/setting/playbook",
        permission: PERMISSIONS.PLAYBOOK.READ,
      },
    ],
  },
];

export const ADMIN_NAVIGATION_ITEM = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
    icon: "DashboardIcon",
  },
  {
    path: "/admin/customer",
    label: "Customer",
    icon: "SolidDoubleManIcon",
    permission: PERMISSIONS.CUSTOMER.READ,
  },
  {
    path: "/admin/prompts",
    label: "Prompts",
    icon: "Terminal",
    permission: PERMISSIONS.CUSTOM_PROMPT.READ,
  },
  {
    path: "/admin/super-admin",
    label: "Setting",
    icon: "SolidSettingIcon",
    subItems: [
      {
        path: "/admin/super-admin",
        label: "User",
        icon: "FilledOneManIcon",
        permission: PERMISSIONS.USER.READ,
      },
      {
        label: "Role",
        icon: "SolidLockIcon",
        path: "/admin/rolesAndPermissions",
        permission: PERMISSIONS.ROLE.READ,
      },
    ],
  },
];
