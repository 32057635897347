export const PUBLIC_NAVIGATION = Object.freeze({
  login: "/auth/login",
  register: "/auth/register",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/set_forgot_password/",
  // notFoundPage: "/404",
  notAuthorized: "/not-authorized",
  verifyAccount: "/auth/account_activation/",
  setPassword: "/auth/set_password/",
  sharedTranscription: (id = ":id") => `/shared/${id}`,
});

export const PRIVATE_NAVIGATION = Object.freeze({
  dashboard: { view: "/" },
  adminDashboard: { view: "/admin/dashboard" },
  customer: {
    view: "/admin/customer",
    detail: {
      view: (id = ":id") => `/admin/customer/${id}`,
    },
  },
  adminPrompt: {
    view: "/admin/prompts",
  },
  notFoundPage: { view: "/404" },
  adminNotFoundPage: "/admin/404",
  adminSetting: {
    rolesAndPermissions: {
      view: "/admin/rolesAndPermissions",
    },
    superuser: { view: "/admin/super-admin" },
  },
  setting: {
    personalInformation: {
      view: "/setting/personalInformation",
    },
    userManagement: {
      view: "/setting/user",
      detail: {
        view: (id = ":id") => `/setting/user/${id}`,
      },
      edit: {
        view: (id = ":id") => `/setting/user/${id}/edit`,
      },
    },
    rolesAndPermissions: {
      view: "/setting/rolesAndPermissions",
    },
    prompt: {
      view: "/setting/prompts",
    },
    playbook: {
      view: "/setting/playbook",
    },
  },
  patient: {
    view: "/patient",
    detail: {
      view: (id = ":id") => `/patient/${id}`,
      // appointment: "/patient/appointment",
    },
    recording: (id = ":id") => `/patient/recording/${id}`,
    appointmentDetail: (id = ":id", patientId = ":patientId") =>
      `/patient/${patientId}/appointment/${id}`,
  },
  transcription: {
    view: "/transcription",
    detail: {
      view: (id = ":id") => `/transcription/${id}`,
    },
  },
  recording: {
    view: "/recording",
  },
  notes: {
    view: "/notes",
  },
  MeetingFolder: {
    view: "/folder",
    detail: {
      view: (id = ":id") => `/folder/${id}`,
    },
    recording: (id = ":id") => `/folder/recording/${id}`,
  },
  content:{
    view: "/content"
  },
  voice:{
    view: "/voice"
  }
});
