// ** packages **
import { useEffect, useRef, useState } from "react";
import { Edit3, Loader } from "react-feather";


// ** hooks **
import Note from "components/Note";
import InputField from "components/FormField/common/inputField";
import { Editor } from "@tiptap/core";
import { useUpdateTemplatePlaybookAPI, useAddTemplatePlaybookAPI } from "../services";
import { tipTapNoteType } from "modules/Patient/sub-modules/Recording/types/recording.types";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { useNavigate } from "react-router-dom";
import AlertModal from "components/modal/AlertModal";
import { firstCharUpperCase } from "utils";
import { useGetAllNoteTypeAPI } from "modules/Setting/sub-modules/PersonalSetting/services";
import MultiSelect, { MultiSelectOptionTypes } from "components/FormField/common/MultiSelect";


const PlaybookForm = (props: any) => {
  const { isNoteOpen, setIsNoteOpen, currentNote, setCurrentNote } = props;
  const [notes, setNotes] = useState<tipTapNoteType[]>([]);
  const [isCurrentlyEdit, setIsCurrentlyEdit] = useState<boolean>(false);
  const publishRef = useRef<HTMLButtonElement>(null);
  const [description, setDescription] = useState<string | null | undefined>(null);
  const [playbookType, setPlaybookType] = useState<MultiSelectOptionTypes[] | any>([]);
  const [showError, setShowError] = useState(false)
  const [isSaveDefault, setIsSaveDefault] = useState<boolean>(false)
  const [saveOption, setSaveOption] = useState<MultiSelectOptionTypes | any>([])
  const { getNoteTypesApi } = useGetAllNoteTypeAPI();
  const { addTemplatePlaybookAPI, isLoading, isError } = useAddTemplatePlaybookAPI()
  const { updateTemplatePlaybookAPI } = useUpdateTemplatePlaybookAPI()
  const { hasPermission } = usePermission();
  const playbookReadPermission = hasPermission(PERMISSIONS.PLAYBOOK.READ);
  const playbookUpdatePermission = hasPermission(PERMISSIONS.PLAYBOOK.UPDATE);
  const playbookCreatePermission = hasPermission(PERMISSIONS.PLAYBOOK.CREATE);
  const navigator = useNavigate()
  const noteContent = useRef<string>("");


  useEffect(() => {
    if (!playbookReadPermission) {
      navigator(-1)
    }
    if (currentNote) {
      setDescription(currentNote?.description);
      currentNote?.type && setPlaybookType(currentNote.type)
    }
  }, [currentNote])
  const onSaveTipTapNote = async (editor: Editor | null) => {
    if (editor) {
      const newCurrentNote = Object.assign({}, currentNote);
      noteContent.current = editor?.getHTML();
      newCurrentNote.content = editor?.getHTML();
      newCurrentNote.description = description;
      newCurrentNote.type = playbookType;
      const getIndex = notes.findIndex((note) => note?.id === currentNote?.id);
      const newNotes = Object.assign([], notes);
      newNotes.splice(getIndex, 1, newCurrentNote);
      setIsCurrentlyEdit(false)
      setIsSaveDefault(true)
    }
  };

  const onDefaultSave = async (isTemplate: boolean | null | undefined) => {   
    if (playbookType?.length === 0) {
      setShowError(true)
    }
    else {
      if (publishRef) {
        publishRef?.current?.click()
      }
      const newCurrentNote = Object.assign({}, currentNote);
      newCurrentNote["content"] = noteContent?.current || ""
      newCurrentNote.description = description;
      newCurrentNote.type = playbookType;
      if (currentNote?.id) {
        await updateTemplatePlaybookAPI(newCurrentNote, newCurrentNote.id)
      }
      else {
        newCurrentNote.isTemplate = true;
        await addTemplatePlaybookAPI(newCurrentNote)
      }
      const getIndex = notes.findIndex((note) => note?.id === currentNote?.id);
      const newNotes = Object.assign([], notes);
      newNotes.splice(getIndex, 1, newCurrentNote);
      setNotes(newNotes);
      setCurrentNote(newCurrentNote);
      setDescription(newCurrentNote.description)
      setPlaybookType(newCurrentNote.type)
      setIsSaveDefault(false)
      setIsNoteOpen(false)
      if (!playbookReadPermission) {
        navigator(-1)
      }
      if (currentNote) {
        currentNote?.description && setDescription(currentNote.description);
        currentNote?.type && setPlaybookType(currentNote.type)

      }
    }
  }

  const getPromptTypes = async () => {
    const { data, error } = await getNoteTypesApi();
    if (!error && data) {
      const dataT: string[] = data
      const tempData = dataT.map(item => {
        return ({ "title": `Save as ${firstCharUpperCase(item)}`, value: item })
      })
      if (tempData && saveOption) {
        tempData.push(...saveOption)
        setSaveOption(tempData);
      }
    }
  };

  useEffect(() => {
    playbookReadPermission && getPromptTypes()
  }, [])

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentNote) {
      setPlaybookType(currentNote?.type)
      setCurrentNote({
        title: e?.target?.value,
        content: currentNote?.content,
        id: currentNote?.id,
        description: description ? description : currentNote?.description,
        type: currentNote?.type,
      });
    }
  };

  const changeNoteName = () => {
    if (currentNote) {
      const ind = notes.findIndex((note) => note?.id === currentNote?.id);
      const newNotes = Object.assign([], notes);
      newNotes.splice(ind, 1, currentNote);
      setNotes(newNotes);
      setIsCurrentlyEdit(false);
    }
  };

  const headerOfTipTapNote = (setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>) => {
    return (
      <>
        {
          isLoading ? <Loader /> :
            <div className="flex items-center gap-2">
              {isCurrentlyEdit ? (
                <InputField
                  name="currentNote"
                  value={currentNote?.title}
                  onChange={(e) => onChangeTitle(e)}
                  onKeyDown={(e) => {
                    e?.key === "Enter" && changeNoteName();
                    setIsDirty && setIsDirty(true)
                  }}
                  autoComplete="off"
                  className="input__DSD flex"
                  fieldWrapperClassName="!m-0"
                />
              ) : (
                <span>{currentNote?.title}</span>
              )}
              {!isCurrentlyEdit && (playbookUpdatePermission || (!currentNote?.content && playbookCreatePermission)) && (
                <Edit3
                  className="size-4 cursor-pointer"
                  onClick={() => {
                    setIsCurrentlyEdit(true);
                  }}
                />
              )}
            </div>
        }
      </>
    );
  };

  return (
    <div className="w-full px-[15px] sm:px-0">
      {isNoteOpen && (
        <>
          <Note
            content={currentNote?.content}
            isEdit={playbookUpdatePermission || (!currentNote?.content && playbookCreatePermission)}
            isLoading={false}
            mainDivClassName=""
            isLoadingSave={false}
            needAlert={true}
            needMenu={true}
            onSave={onSaveTipTapNote}
            saveBtnName="Save"
            publishRef={publishRef}
            contentId=""
            cancelBtnName="Close"
            onCancel={() => {
              setIsNoteOpen(false);
            }}
            fileName={headerOfTipTapNote}
            EditorClassName={"h-[50vh] overflow-y-auto"}
            description={description ? description : ""}
            setDescription={setDescription}
            setIsSaveDefault={null}
          />
          {!currentNote?.isTemplate && <AlertModal visible={isSaveDefault} showFooter showCrossIcon onSubmit={() => onDefaultSave(currentNote?.isTemplate)} onClose={() => setIsSaveDefault(false)} onCancel={() => setIsSaveDefault(false)} submitButtonText="Save">
            <div className="text-center">
              <p>Select Note type</p>
              <MultiSelect options={saveOption} setShowError={setShowError} selectedValue={playbookType} setSelectedValue={setPlaybookType} />
              <p className="text-red-700 text-sm">{showError && "Please select option"}</p>
            </div>
          </AlertModal>}
        </>
      )}
    </div>
  );
};

export default PlaybookForm;
