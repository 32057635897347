import Note from "components/Note";
import SelectMenu, { optionsType } from "components/SelectMenu";
import { useEffect, useState } from "react";
import { Edit } from "react-feather";
import { useParams } from "react-router-dom";
import {
  useGetPlayBookAPI,
  useUpdatePlayBookAPI,
} from "../../Summary/services";
import { Editor } from "@tiptap/core";
import PageLoader from "components/Loaders/PageLoader";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";

type contentType = {
  content: string;
  id: number;
  title: string;
  description?: string | null;
};

const PlayBook = () => {
  const { id } = useParams();
  const [content, setContent] = useState<contentType[]>();
  const [isEditEnable, setIsEditEnable] = useState<boolean>(false);
  const { getPlayBookApi, isLoading } = useGetPlayBookAPI();
  const { updatePlaybookApi, isLoading: isUpdateLoading } =
    useUpdatePlayBookAPI();
  const [currentOption, setCurrentOption] = useState<contentType>();
  const { hasPermission } = usePermission();
  const noteBookUpdatePermission = hasPermission(PERMISSIONS.NOTES.UPDATE);

  const getAllPlayBooks = async () => {
    if (id) {
      const { data, error } = await getPlayBookApi(id);
      if (!error && data) {
        setContent(data);
        if (data.length > 0) {
          setCurrentOption(data[0]);
        }
      }
    }
  };

  const onSave = async (editor: Editor | null) => {    
    if (currentOption?.id && editor) {
      const payload = {
        title: currentOption?.title,
        content: editor?.getHTML(),
      };
      await updatePlaybookApi(payload, currentOption?.id);
      setIsEditEnable(false);
      const findCurrentIndex = content?.findIndex(
        (note) => note?.id === currentOption?.id
      );
      if (findCurrentIndex) {
        const newContent: contentType[] = Object.assign([], content);
        newContent.splice(findCurrentIndex, 1, currentOption);
        setContent(newContent);
      }
    }
  };

  const onCancel = () => {
    setIsEditEnable(false);
  };

  useEffect(() => {
    getAllPlayBooks();
  }, []);

  const onSelect = (value: optionsType) => {
    const options = content?.find((data) => data?.id === value?.id);
    setCurrentOption(options);
  };

  return (
    <div className="w-full px-[15px]">
      {isLoading ? (
        <PageLoader />
      ) : (
        <div>
          <div className="mb-2 flex justify-end md:mb-4">
            {content && currentOption && (
              <SelectMenu
                publishingOptions={content}
                selected={currentOption}
                onSelect={onSelect}
                isLoading={isLoading}
              />
            )}
          </div>
          {currentOption ? (
            <div className={`flex flex-wrap ${isLoading && "justify-center"}`}>
              <Note
                content={currentOption?.content}
                isEdit={isEditEnable}
                setIsEdit={setIsEditEnable}
                isLoading={isLoading}
                EditorContentClassName="w-full"
                onSave={onSave}
                mainDivClassName="w-full"
                isLoadingSave={isUpdateLoading}
                needAlert={true}
                onCancel={onCancel}
                contentId={currentOption?.id}
                hasUpdatePermission={noteBookUpdatePermission}
              />
            </div>
          ) : (
            !content && <div className="text-center text-lg p-5"> No PlayBook Found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlayBook;
