// ** packages **
import { useDispatch, useSelector } from "react-redux";

// ** Redux **
import { getToast, removeToast } from "redux/slices/toastSlice";

// ** CSS **
import "./style/toast.css";
import { Bell, X } from "react-feather";

const Toast = () => {
  const toastMessage = useSelector(getToast);
  const dispatch = useDispatch();
  return (
    <>
      {toastMessage.length ? (
        <div className="toast__wrapper__SD hideScrollbar__SD !z-[200]">
          <div className="toast__CN__absolute">
            {toastMessage.map((toast) => (
              <div
                key={toast.id}
                className={`toast__SD__item show ${toast.type}`}
              >
                <div className="inner__wrapper">
                  <div className="typeIcon">
                    <Bell />
                  </div>
                  <p className="toast__text">{toast.message}</p>
                  <div
                    className="closeBtn"
                    onClick={() => dispatch(removeToast({ id: toast.id }))}
                  >
                    <X />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Toast;
