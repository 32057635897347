import { IconInputProps } from ".";

export const InProgressIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_44_106)">
        <path
          d="M26.8597 22.3055C26.3404 21.9192 25.6064 22.0271 25.22 22.5463C25.0076 22.8319 24.7802 23.112 24.5443 23.3789C24.1157 23.8638 24.1612 24.6044 24.6461 25.0331C24.8691 25.2302 25.1459 25.327 25.4218 25.327C25.7459 25.327 26.0687 25.1932 26.3002 24.9313C26.5797 24.6152 26.8489 24.2834 27.1005 23.9452C27.4868 23.426 27.3791 22.6918 26.8597 22.3055Z"
          fill="black"
        />
        <path
          d="M28.8196 16.8097C28.1876 16.6721 27.5629 17.0731 27.4253 17.7055C27.3495 18.0534 27.258 18.4019 27.153 18.7415C26.962 19.3599 27.3084 20.016 27.9267 20.2071C28.042 20.2427 28.1585 20.2596 28.2731 20.2596C28.7735 20.2596 29.2368 19.9365 29.3923 19.4333C29.5169 19.0303 29.6255 18.6167 29.7154 18.204C29.853 17.5717 29.452 16.9474 28.8196 16.8097Z"
          fill="black"
        />
        <path
          d="M21.2191 26.0587C20.9079 26.2323 20.5858 26.3946 20.2617 26.5411C19.6718 26.8074 19.4096 27.5015 19.676 28.0914C19.8717 28.5247 20.2982 28.7812 20.7446 28.7812C20.9059 28.7812 21.0698 28.7478 21.2263 28.6771C21.6108 28.5034 21.9927 28.3109 22.3617 28.105C22.9268 27.7895 23.1291 27.0756 22.8135 26.5105C22.4981 25.9455 21.7842 25.7432 21.2191 26.0587Z"
          fill="black"
        />
        <path
          d="M13.8281 5.625V14.5146L9.53093 18.8118C9.07331 19.2694 9.07331 20.0114 9.53093 20.469C9.75979 20.6979 10.0596 20.8123 10.3596 20.8123C10.6595 20.8123 10.9594 20.6978 11.1883 20.469L15.8287 15.8286C16.0484 15.6089 16.1719 15.3108 16.1719 15V5.625C16.1719 4.97777 15.6472 4.45312 15 4.45312C14.3528 4.45312 13.8281 4.97777 13.8281 5.625Z"
          fill="black"
        />
        <path
          d="M28.8281 2.51953C28.1809 2.51953 27.6562 3.04418 27.6562 3.69141V6.94547C24.9286 2.66766 20.1651 0 15 0C10.9934 0 7.22654 1.56029 4.39336 4.39336C1.56029 7.22654 0 10.9934 0 15C0 19.0066 1.56029 22.7735 4.39336 25.6066C7.22654 28.4397 10.9934 30 15 30C15.0099 30 15.0195 29.9988 15.0293 29.9985C15.0391 29.9988 15.0487 30 15.0586 30C15.4809 30 15.9074 29.9822 16.3264 29.9471C16.9713 29.8931 17.4504 29.3265 17.3964 28.6816C17.3423 28.0366 16.7765 27.5573 16.1308 27.6116C15.7767 27.6412 15.416 27.6562 15.0586 27.6562C15.0487 27.6562 15.0391 27.6575 15.0293 27.6577C15.0195 27.6575 15.0099 27.6562 15 27.6562C8.02131 27.6562 2.34375 21.9787 2.34375 15C2.34375 8.02131 8.02131 2.34375 15 2.34375C19.4975 2.34375 23.6342 4.741 25.8947 8.55469H22.6724C22.0252 8.55469 21.5006 9.07934 21.5006 9.72656C21.5006 10.3738 22.0252 10.8984 22.6724 10.8984H26.25C26.9637 10.8984 27.6313 10.6979 28.1998 10.3504C28.2364 10.3296 28.2715 10.3071 28.3051 10.2829C29.3249 9.61195 30 8.45783 30 7.14844V3.69141C30 3.04418 29.4754 2.51953 28.8281 2.51953Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_44_106">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
