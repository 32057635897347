// ** packages **
import { AxiosRequestConfig } from "axios";
import { useAxiosPost } from "hooks/useaxios";
const PATIENT_API_BASE_PATH = "/transcript";
export const useAddAudioRecordingAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addAudioRecordingApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(
      `${PATIENT_API_BASE_PATH}/generate_transcript`,
      data,
      config
    );
  };
  return { addAudioRecordingApi, isLoading, isError, isSuccess };
};
