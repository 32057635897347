// =================== import packages ==================
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// ======================================================
import { RootStateType } from "redux/store";

export type toastPopupSliceType = {
  message?: string | null;
  conversationId?: number;
  emailId?: number;
};

export type ToastSliceType = {
  message: string | null;
  type: string | null;
  id: number;
  popupMail?: toastPopupSliceType;
};
export type ToastCommonSliceType = {
  toasts: ToastSliceType[];
  toastPopupSlice: ToastSliceType[];
};

const initialState: ToastCommonSliceType = {
  toasts: [],
  toastPopupSlice: [],
};

const toastSlice = createSlice({
  name: "commonToast",
  initialState,
  reducers: {
    setToast(
      state: ToastCommonSliceType,
      action: PayloadAction<ToastSliceType>
    ) {
      state.toasts.push({
        message: action.payload.message,
        type: action.payload.type,
        id: action.payload.id,
      });
    },
    removeToast(
      state: ToastCommonSliceType,
      action: PayloadAction<{ id: number }>
    ) {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload.id
      );
    },

    setPopupToast(
      state: ToastCommonSliceType,
      action: PayloadAction<ToastSliceType>
    ) {
      state.toastPopupSlice.push({
        message: action.payload.message,
        type: action.payload.type,
        id: action.payload.id,
      });
    },

    removePopupToast(
      state: ToastCommonSliceType,
      action: PayloadAction<{ id: number }>
    ) {
      state.toastPopupSlice = state.toastPopupSlice.filter(
        (toast) => toast.id !== action.payload.id
      );
    },
  },
});
export const { reducer } = toastSlice;
export const { setToast, removeToast, removePopupToast, setPopupToast } =
  toastSlice.actions;
export const getToast = (state: RootStateType) => state.commonToast.toasts;
export const getPopupToast = (state: RootStateType) =>
  state.commonToast.toastPopupSlice;

export default toastSlice;
