import { FormFieldProps } from "../types/formField.types";
import { Controller } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";
import Label from "./Label";

const PhoneNumberWithCountryCode = <
  TFormValues extends Record<string, unknown>
>(
  fieldProps: FormFieldProps<TFormValues>
) => {
  const {
    name,
    control,
    id,
    label,
    placeholder,
    labelClass,
    required,
    errors,
  } = fieldProps;

  return (
    <>
      <div className="form__group">
        <div className="field__wrapper mb-0">
          {label && (
            <Label
              id={id}
              label={label}
              required={required}
              labelClass={labelClass}
            />
          )}

          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
              const valueData = value ?? null;
              return (
                <PhoneInput
                  country="us"
                  placeholder={placeholder}
                  onChange={(
                    valueNo: string,
                    data: CountryData,
                    event: React.ChangeEvent<HTMLInputElement>,
                    formattedValue: string
                  ) => {
                    if (formattedValue === "+") {
                      onChange("");
                    } else {
                      onChange(formattedValue);
                    }
                    if (event.type === "click") {
                      const el = document.getElementById(name as any);
                      el?.focus();
                    }
                  }}
                  inputProps={{ id: name }}
                  value={valueData as string}
                />
              );
            }}
          />
        </div>
        {errors?.message && <p className="error__message">{errors?.message}</p>}{" "}
      </div>
    </>
  );
};

export default PhoneNumberWithCountryCode;
