// ** packages **
import React, { useEffect, useMemo, useState } from "react";
import { MoreVertical } from "react-feather";
import { useNavigate } from "react-router-dom";
// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";
import TippyDropdown from "components/TippyDropDown";
import TranscriptionStatus from "components/Badge/TranscriptionStatus";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
// ** helper **
import { renderOptions } from "components/TippyDropDown/helper";
import DateFormat from "components/DateFormat";
import { HoursMinSecFormat, firstCharUpperCase } from "utils";
import TranscriptionBtn from "components/GroupBtn/TranscriptionBtn";
import { useDeleteTranscriptAPI, useRetryTranscriptAPI } from "modules/Patient/sub-modules/Transcription/services";
import { TableRefType } from "components/DataTable";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import SvgIcon from "components/Icon/SvgIcon";
import Button from "components/Theme/Button";

type useNotesColumnPropsType = {
  currentNote: string;
  tableRef: React.RefObject<TableRefType>;
  onDeleteClick: (id: number | string) => void
};

const useNotesColumn = (props: useNotesColumnPropsType) => {
  const { currentNote, tableRef, onDeleteClick } = props;
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const hasNoteDeletePermission = hasPermission(PERMISSIONS.NOTES.DELETE);
  const navigateToTranscript = (
    id: string | number,
    OpenSummary: boolean = false,
    mode: string = "patient"
  ) => {
    navigate(PRIVATE_NAVIGATION.transcription.detail.view(String(id)), {
      state: {
        mode: mode,
        tab: {
          summary: OpenSummary,
          transcript: !OpenSummary,
          share: false,
          playbook: false,
        },
        navHighlighter: mode,
      },
    });
  };

  const { retryTranscriptAPI, isLoading: isRetryLoading } =
    useRetryTranscriptAPI();

  const onRetryTranscript = async (id: number | undefined) => {
    if (id) {
      await retryTranscriptAPI(id);
      tableRef?.current?.refreshData();
    }
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1300 && windowSize < 1300) ||
      (window.innerWidth < 1300 && windowSize >= 1300)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);


  const columnDefs = useMemo(
    () => [
          (windowSize > 1300) ? {
            name: "Name",
            selector: (row: { file_name: string }) => row.file_name,
            sortable: true,
            sortField: "file_name",
            cell: (row: { file_name: string; id: string | number; status: string; }) => (
              <TableCellRender
                className="w-100 container"
                cellRowData={
                  <div className="flex items-center justify-between">
                    <span
                      className="cursor-pointer w-full" onClick={() =>
                        navigateToTranscript(row?.id, false, currentNote)}
                    >
                      {row.file_name}
                    </span>
                    {(row?.status.toLowerCase() === "failed" ||
                      row?.status.toLowerCase() === "rejected") ? <></> :
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        <Button
                          type="button"
                          className="relative self-end -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-indigo-100 hover:ring-primaryColor focus:z-10"
                          onClick={() => navigateToTranscript(row?.id, true, currentNote)}>
                          <SvgIcon iconName="AINotesIcon" className="" />
                        </Button>
                      </span>
                    }
                  </div>
                }
              />
            ),
          } : {
            name: "Name",
            selector: (row: { file_name: string, status: string }) => row.file_name,
            sortable: true,
            sortField: "file_name",
            cell: (row: {
              file_name: string;
              status: string;
              created: { first_name: string; last_name: string }
              created_at: string
              id: number;
            }) => (
              <TableCellRender
                className="flex overflow-hidden w-[90vw]"
                cellRowData={
                  <>
                    <div
                      className="container mx-auto px-4 py-8 cursor-pointer"
                      onClick={() => navigateToTranscript(row?.id, false, currentNote)}
                    >
                      <div className="flex gap-2 items-center">
                        <span className="font-medium text-gray-900 truncate">{row.file_name}</span>
                        <div className="checkmark">
                          <TranscriptionStatus status={row?.status} />
                        </div>
                      </div>
                      <div className="lg:ml-0 lg:mt-1">
                        <div className="flex gap-2 truncate mt-1 text-gray-500">
                          {row?.created && <span>Uploaded by {row?.created?.first_name + " " + row?.created?.last_name}</span>}
                          &#x2022;{" "}{row.created_at && <DateFormat date={row.created_at} format="dd MMM yyyy" />}
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            ),
          },
          (windowSize > 1300) && {
            name: "Uploaded By",
            selector: (row: {
              created: { first_name: string; last_name: string };
            }) => row?.created?.first_name,
            sortField: "first_name",
            width: "10%",
            sortable: false,
            cell: (row: { created: { first_name: string; last_name: string } }) => (
              <TableCellRender
                cellRowData={
                  firstCharUpperCase(row?.created?.first_name) +
                  " " +
                  firstCharUpperCase(row?.created?.last_name)
                }
              />
            ),
          },
          (windowSize > 1300) && {
            name: "Uploaded Date",
            selector: (row: { created_at: string }) => row.created_at,
            sortField: "created_at",
            width: "15%",
            sortable: true,
            cell: (row: { created_at: string }) => (
              <TableCellRender
                cellRowData={
                  <DateFormat date={row.created_at} format="dd MMM yyyy, hh:mm a" />
                }
              />
            ),
          },
          (windowSize > 1300) && {
            name: "Duration",
            selector: (row: { file_duration: string }) => row.file_duration,
            sortable: false,
            width: "10%",
            sortField: "file_duration",
            cell: (row: { file_duration: string }) => (
              <TableCellRender
                cellRowData={HoursMinSecFormat(parseInt(row.file_duration))}
              />
            ),
          },
          (windowSize > 1300) && {
            name: "Status",
            selector: (row: { status: string }) => row.status,
            sortField: "created_at",
            sortable: true,
            width: "8%",
            cell: (row: { status: string }) => (
              <TableCellRender
                cellRowData={
                  <div className="checkmark">
                    <TranscriptionStatus status={row?.status} />
                  </div>
                }
              />
            ),
          },
          {
            name: "Actions",
            selector: (row: { id: string | number }) => row.id,
            sortable: false,
            // width: "15%",
            // cell: (row: { id: string | number; reference_id: string | number }) => {
            //   const PatientAdd: {
            //     label: string;
            //     onClick: () => void;
            //   }[] = [];
            //   PatientAdd.push({
            //     label: "View",
            //     onClick: () => {
            //       if (currentNote === "patient") {
            //         navigate(
            //           `${PRIVATE_NAVIGATION.patient.detail.view(
            //             String(row.reference_id)
            //           )}`,
            //           { state: "patient" }
            //         );
            //       } else if (currentNote === "voice") {
            //         navigate(
            //           `${PRIVATE_NAVIGATION.transcription.detail.view(
            //             String(row.id)
            //           )}`,
            //           {
            //             state: {
            //               mode: "voice",
            //               tab: {
            //                 summary: false,
            //                 transcript: true,
            //                 share: false,
            //                 playbook: false,
            //               },
            //             },
            //           }
            //         );
            //       } else {
            //         navigate(
            //           `${PRIVATE_NAVIGATION.transcription.detail.view(
            //             String(row.id)
            //           )}`,
            //           {
            //             state: {
            //               mode: "content",
            //               tab: {
            //                 summary: false,
            //                 transcript: true,
            //                 share: false,
            //                 playbook: false,
            //               },
            //             },
            //           }
            //         );
            //       }
            //     },
            //   });
            //   return (
            //     <TippyDropdown
            //       content={({ close }) =>
            //         renderOptions({ close, filedArray: PatientAdd })
            //       }
            //     >
            //       <MoreVertical />
            //     </TippyDropdown>
            //   );
            // },
            cell: (row: {
              id: string | number;
              status: string;
              note_type: string;
            }) => (
              <TableCellRender
                cellRowData={
                  <TranscriptionBtn
                    onDelete={onDeleteClick}
                    hasNoteDeletePermission={hasNoteDeletePermission}
                    id={row?.id}
                    status={row?.status}
                    onClick={navigateToTranscript}
                    onClickFailed={onRetryTranscript}
                    isRetryLoading={isRetryLoading}
                    currentRetryId={row?.id}
                    noteType={row?.note_type}
                  />
                }
              />
            ),
          },
        ],
    [currentNote, windowSize])  

  return columnDefs;
};

export default useNotesColumn;
