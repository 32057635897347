import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import PlaybookTemplateList from "modules/Admin/sub-module/AdminPrompt/components/PlaybookTemplateList";

const PlaybookSettingUser = () => {
  return (
    <div>
      <Breadcrumbs path={BREAD_CRUMB.PlaybooksSetting} />
      <div className="bg-bgWhiteSD view-prompt-modal rounded-[10px] shadow-raiseShadow p-[24px] sm:p-[12px] pb-[16px] mt-[20px]">
        <PlaybookTemplateList />
      </div>
    </div>
  );
};
export default PlaybookSettingUser;
