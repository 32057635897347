// ** packages *
import { useEffect, useState } from "react";
import { CheckCircle, Mic, MoreVertical } from "react-feather";
import { useParams, useNavigate } from "react-router-dom";
import { format, isToday, isTomorrow } from "date-fns";

// ** components **
import UserFileView from "components/UserField/UserFieldView";
import DateFormat from "components/DateFormat";
import PageLoader from "components/Loaders/PageLoader";
import TippyDropdown from "components/TippyDropDown";

// ** services  **
import { useAppointmentScheduleDetailsGetAPI } from "../services";
// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
// ** utils **
import { isTodayDate, isTomorrowDate } from "utils";
const AppointmentScheduleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [appointmentScheduleDetailsData, setAppointmentScheduleDetailsData] =
    useState<{
      patientName: string;
      transcript: [];
      appointmentTime: string;
      id: number | null;
      patient_id: number | null;
      status: string;
      subjectName: string;
    }>({
      subjectName: "",
      patientName: "",
      transcript: [],
      appointmentTime: "",
      id: null,
      patient_id: null,
      status: "string",
    });
  const { getAppointmentScheduleInformationAPI, isLoading } =
    useAppointmentScheduleDetailsGetAPI();
  const appointmentAllDetails = async () => {
    const { data, error } = await getAppointmentScheduleInformationAPI(id);
    if (data && !error) {
      setAppointmentScheduleDetailsData({
        subjectName: data?.name,
        patientName: data?.Patient?.name,
        transcript: data.transcripts,
        appointmentTime: data.appointment_datetime,
        id: data.id,
        patient_id: data.patient_id,
        status: data.status,
      });
    }
  };
  useEffect(() => {
    if (id) {
      appointmentAllDetails();
    }
  }, []);

  const dateFormat = (e: any) => {
    if (isToday(new Date(e))) {
      return (
        <p className="text-emerald-900 bold">
          {"Today " + format(new Date(e), "hh:mm a")}
        </p>
      );
    } else if (isTomorrow(new Date(e))) {
      return (
        <p className="text-emerald-900 bold">
          {"Tomorrow " + format(new Date(e), "hh:mm a")}
        </p>
      );
    } else {
      return <DateFormat date={new Date(e)} format="dd MMM yyyy hh:mm a" />;
    }
  };
  const navigateToRec = (id: string) => {
    navigate(PRIVATE_NAVIGATION.patient.recording(id));
  };
  const transcriptView = (id: string) => {
    navigate(PRIVATE_NAVIGATION.transcription.detail.view(id));
  };
  const getActionTippy = (id: any) => {
    return (
      <div className="tippy__dropdown__ul">
        <div className="item">
          <div className="item__link">
            <div className="item__text" onClick={() => transcriptView(id)}>
              View
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <PageLoader pageLoaderClassName="h-[calc(100vh_-_92px)]" />
      ) : (
        <>
          <div className="bg-[#6558F5] text-white font-semibold text-[22x] rounded-[10px] shadow-raiseShadow px-[24px] py-4 mt-[20px]">
            Appointment Information
          </div>
          <div>
            <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] mt-[20px] ">
              <div className="flex justify-between">
                <UserFileView
                  fieldName="Subject Name"
                  fieldValue={
                    appointmentScheduleDetailsData?.subjectName || "-"
                  }
                />
                <div>
                  <Mic
                    onClick={() =>
                      navigateToRec(String(appointmentScheduleDetailsData?.id))
                    }
                  />
                </div>
              </div>
              <UserFileView
                fieldName="Patient Name"
                fieldValue={appointmentScheduleDetailsData?.patientName || ""}
              />
              {isTodayDate(appointmentScheduleDetailsData.appointmentTime) ||
              isTomorrowDate(appointmentScheduleDetailsData.appointmentTime) ? (
                <UserFileView
                  className="text-textGreen"
                  fieldName="Appointment Time "
                  fieldValue={
                    isTodayDate(
                      appointmentScheduleDetailsData.appointmentTime
                    ) ? (
                      `${
                        isTodayDate(
                          appointmentScheduleDetailsData.appointmentTime
                        )
                          ? "Today"
                          : "Tomorrow"
                      }` +
                      " " +
                      format(
                        new Date(
                          appointmentScheduleDetailsData.appointmentTime
                        ),
                        "hh:mm a"
                      )
                    ) : (
                      <DateFormat
                        date={appointmentScheduleDetailsData.appointmentTime}
                        format="dd MMM yyyy, hh:mm a"
                      />
                    )
                  }
                />
              ) : (
                <UserFileView
                  fieldName="Appointment Time "
                  fieldValue={
                    <DateFormat
                      date={appointmentScheduleDetailsData.appointmentTime}
                      format="dd MMM yyyy, hh:mm a"
                    />
                  }
                />
              )}
            </div>
          </div>
          <div className="mt-[10px]">
            <>
              {appointmentScheduleDetailsData.transcript.length ? (
                <div className="recent__files__table__wrapper">
                  <div className="recent__files__table">
                    <div className="recent__files__header">
                      <div className="recent__files__row">
                        <div className="recent__files__cell">Name</div>
                        <div className="recent__files__cell">Uploaded</div>
                        <div className="recent__files__cell">Duration</div>
                        {/* <div className="recent__files__cell">Mode</div> */}
                        <div className="recent__files__cell">Status</div>
                        <div className="recent__files__cell">Action</div>
                      </div>
                    </div>
                    <div className="recent__files__body overflow-y-auto max-h-[200px]">
                      <>
                        {appointmentScheduleDetailsData.transcript.length ? (
                          appointmentScheduleDetailsData.transcript?.map(
                            (item: any) => {
                              return (
                                <div
                                  key={item.id}
                                  className="recent__files__row"
                                >
                                  <div className="recent__files__cell">
                                    {item.name || ""}
                                  </div>
                                  <div className="recent__files__cell">
                                    {dateFormat(item.uploaded)}
                                  </div>
                                  <div className="recent__files__cell">
                                    {item.duration || ""}
                                  </div>
                                  {/* <div className="recent__files__cell">
                              {item.mode || ""}
                            </div> */}
                                  <div className="recent__files__cell checkmark">
                                    {item.status ? <CheckCircle /> : ""}
                                  </div>
                                  <div className="recent__files__cell">
                                    <div className="toggle__btn">
                                      <TippyDropdown
                                        className=""
                                        content={({ close }) =>
                                          getActionTippy(item.id)
                                        }
                                      >
                                        <MoreVertical />
                                      </TippyDropdown>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <div className="">No Data Found</div>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          </div>
        </>
      )}
    </>
  );
};

export default AppointmentScheduleDetail;
