import { Check, Clipboard, Edit3 } from "react-feather";
// ** constants **
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "constants/navigation.constant";
// ** types **
import {
  TranscribePropsType,
  noteType,
  shareTokenType,
  shareUrlsKeysType,
  tabsStateType,
  tabsType,
  transcriptShareType,
} from "./types/transcription.types";
// ** redux **
import { removeToast, setToast } from "redux/slices/toastSlice";
// ** services **
import {
  useDeleteTranscriptAPI,
  useRetryTranscriptAPI,
  useUpdateSharedTranscriptAPI,
  useUpdateTranscriptAPI,
  useUpdateTranscriptUrlAPI,
} from "./services";
// ** packages **
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AlertModal from "components/modal/AlertModal";
import Breadcrumbs, { BREADCRUMB_DATA } from "components/Breadcrumbs";
import Button from "components/Theme/Button";
import CustomPlayer from "./components/CustomPlayer";
import ExportFile from "./components/ExportFile";
// ** components **
import InviteUser from "./components/InviteUser";
import { Modal } from "components/Theme/Modal";
import PatientNote from "./components/PatientNote";
import { REACT_APP_FRONT_URL } from "config";
import { useDispatch } from "react-redux";
import RouteChangeConformationModal from "components/RouteChangeModal/RouteChangeInfoModal";
import TranscribeGrid from "./components/TranscribeGrid";
import VoiceNote from "./components/VoiceNote";
import { convertUTCToLocal } from "utils";
import store from "redux/store";

const generateTokens = (data: transcriptShareType[] | null | undefined) => {
  if (data) {
    const tokens: shareTokenType = {
      public_view: "",
      private_view: "",
      private_view_password: "",
      public_edit: "",
      private_edit: "",
      private_edit_password: "",
    };
    // Iterate through the 'data' array
    for (const item of data) {
      const url = item.shared_url;
      const isUpdatable = item.is_shared_updatable;

      if (item.is_public) {
        if (isUpdatable) {
          tokens.public_edit = url;
        } else {
          tokens.public_view = url;
        }
      } else {
        // Item is private
        if (isUpdatable) {
          tokens.private_edit = url;
          tokens.private_edit_password = item?.password;
        } else {
          tokens.private_view = url;
          tokens.private_view_password = item?.password;
        }
      }
    }

    return tokens;
  }
  return null;
};

const Transcription = (props: TranscribePropsType) => {
  const { data, isAuthor } = props;
  const { state, pathname } = useLocation();
  const currentParams = useParams();
  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPublicOpen, setIsPublicOpen] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<tabsStateType>(
    state?.tab
      ? state?.tab
      : {
        summary:
          data?.is_public ? false : true,
        transcript:  data?.is_public,
        share: false,
        playbook: false,
      }
  );

  const [find, setFind] = useState<string>("");
  const [replace, setReplace] = useState<string>("");
  const [transcript, setTranscript] = useState(data);
  const [history, setHistory] = useState([data]);
  const [historyIndex, setHistoryIndex] = useState<number>(0);
  const [isEditFileName, setIsEditFileName] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>(
    data?.file_name ?? "Untitled"
  );
  const [showInviteUser, setShowInviteUser] = useState(false);
  const sharedUrls: shareTokenType | null = generateTokens(
    transcript?.shared_url
  );
  const [isSideOpen, setIsSideOpen] = useState<boolean>(
    tab?.transcript && !data?.is_public
      ? true
      : false
  );

  const [textToAudioClickChange, setTextToAudioClickChange] = useState();
  const [showTimeStamp, setShowTimeStamp] = useState<boolean>(true);
  const { isLoading, updateTranscriptAPI } = useUpdateTranscriptAPI();
  const { deleteTranscriptAPI, isLoading: isDeleteLoading } =
    useDeleteTranscriptAPI();
  // const updateTranscriptUrlAPI = useUpdateTranscriptUrlAPI();
  const updateSharedTranscriptAPI = useUpdateSharedTranscriptAPI();
  const [isLinkEditable, setIsLinkEditable] = useState<boolean>(false);
  const navigate = useNavigate();
  const { retryTranscriptAPI } = useRetryTranscriptAPI();
  const shareLinkRef = useRef<HTMLInputElement | null>(null);
  const sharePasswordRef = useRef<HTMLInputElement | null>(null);

  const onRetryTranscript = async () => {
    if (transcript?.status === "Failed") {
      const { data, error } = await retryTranscriptAPI(transcript.id);
      if (!error && data) {
        setTranscript({ ...transcript, status: data?.status ?? "Pending" });
      }
    }
  };
  const ref = useRef<any>({});

  const onClose = () => {
    setOpen(false);
  };

  const onDeleteSave = async () => {
    if (transcript) {
      const { error } = await deleteTranscriptAPI(transcript?.id);
      if (!error) {
        setOpen(false);
        if(BREADCRUMB_DATA && BREADCRUMB_DATA[BREADCRUMB_DATA.length - 1].path){
          const last_path = BREADCRUMB_DATA[BREADCRUMB_DATA.length - 1]
          navigate(last_path?.path, { state: last_path?.state })
        }
        else{
          navigate(PRIVATE_NAVIGATION.dashboard.view)
        }
      }
    }
  };


  const get_share_ui = (sharedUrl: shareUrlsKeysType) => {
    return (
      <>
        {sharedUrls && (
          <div className="relative" key={sharedUrl}>
            <input
              readOnly={true}
              type="text"
              ref={(ref.current[sharedUrl] ??= { current: null })}
              className="py-3 pl-4 pr-8 block w-full border border-solid border-[#e5e5eb] rounded-lg text-sm"
              value={
                sharedUrls[sharedUrl as shareUrlsKeysType]
                  ? REACT_APP_FRONT_URL +
                  "/shared/" +
                  sharedUrls[sharedUrl as shareUrlsKeysType]
                  : "Click Generate for url"
              }
            />
            {sharedUrls[sharedUrl as shareUrlsKeysType] && (
              <div
                className="group cursor-pointer flex absolute top-0 right-0 w-fit gap-1 active:bg-black active:text-white py-[13px] px-[7px] rounded-lg"
                onClick={() => {
                  copySharedLink(
                    REACT_APP_FRONT_URL +
                    "/shared/" +
                    sharedUrls[sharedUrl as shareUrlsKeysType]
                  );
                  ref.current[sharedUrl].current.select();
                }}
              >
                <Clipboard className="w-5 h-5 " />
                <span className="group-hover:opacity-100 whitespace-nowrap absolute text-[10px] text-white bg-black -top-4 -left-4 px-1.5 py-1 rounded opacity-0 transition-all duration-500">
                  Copy Link
                </span>
              </div>
            )}
            {
              !isPublicOpen && (
                <div className="grid grid-cols-2 justify-evenly items-center mt-5 mx-1">
                  <label className="mr-2 text-[15px] select-none">
                    Password For Access
                  </label>
                  <input
                    readOnly={true}
                    type="text"
                    className="py-3 pl-4 pr-8 border border-solid border-[#e5e5eb] rounded-lg text-sm"
                    value={(sharedUrl === "private_view") ? sharedUrls["private_view_password"] : sharedUrls["private_edit_password"]}
                  />
                
                <div
                    className="group absolute right-3 cursor-pointer"
                    onClick={()=>{copyPassword((sharedUrl === "private_view") ? sharedUrls["private_view_password"] : sharedUrls["private_edit_password"])}}
                  >
                    <Clipboard className="w-5 h-5 " />
                    <span className="group-hover:opacity-100 whitespace-nowrap absolute text-[10px] text-white bg-black -top-4 -left-4 px-1.5 py-1 rounded opacity-0 transition-all duration-500">
                      Copy Password
                    </span>
                </div>
                </div>
              )
            }
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (history?.length > 1) {
      const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
        const confirmationMessage =
          "The changes you have made might in Transcript not be saved, do you want to Discard ?";
        (event || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      };

      window.addEventListener("beforeunload", beforeUnloadHandler);

      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [history]);

  const replaceText = () => {
    if (transcript) {
      const filteredData = transcript.transcript.filter((item) =>
        item.text.includes(find.trim())
      );
      if (filteredData.length) {
        if (find.trim() && replace.trim()) {
          const updatedTranscript = {
            ...transcript,
            transcript: transcript.transcript.map((item) => ({
              ...item,
              text: item.text.replaceAll(find.trim(), replace.trim()),
            })),
          };
          setHistory((prevHistory) => [
            ...prevHistory.slice(0, historyIndex + 1),
            updatedTranscript,
          ]);
          const toastId = new Date().getTime();
          dispatch(
            setToast({
              message: "Text Replaced successfully",
              type: "success",

              id: toastId,
            })
          );
          setTimeout(() => {
            dispatch(removeToast({ id: toastId }));
          }, 2000);
          setTranscript(updatedTranscript);
          setHistoryIndex(historyIndex + 1);
          setFind("");
          setReplace("");
        }
      } else {
        const toastId = new Date().getTime();
        dispatch(
          setToast({
            message: "Text Not Found",
            type: "error",
            id: toastId,
          })
        );
        setTimeout(() => {
          dispatch(removeToast({ id: toastId }));
        }, 2000);
      }
    }
  };

  const changeFileName = async () => {
    if (transcript?.file_name !== fileName && transcript) {
      const payload = {
        file_name: fileName,
      };
      if (isAuthor) {
        const { error } = await updateTranscriptAPI(transcript.id, payload);
        if (!error) {
          setTranscript({ ...transcript, file_name: fileName });
        } else {
          setFileName(transcript?.file_name);
        }
      } else {
        const params = `sharing=${currentParams?.id}`;
        const { error } =
          await updateSharedTranscriptAPI.updateSharedTranscriptAPI(
            params,
            payload
          );
        if (!error) {
          setTranscript({ ...transcript, file_name: fileName });
        } else {
          setFileName(transcript?.file_name);
        }
      }
    }
    setIsEditFileName(false);
  };

  const undo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setTranscript(history[historyIndex - 1]);
    }
  };

  const redo = () => {
    if (historyIndex < history?.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setTranscript(history[historyIndex + 1]);
    }
  };

  const onSave = async () => {
    if (transcript) {
      if (isAuthor) {
        const { error } = await updateTranscriptAPI(transcript.id, transcript);
        if (!error) {
          setHistoryIndex(0);
          setFind("");
          setReplace("");
          setHistory([transcript]);
        }
      } else {
        const params = `sharing=${currentParams?.id}`;
        const { error } =
          await updateSharedTranscriptAPI.updateSharedTranscriptAPI(
            params,
            transcript
          );
        if (!error) {
          setHistoryIndex(0);
          setFind("");
          setReplace("");
          setHistory([transcript]);
        }
      }
    }
  };

  const updateSpeaker = (index: number, newSpeaker: string) => {
    if (transcript) {
      const currentSpeaker = transcript?.transcript[index]?.speaker;
      const uniqueSpeakers = [
        ...new Set(
          transcript?.transcript.map((patient) =>
            patient?.speaker?.toLowerCase()
          )
        ),
      ];
      const indexOfCurrentSpeaker = uniqueSpeakers.indexOf(
        currentSpeaker?.toLowerCase()
      );
      uniqueSpeakers.splice(indexOfCurrentSpeaker, 1);

      if (currentSpeaker !== newSpeaker && newSpeaker?.trim()?.length) {
        if (uniqueSpeakers.includes(newSpeaker?.toLowerCase())) {
          const toastId = new Date().getTime();
          dispatch(
            setToast({
              message: "The speakers cannot have the same names.",
              type: "error",
              id: toastId,
            })
          );
          setTimeout(() => {
            dispatch(removeToast({ id: toastId }));
          }, 2000);
          return null;
        }
        const regex = new RegExp(`\\b${currentSpeaker}\\b`, "gi");
        const updatedTranscript = {
          ...transcript,
          transcript: transcript.transcript.map((item) => ({
            ...item,
            speaker: item.speaker.replace(regex, newSpeaker.trim()),
          })),
        };

        setHistory((prevHistory) => [
          ...prevHistory.slice(0, historyIndex + 1),
          updatedTranscript,
        ]);
        setTranscript(updatedTranscript);
        setHistoryIndex(historyIndex + 1);
      }
    }
  };

  const updateTranscriptText = (index: number, newText: string) => {
    if (transcript) {
      const currentText = transcript?.transcript[index]?.text;
      if (currentText !== newText) {
        const updatedTranscript = {
          ...transcript,
          transcript: transcript?.transcript.map((item, i) => ({
            ...item,
            text: index === i ? newText : item?.text,
          })),
        };

        setHistory((prevHistory) => [
          ...prevHistory.slice(0, historyIndex + 1),
          updatedTranscript,
        ]);
        setTranscript(updatedTranscript);
        setHistoryIndex(historyIndex + 1);
      }
    }
  };

  // const updateSharedUrl = async () => {
  //   const payload = {
  //     is_updatable: !isLinkEditable,
  //   };
  //   const { data, error } = await updateTranscriptUrlAPI.updateTranscriptUrlAPI(
  //     transcript.id,
  //     payload
  //   );
  //   if (!error && data) {
  //     const shareableUrl =
  //       REACT_APP_FRONT_URL +
  //       PUBLIC_NAVIGATION.sharedTranscription(data?.token);
  //     setSharedUrls(shareableUrl);
  //   }
  // };

  // const generateLinkEditing = () => {
  //   if (sharedUrls) {
  //     updateSharedUrl();
  //   }
  // };

  const copyPassword = (value: string) => {
    navigator.clipboard.writeText(value);
      const toastId = new Date().getTime();
      dispatch(
        setToast({
          message: "Password Copied",
          type: "success",

          id: toastId,
        })
      );
      if (sharePasswordRef) {
        sharePasswordRef.current?.select();
      }
      setTimeout(() => {
        dispatch(removeToast({ id: toastId }));
      }, 2000);
  }
  const copySharedLink = (value: string) => {
    if (sharedUrls) {
      navigator.clipboard.writeText(value);
      const toastId = new Date().getTime();
      dispatch(
        setToast({
          message: "Shared link Copied",
          type: "success",

          id: toastId,
        })
      );
      if (shareLinkRef) {
        shareLinkRef.current?.select();
      }
      setTimeout(() => {
        dispatch(removeToast({ id: toastId }));
      }, 2000);
    }
  };
  const closeShowInviteUser = () => {
    setShowInviteUser(false);
  };
  const fileEdit = () => {
    return (
      <>
        {transcript?.is_editable || isAuthor ? (
          <div className="max-w-[20vw] lg:max-w-[30vw] md:max-w-[50vw] sm:max-w-none right-0 editname">
            {isEditFileName ? (
              <div className="flex items-center mr-2 min-w-[calc(100%_-_115px)] relative">
                <input
                  className="input__DSD py-3 text-xs pr-7"
                  type="text"
                  placeholder="Enter File Name"
                  id="fileName"
                  autoComplete="off"
                  value={fileName}
                  defaultValue={fileName}
                  onChange={(e) => {
                    if (e.target.value.length <= 40) {
                      setFileName(e.target.value)
                    }
                    else {
                      store.dispatch(
                        removeToast({id:470000000})
                      )
                      store.dispatch(
                        setToast({
                          message: "Filename shouldn't exceed 40 characters",
                          type: "error",
                          id: 470000000,
                        })
                      );
                      setTimeout(() => {
                        store.dispatch(
                          removeToast({id:470000000})
                        )
                      }, 2000);
                    }
                  }
                  }
                  onKeyDown={(e) => {
                    e?.key === "Enter" && changeFileName();
                  }}
                  disabled={isLoading}
                />
                {(transcript?.is_editable || isAuthor) && (
                  <Check
                    className="cursor-pointer absolute right-2 w-4 h-4"
                    onClick={changeFileName}
                  />
                )}
              </div>
            ) : (
              <div className="flex items-center mr-2">
                <div className="py-3 text-sm break-all font-medium">
                  {fileName}
                </div>
                {(transcript?.is_editable || isAuthor) && (
                  <Edit3
                    className="mx-2 w-4 h-4 cursor-pointer"
                    onClick={() => setIsEditFileName(true)}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="right-0 editname">
            <div className="py-3 text-sm break-all font-medium">{fileName}</div>
          </div>
        )}
      </>
    );
  };

  const titleForBreadCrumb = {
    voice: "Voice Notes",
    content: "Content Notes",
    meeting: "Meeting Notes",
  };

  const patientTypeBreadCrumb = {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      {
        title: "Patient",
        path: PRIVATE_NAVIGATION.patient.view,
      },
      {
        title: transcript?.Patient?.name ?? "Patient Name",
        path: PRIVATE_NAVIGATION.patient.detail.view(
          String(transcript?.Patient?.id)
        ),
      },
    ],
    title: fileName ?? "transcription",
  };

  const otherTypeBreadCrumb = {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      {
        title: transcript?.note_type
          ? titleForBreadCrumb[transcript?.note_type as noteType] ?? ""
          : "",
        path: PRIVATE_NAVIGATION.notes.view,
        state: {
          note_type: transcript?.note_type ?? "patient",
          navHighlighter: transcript?.note_type ?? "patient",
        },
      },
    ],
    title: fileName ?? "transcription",
  };

  const voiceTabs: tabsType[] = [
    { name: "Transcription", value: "transcription", disabled: false },
    {
      name: isAuthor ? "Export & Share" : "Export",
      value: "share",
      disabled: false,
    },
  ];

  if (isAuthor) {
    voiceTabs.push({
      name: "PlayBook",
      value: "playbook",
      disabled: false,
    });
  }

  const onSideBarClose = () => {
    setIsSideOpen(false);
    setTab((prevState) => ({
      ...prevState,
      summary: !prevState?.playbook,
      transcript: data?.note_type === "voice" || data?.is_public ? true : false,
      share: false,
    }));
  };

  const onDiscard = () =>{
    setTranscript(data)
    setHistory([data])
    setHistoryIndex(0)
  }

  const shareTab = () => {
    return (
      <div>
        <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] pb-[16px]">
          {transcript && (
            <ExportFile
              setOpen={setOpen}
              transcript={transcript}
              setShowTimeStamp={setShowTimeStamp}
              onSideBarClose={onSideBarClose}
              showTimeStamp={showTimeStamp}
              isAuthor={isAuthor}
              is_shared={pathname.slice(1, 7) === "shared"}
              shared_id={currentParams?.id}
            />
          )}
        </div>
        {isAuthor && (
          <div className="mt-6 bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] pb-[16px]">
            <div className="flex flex-wrap justify-between items-center pb-5">
              <h5 className="text-lg font-semibold sm:mb-3">Share Recording</h5>
              <Button
                className="input__DSD w-fit py-[10px] px-5 ml-auto bg-[#6558F5] text-white shadow-none cursor-pointer"
                onClick={() => {
                  setShowInviteUser(!showInviteUser);
                  onSideBarClose();
                }}
              >
                Share With User
              </Button>
            </div>
            <span className="text-sm mb-2 block">
              Anyone with this link can download file{" "}
            </span>
            <div className="inline-flex rounded-lg shadow-sm w-full">
              <span
                className={`py-2 px-4 w-full cursor-pointer rounded-xl text-center select-none rounded-r-none border-2 border-r-0 ${isPublicOpen ? "bg-indigo-700 text-white" : " text-indigo-700"
                  }`}
                onClick={(e) => setIsPublicOpen(true)}
              >
                Public Link
              </span>
              <span
                className={`py-2 px-4 w-full cursor-pointer rounded-xl text-center select-none rounded-l-none border-2 border-l-0 ${!isPublicOpen
                  ? "bg-indigo-700 text-white"
                  : "bg-none text-indigo-700"
                  }`}
                onClick={(e) => setIsPublicOpen(false)}
              >
                Private Link
              </span>
            </div>
            <div>
              {isPublicOpen ? (
                <>
                  {/* <h6 className="text-md font-semibold mt-4">Public Links</h6> */}
                  <div className="mb-3 mt-3">
                    {get_share_ui(
                      !isLinkEditable ? "public_view" : "public_edit"
                    )}
                  </div>
                </>
              ) : (
                <>
                  {/* <h6 className="text-md font-semibold mt-4">Private Links</h6> */}
                  <div className="mb-3 mt-3">
                    {get_share_ui(
                      !isLinkEditable ? "private_view" : "private_edit"
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="flex justify-between items-center mt-5 mx-1">
              <label className="mr-2 text-[15px] select-none">
                Allow Editing of your transcription
              </label>
              <label className="switch" htmlFor="switchcheckbox">
                <input
                  type="checkbox"
                  id="switchcheckbox"
                  checked={!isLinkEditable}
                  // onClick={() => generateLinkEditing()}
                  onChange={(e) => setIsLinkEditable(!e.target.checked)}
                />
                <div className="slider"></div>
              </label>
            </div>
            {/* <Button
                type="button"
                className="bg-black text-white group mt-5"
                loading={updateTranscriptUrlAPI.isLoading}
                onClick={updateSharedUrl}
              >
                <div className="flex w-fit gap-1">
                  <Link className="w-5 h-5" />
                  Generate
                </div>
              </Button> */}
          </div>
        )}
        {!(historyIndex === 0) && (
          <RouteChangeConformationModal
            isDirtyCondition={!(historyIndex === 0)}
            promptMessage="The changes made in Transcript, will not be saved."
          />
        )}
      </div>
    );
  };

  const transcriptTab = (
    transcriptionBodyClass: string = "overflow-y-auto 2xl:max-h-[50vh] 3xl:max-h-[65vh]"
  ) => {
    return (
      <div className="w-full px-[15px] xl:mt-6 sm:px-2">
        {transcript && (
          <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] sm:p-3 pb-[16px] ">
            <div>
              <div className="flex flex-wrap -mx-[15px] border-b border-black/10">
                {(transcript?.is_editable || isAuthor) && (
                  <div className="px-[15px] w-[40%] lg:w-full">
                    <div className="flex flex-col -mx-[15px] gap-2 my-5">
                      <div className="flex xs:flex-col gap-2 px-[15px]">
                        <input
                          className="input__DSD"
                          type="text"
                          placeholder="Find"
                          id="find"
                          value={find || ""}
                          onChange={(e) => setFind(e.target.value)}
                          autoComplete="off"
                        />
                        <input
                          className="input__DSD"
                          type="text"
                          placeholder="Replace"
                          id="replace"
                          value={replace}
                          autoComplete="off"
                          onChange={(e) => setReplace(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-wrap px-[15px]">
                        <Button
                          className="button__DSD text-[#4c60e9] bg-white rounded-full border border-[#4c60e9] h-10 mb-2 mr-3"
                          type="button"
                          onClick={replaceText}
                        >
                          <div className="btn__text">Replace</div>
                        </Button>
                        <Button
                          loading={
                            isLoading || updateSharedTranscriptAPI.isLoading
                          }
                          className="button__DSD cursor-pointer bg-[#4c60e9] text-white rounded-full border border-[#4c60e9] h-10"
                          onClick={onSave}
                          disabled={historyIndex === 0}
                        >
                          Save all
                        </Button>
                      </div>
                    </div>
                    {/* <div className="flex items-center gap-1 mb-[8px]">
                    <input
                      id="showTimeStamp"
                      className="custom-check !ml-[2px]"
                      type="checkbox"
                      checked={showTimeStamp}
                      onChange={(e) => setShowTimeStamp(e.target?.checked)}
                    />
                    <label htmlFor="showTimeStamp" className="cursor-pointer">
                      Show timestamps
                    </label>
                  </div> */}
                  </div>
                )}
                {/* <div className="inline"> */}

                <CustomPlayer
                  historyOfTranscriptReplace={history}
                  historyIndex={historyIndex}
                  redo={redo}
                  undo={undo}
                  textToAudioClickChange={textToAudioClickChange}
                  currentTime={currentTime}
                  setCurrentTime={setCurrentTime}
                  media_url={transcript.media}
                />
                {/* </div> */}
              </div>

              <TranscribeGrid
                setTextToAudioClickChange={setTextToAudioClickChange}
                res={transcript.transcript}
                transcriptStatus={transcript.status}
                transcriptAllData={transcript}
                currentTime={currentTime}
                onUpdateSpeaker={updateSpeaker}
                isEditable={transcript.is_editable || isAuthor}
                onTranscriptTextUpdate={updateTranscriptText}
                onRetryTranscript={onRetryTranscript}
                transcriptionBodyClass={transcriptionBodyClass}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`${pathname.slice(1, 7) === "shared" ? "p-[15px]" : ""}`}>
      {showInviteUser && transcript && (
        <Modal
          modalWidth="800px"
          modalClass="max-h-[70vh]"
          modalBodyClass="!max-h-[calc(100dvh_-_255px)]"
          onClose={closeShowInviteUser}
          headerName="Share with individual user"
        >
          <InviteUser
            transcript_id={transcript?.id}
            closeShowInviteUser={closeShowInviteUser}
          />
        </Modal>
      )}
      {isAuthor && (
        <div>
          <Breadcrumbs
            path={
              transcript?.note_type === "patient"
                ? patientTypeBreadCrumb
                : otherTypeBreadCrumb
            }
          />
        </div>
      )}
      <>
        <AlertModal
          visible={open}
          onClose={onClose}
          onCancel={onClose}
          onSubmit={onDeleteSave}
          submitButtonText="Yes"
        >
          <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
            Are you sure you want to delete Recording ?
          </p>
        </AlertModal>
        {(transcript?.note_type === "patient" ||
          transcript?.note_type === "content" ||
          transcript?.note_type === "voice" ||
          transcript?.note_type === "meeting") && (
            <PatientNote
              onSave={onSave}
              history={history}
              onDiscard={onDiscard}
              fileEdit={fileEdit}
              setTab={setTab}
              shareTab={shareTab}
              tab={tab}
              transcript={transcript}
              transcriptTab={transcriptTab}
              isSideOpen={isSideOpen}
              setIsSideOpen={setIsSideOpen}
              is_shared={pathname.slice(1, 7) === "shared"}
            />
          )}
        {/* {transcript?.note_type === "voice" && (
          <VoiceNote
            fileEdit={fileEdit}
            setTab={setTab}
            shareTab={shareTab}
            tab={tab}
            transcript={transcript}
            transcriptTab={transcriptTab}
            isSideOpen={isSideOpen}
            setIsSideOpen={setIsSideOpen}
            is_shared={pathname.slice(1, 7) === "shared"}
          />
        )} */}
      </>
    </div>
  );
};
export default Transcription;
