import { Fragment, useState, useEffect, useRef } from "react";
import { Check, Edit3 } from "react-feather";
import { TranscriptArrayType } from "../types/transcription.types";
import { removeToast, setToast } from "redux/slices/toastSlice";
import { useDispatch } from "react-redux";

type TranscribeGridPropsType = {
  res: Array<TranscriptArrayType>;
  currentTime: number;
  onUpdateSpeaker: (index: number, newSpeaker: string) => void;
  isEditable: boolean;
  transcriptAllData: any;
  transcriptStatus?: string;
  setTextToAudioClickChange: React.Dispatch<React.SetStateAction<any>>;
  onTranscriptTextUpdate: (index: number, newText: string) => void;
  onRetryTranscript: () => void;
  transcriptionBodyClass?: string;
};

const TranscribeGrid = (props: TranscribeGridPropsType) => {
  const {
    transcriptStatus,
    res,
    transcriptAllData,
    currentTime,
    onUpdateSpeaker,
    isEditable,
    setTextToAudioClickChange,
    onTranscriptTextUpdate,
    onRetryTranscript,
    transcriptionBodyClass = "overflow-y-auto 2xl:max-h-[40vh] 3xl:max-h-[65vh]",
  } = props;
  const [editableSpeakerIndex, setEditableSpeakerIndex] = useState<
    number | null
  >(null);
  const [editedSpeakerName, setEditedSpeakerName] = useState<string>("");
  const [editableTextIndex, setEditableTextIndex] = useState<number | null>(
    null
  );
  const [showTimeStamp, setShowTimeStamp] = useState<boolean>(true);
  const [editedText, setEditedText] = useState<string>("");
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const currentLineIndex = res.findIndex((line, index) => {
    const currentLine = new Date(
      `1970-01-01 ${res[index].start}.000Z`
    ).getTime();
    let nextLineTime: number;
    if (res[index + 1]) {
      nextLineTime = new Date(
        `1970-01-01 ${res[index + 1].start}.000Z`
      ).getTime();
    } else {
      nextLineTime = Number.MAX_VALUE;
    }

    return currentLine <= currentTime && currentTime < nextLineTime;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    // Scroll to the element with the ref when currentLineIndex changes
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [currentLineIndex]);

  const handleSpeakerClick = (index: number, currentSpeakerName: string) => {
    setEditedSpeakerName(currentSpeakerName);
    setEditableSpeakerIndex(index);
  };

  const handleSpeakerChange = (index: number) => {
    setEditableSpeakerIndex(null);
    onUpdateSpeaker(index, editedSpeakerName);
  };

  const handleTextClick = (index: number, currentText: string) => {
    setEditedText(currentText);
    setEditableTextIndex(index);
  };

  const handleTextChange = (index: number) => {
    setEditableTextIndex(null);
    onTranscriptTextUpdate(index, editedText);
  };
  const handleClickTextToSpeak = (e: any) => {
    setTextToAudioClickChange(e);
  };
  const formatTranscript = (transcript: Array<TranscriptArrayType>) => {
    let formattedString = "";
    transcript.forEach(function (item) {
      const speaker = item.speaker;
      const startTime = item.start;
      const endTime = item.end;
      const text = item.text;
      formattedString +=
        speaker + " (" + startTime + " : " + endTime + ") : " + text + "\n\n";
    });
    return formattedString;
  };

  const copySummaryData = () => {
    const doc = new DOMParser().parseFromString(
      formatTranscript(res),
      "text/html"
    );
    navigator.clipboard.writeText(doc.body?.textContent ?? "");
    const toastId = new Date().getTime();
    dispatch(
      setToast({
        message: "Summary Copied",
        type: "success",
        id: toastId,
      })
    );
    setTimeout(() => {
      dispatch(removeToast({ id: toastId }));
    }, 2000);
  };

  return (
    <>
      {transcriptStatus === "Completed" ? (
        res.length > 0 ? 
        <>
          <div className="flex sm:flex-wrap justify-between mt-4">
            <div className="flex items-center gap-1 mb-[8px]">
              <input
                id="showTimeStamp"
                className="custom-check !ml-[2px]"
                type="checkbox"
                checked={showTimeStamp}
                onChange={(e) => setShowTimeStamp(e.target?.checked)}
              />
              <label
                htmlFor="showTimeStamp"
                className="cursor-pointer select-none"
              >
                Show timestamps
              </label>
            </div>
            <div className="flex justify-end my-5">
              <p
                className="cursor-pointer select-none border-solid border-[2px] rounded-full px-3 py-1 text-[14px] border-[#5855d6] hover:text-white hover:bg-[#5855d6] transition-all"
                onClick={() => copySummaryData()}
              >
                Copy Transcript
              </p>
            </div>
          </div>
          <div className={transcriptionBodyClass}>
            <div className="flex md:flex-col flex-wrap transcrip">
              {res.map((data, index) => (
                <Fragment key={index}>
                  <div className="md:w-full w-[20%] mb-4 md:mb-2">
                    <div
                      ref={(ref) => {
                        if (index === currentLineIndex) {
                          scrollRef.current = ref;
                        }
                      }}
                      className={
                        index === currentLineIndex
                          ? "text-cyan-700 font-semibold cursor-pointer flex flex-col"
                          : "text-black cursor-pointer flex flex-col"
                      }
                    >
                      {editableSpeakerIndex === index ? (
                        <div className="relative">
                          <input
                            type="text"
                            className="input__DSD pr-7"
                            value={editedSpeakerName}
                            onChange={(e) =>
                              setEditedSpeakerName(e.target.value)
                            }
                            onKeyDown={(e) => {
                              e?.key === "Enter" && handleSpeakerChange(index);
                            }}
                          />
                          <Check
                            className="absolute right-2 top-1/2 !w-4 !h-4 -translate-y-1/2"
                            onClick={() => handleSpeakerChange(index)}
                          />
                        </div>
                      ) : (
                        <div className="flex px-[12px] gap-2 mt-2">
                          <span className="break-all"> {data?.speaker}</span>
                          {isEditable && (
                            <Edit3
                              className="!w-4 !h-4 min-w-[16px] mt-[5px]"
                              onClick={() =>
                                handleSpeakerClick(index, data?.speaker)
                              }
                            />
                          )}
                        </div>
                      )}
                      {showTimeStamp && (
                        <small className="px-[12px] mt-1">
                          {data?.start}-{data?.end}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="md:w-full w-[70%] mb-4 md:mb-2 pl-4 md:pl-3 sm:pl-0">
                    {editableTextIndex === index ? (
                      <div className="relative">
                        <textarea
                          className="input__DSD pr-7 h-fit"
                          rows={4}
                          cols={50}
                          value={editedText}
                          onChange={(e) => setEditedText(e.target.value)}
                        />
                        <Check
                          className="absolute right-4 top-1/2 !w-4 !h-4 -translate-y-1/2"
                          onClick={() => handleTextChange(index)}
                        />
                      </div>
                    ) : (
                      <div className="flex px-[12px] gap-2 mt-2 select-none">
                        {isEditable && (
                          <Edit3
                            className="!w-4 !h-4 min-w-[16px] mt-[12px] self-start mr-4"
                            onClick={() => handleTextClick(index, data?.text)}
                          />
                        )}
                        <pre
                          className={
                            index === currentLineIndex
                              ? "text-cyan-700 font-semibold mt-2 sm:text-sm font-Poppins text-wrap"
                              : "text-black mt-2 font-Poppins text-wrap"
                          }
                          onClick={() => handleClickTextToSpeak(data?.start)}
                        >
                          {data?.text}
                        </pre>
                      </div>
                    )}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </>
      : <div className="text-center p-6">
          <h2>There is no conversation to generate a transcript.</h2>
        </div>
      ) : (
        <>
          <div className="text-center my-[10px]">
            {transcriptStatus === "Pending" &&
              "Your transcription in Pending, wait a while..."}
            {transcriptStatus === "In process" &&
              "Your transcription in process, wait a while..."}
            {(transcriptStatus === "Failed" ||
              transcriptStatus === "Rejected") &&
              "Your transcription is Failed"}
          </div>
          {(transcriptStatus === "Failed" || transcriptStatus === "Rejected") &&
            isEditable && (
              <div
                className="text-center text-red-500 button cursor-pointer sm:mt-2"
                onClick={() => {
                  onRetryTranscript();
                }}
              >
                <span className="bg-red-200 py-1 px-3 border-red-500">Retry</span>
              </div>
            )}
        </>
      )}
    </>
  );
};

export default TranscribeGrid;
