// ** packages **
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { firstCharUpperCase, formatTimeAgo } from "../../../utils/index";
import ImageShow from "components/Image";
import { Mic } from "react-feather";
// ** helper **

const usePatientColumn = () => {
  const navigate = useNavigate();
  const navigateToPatient = (id: number | string) => {
    navigate(`${PRIVATE_NAVIGATION.patient.detail.view(String(id))}`);
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);


  const columnDefs = useMemo(
    () => [
      {
        name: "Name",
        selector: (row: { name: string }) => row.name,
        sortable: true,
        sortField: "name",
        // width: "40%",
        cell: (row: {
          name: string;
          email: string;
          profile: string;
          id: number | string;
        }) => (
          <TableCellRender
            cellRowData={
              <>
                <div
                  className="container mx-auto px-4 py-8 cursor-pointer"
                  onClick={() => navigateToPatient(row.id)}
                >
                  <div className="flex gap-2 items-center">
                    <div className="flex-shrink-0">
                      <ImageShow
                        avatarWrapperClassName="h-[50px] w-[50px] rounded-full"
                        imgClassName="rounded-full z-2"
                        avatarInnerWrapperClassName="!rounded-full"
                        imgPath={row.profile}
                        first_name={row.name}
                        last_name={row.name}
                        NoNameLetterWrapperClass="!z-0"
                      />
                    </div>
                    <div className="ml-4 lg:ml-0 lg:mt-1">
                      <div className="font-medium text-gray-900">
                        {row.name}
                      </div>
                      <div className="mt-1 text-gray-500">{row.email}</div>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        ),
      },
      (windowSize > 1100) && {
        name: "Last Modified By",
        selector: (row: { created: { first_name: string } }) =>
          row?.created.first_name,
        sortable: false,
        cell: (row: {
          created: {
            first_name: string;
            last_name: string;
            created_at: string;
          };
          updated: {
            first_name: string;
            last_name: string;
          };
          created_at: string;
          updated_at: string;
        }) => (
          <TableCellRender
            cellRowData={
              <div className="flex items-center">
                <div>
                  <div className="font-medium text-gray-900">
                    {firstCharUpperCase(
                      row?.updated
                        ? row?.updated.first_name
                        : row?.created.first_name
                    ) +
                      " " +
                      firstCharUpperCase(
                        row?.updated
                          ? row?.updated.last_name
                          : row?.created.last_name
                      )}
                  </div>
                  <div className="mt-1 text-gray-500">
                    {formatTimeAgo(
                      row?.updated ? row?.updated_at : row?.created_at
                    )}
                    {/* {Format(new Date(row?.created?.created_at), "MM/dd/yyyy")} */}
                  </div>
                </div>
              </div>
            }
          />
        ),
      },
      (windowSize > 1100) && {
        name: "Action",
        selector: (row: { id: string | number }) => row.id,
        sortable: false,
        width: "15%",
        cell: (row: { id: string | number }) => {
          const PatientAdd: {
            label: string;
            onClick: () => void;
          }[] = [];
          PatientAdd.push({
            label: "View",
            onClick: () => {
              navigate(
                `${PRIVATE_NAVIGATION.patient.detail.view(String(row.id))}`
              );
            },
          });
          return (
            <div className="flex lg:flex-wrap gap-4">
              {/* <TippyDropdown
                content={({ close }) =>
                  renderOptions({ close, filedArray: PatientAdd })
                }
              >
                <MoreVertical />

</TippyDropdown> */}
              <span
                onClick={() => navigateToPatient(row.id)}
                className="inline-flex whitespace-nowrap items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 cursor-pointer"
              >
                View
              </span>
              <Mic
                onClick={() =>
                  navigate(PRIVATE_NAVIGATION.patient.recording(String(row.id)))
                }
                className="microphone w-5 h-5 cursor-pointer"
              />
            </div>
          );
        },
      },
    ],
    [windowSize]
  );
  return columnDefs;
};

export default usePatientColumn;
