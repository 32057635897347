import { IconInputProps } from ".";

export const FilledOneManIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.0621 23.7813C24.9798 23.9238 24.8614 24.0422 24.7189 24.1245C24.5763 24.2068 24.4146 24.2501 24.25 24.25H1.75C1.58552 24.2498 1.42397 24.2064 1.28159 24.1241C1.1392 24.0417 1.02099 23.9233 0.938822 23.7808C0.856653 23.6384 0.813419 23.4768 0.813461 23.3123C0.813504 23.1478 0.856821 22.9862 0.939063 22.8438C2.72383 19.7582 5.47422 17.5457 8.68398 16.4969C7.09629 15.5517 5.86275 14.1115 5.1728 12.3974C4.48285 10.6833 4.37463 8.79017 4.86477 7.00864C5.35492 5.2271 6.41631 3.65571 7.88597 2.53579C9.35562 1.41586 11.1523 0.809326 13 0.809326C14.8477 0.809326 16.6444 1.41586 18.114 2.53579C19.5837 3.65571 20.6451 5.2271 21.1352 7.00864C21.6254 8.79017 21.5172 10.6833 20.8272 12.3974C20.1373 14.1115 18.9037 15.5517 17.316 16.4969C20.5258 17.5457 23.2762 19.7582 25.0609 22.8438C25.1434 22.9862 25.1869 23.1478 25.1871 23.3124C25.1873 23.4769 25.1442 23.6386 25.0621 23.7813Z"
        fill="#4b5c6b"
      />
    </svg>
  );
};
