// ** packages **
import * as yup from "yup";

// ** constants **
import { CreateCustomPromptSchemaError } from "constants/formErrorMessage.constant";

export const createCustomPromptSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, CreateCustomPromptSchemaError.title_min)
    .required(CreateCustomPromptSchemaError.title)
    .max(50, CreateCustomPromptSchemaError.title_max),

  descriptions: yup
    .string()
    .trim()
    .min(3, CreateCustomPromptSchemaError.description_min)
    .required(CreateCustomPromptSchemaError.description)
    .max(250, CreateCustomPromptSchemaError.description_max),

  prompt: yup.string().trim().required(CreateCustomPromptSchemaError.prompt),
});

export const createCustomPromptWithTypeSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, CreateCustomPromptSchemaError.title_min)
    .required(CreateCustomPromptSchemaError.title)
    .max(50, CreateCustomPromptSchemaError.title_max),

  descriptions: yup
    .string()
    .trim()
    .min(3, CreateCustomPromptSchemaError.description_min)
    .required(CreateCustomPromptSchemaError.description)
    .max(250, CreateCustomPromptSchemaError.description_max),

  prompt: yup.string().trim().required(CreateCustomPromptSchemaError.prompt),
  types: yup
    .object()
    .shape({
      value: yup.string().required(CreateCustomPromptSchemaError.prompt_type),
      label: yup.string().required(CreateCustomPromptSchemaError.prompt_type),
    })
    .required(CreateCustomPromptSchemaError.prompt_type).typeError(CreateCustomPromptSchemaError.prompt_type),
});
