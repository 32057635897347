export const allowed_mimetype: string[] = [
  "audio/flac",
  "audio/mpeg",
  "audio/mp3",
  "audio/wav",
  "audio/wave",
  "audio/webm",
  "audio/AMR",
  "audio/m4a",
  "video/mp4",
  "video/webm",
  "audio/x-m4a",
  "audio/ogg",
];
