import { IconInputProps } from ".";

export const AINotesIcon = ({ className }: IconInputProps) => {
    return (
        <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 4000 4000"
        >
            {/* <g id="background">
                <rect width="4000" height="4000" fill="#fff" strokeWidth={0}/>
              </g> */}
            <g id="Layer_2" data-name="Layer 2">
                <g>
                    <path
                        d="m2388.19,1034.58l112.81,434.11c96.35,370.79,385.91,660.34,756.69,756.69l434.11,112.81-434.11,112.81c-370.79,96.35-660.34,385.91-756.69,756.69l-112.81,434.11-112.81-434.11c-96.35-370.79-385.91-660.34-756.69-756.69l-434.11-112.81,434.11-112.81c370.79-96.35,660.34-385.91,756.69-756.69l112.81-434.11Z"
                        fill="#231f20"
                        strokeWidth={0}
                    />
                    <path
                        d="m871.53,971.53l48.75,187.59c41.64,160.23,166.76,285.35,326.99,326.99l187.59,48.75-187.59,48.75c-160.23,41.64-285.35,166.76-326.99,326.99l-48.75,187.59-48.75-187.59c-41.64-160.23-166.76-285.35-326.99-326.99l-187.59-48.75,187.59-48.75c160.23-41.64,285.35-166.76,326.99-326.99l48.75-187.59Z"
                        fill="#231f20"
                        strokeWidth={0}
                    />
                    <path
                        d="m1698.19,358.19l36.63,140.97c31.29,120.41,125.32,214.44,245.73,245.73l140.97,36.63-140.97,36.63c-120.41,31.29-214.44,125.32-245.73,245.73l-36.63,140.97-36.63-140.97c-31.29-120.41-125.32-214.44-245.73-245.73l-140.97-36.63,140.97-36.63c120.41-31.29,214.44-125.32,245.73-245.73l36.63-140.97Z"
                        fill="#231f20"
                        strokeWidth={0}
                    />
                </g>
            </g>
        </svg>
    );
};