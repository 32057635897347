import { IconInputProps } from ".";

export const CompleteIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_44_30)">
        <path
          d="M15.0001 0.00012207C6.72901 0.00012207 0 6.72903 0 15C0 23.271 6.72901 29.9999 15.0001 29.9999C23.2711 29.9999 30 23.271 30 15C30 6.72903 23.2711 0.00012207 15.0001 0.00012207ZM15.0001 27.5409C8.0849 27.5409 2.459 21.9152 2.459 15C2.459 8.08492 8.0849 2.45912 15.0001 2.45912C21.9152 2.45912 27.541 8.08492 27.541 15C27.541 21.9152 21.9152 27.5409 15.0001 27.5409Z"
          fill="black"
        />
        <path
          d="M21.489 9.24293L12.5829 18.1491L8.51099 14.0772C8.0308 13.5971 7.25237 13.5971 6.77218 14.0772C6.29209 14.5574 6.29209 15.3358 6.77218 15.816L11.7135 20.7573C11.9536 20.9973 12.2682 21.1174 12.5829 21.1174C12.8976 21.1174 13.2122 20.9973 13.4523 20.7573L23.2278 10.9818C23.7079 10.5016 23.7079 9.72322 23.2278 9.24303C22.7476 8.76284 21.9691 8.76284 21.489 9.24293Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_44_30">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
