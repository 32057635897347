// ** packages **
import { ChevronRight, LogOut, ChevronLeft } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Ref, useState } from "react";

// ** components **
import SidebarNavItem, { IconType } from "./SidebarNavItems";

// ** CSS **
import "./style/sidebar.css";

// ** Redux **
import {
  getSidebarIsCollapse,
  setSidebarIsCollapse,
} from "redux/slices/commonSlice";
import { getAuth, setLogoutData } from "redux/slices/authSlice";

// ** constants **
import { ADMIN_NAVIGATION_ITEM, NAVIGATION_ITEM } from "../constants";
import usePermission from "hooks/usePermission";
import AlertModal from "components/modal/AlertModal";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import ImageShow from "components/Image";

type Props = {
  sidebarRef: Ref<HTMLDivElement>;
  headerTitle: string;
  profile?: string | null;
  setIsSidebarClicked: React.Dispatch<React.SetStateAction<boolean>>
  isSidebarClicked: boolean;
};
export type NavItem = {
  name: string;
  path: string;
  label: string;
  icon: string;
  subItems?: {
    name: string;
    type: string;
  };
};

type navigationType = {
  path: string;
  label: string;
  icon: string;
  permission?:
    | {
        name: string;
        type: string;
      }
    | undefined;
  subItems: {
    path: string;
    label: string;
    icon: string;
    permission?:
      | {
          name: string;
          type: string;
        }
      | undefined;
  }[];
};
const Sidebar = (props: Props) => {
  const { sidebarRef, headerTitle, profile, setIsSidebarClicked, isSidebarClicked } = props;
  const [logoutVisible, setLogoutVisible] = useState<boolean>(false);
  const sidebarIsCollapse = useSelector(getSidebarIsCollapse);
  const location = useLocation();
  const { hasPermission } = usePermission();
  const navigate = useNavigate();

  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const { user } = useSelector(getAuth);
  const navigationData = user?.is_super_administrator
    ? ADMIN_NAVIGATION_ITEM
    : NAVIGATION_ITEM;
  const handleCollapse = () => {
    dispatch(setSidebarIsCollapse(!sidebarIsCollapse));
  };
  const onSubmit = () => {
    dispatch(setLogoutData());
    setLogoutVisible(false);
  };
  return (
    <aside className="sidebar__wrapper pt-[15px]" ref={sidebarRef}>
      <div className="flex items-center">
        <div
          onClick={() => {
            navigate(PRIVATE_NAVIGATION.setting.personalInformation.view);
          }}
          className="cursor-pointer s__Menu__Item"
        >
          <ImageShow
            avatarWrapperClassName={
              !sidebarIsCollapse
                ? `w-[40px] h-[40px] sm:w-[45px] sm:h-[45px]`
                : `w-[60px] h-[60px] sm:w-[45px] sm:h-[45px]`
            }
            avatarInnerWrapperClassName="!rounded-full"
            imgPath={profile}
            first_name={headerTitle}
            last_name={headerTitle}
          />
        </div>
        {sidebarIsCollapse && (
          <p className="text-[20px] ml-3 sm:text-[15px] sm:w-min sm:leading-[12px]">
            <span className="font-semibold">{`${headerTitle}`}</span>
          </p>
        )}
      </div>
      <button
        onClick={() => handleCollapse()}
        className="collapse__btn absolute top-[42px] right-[-14px] flex items-center justify-center p-[3px] w-[28px] h-[28px] rounded-full bg-[#6558F5]"
      >
        {!sidebarIsCollapse ? <ChevronRight /> : <ChevronLeft />}
      </button>
      {/* <div className="logo__wrapper flex items-center justify-center w-full pt-[20px] pb-[25px] px-[10px]">
        <img
          className="h-[60px] w-auto block object-contain"
          src="/images/company__logo.png"
          alt=""
        />
      </div> */}
      <div
        className={`menu__wrapper ${
          sidebarIsCollapse
            ? "h-[calc(100vh_-_200px)]"
            : "h-[calc(100vh_-_170px)]"
        } py-[18px] overflow-y-auto`}
      >
        <div>
          {navigationData
            ?.filter(
              (item: any) => !item.permission || hasPermission(item.permission)
            )
            .map((filteredItem: any) => (
              <SidebarNavItem
                key={filteredItem?.label}
                path={filteredItem.path}
                icon={filteredItem.icon}
                label={filteredItem.label}
                currentPath={currentPath}
                subMenu={filteredItem.subItems}
                setIsSidebarClicked={setIsSidebarClicked}
              />
            ))}
        </div>
      </div>
      <div className="sidebar-bottom">
        <div className="s__Menu__Item !my-2">
          <img
            className="h-[60px] w-auto block object-contain sm:w-[200px] dsd-logo"
            src="/images/dsd-logo.png"
            alt=""
          />
           <img
            className="h-[30px] w-auto block object-contain dsd-icon"
            src="/images/dsd-icon.svg"
            alt=""
          />
        </div>
        <div
          className="s__Menu__Item logout__btn w-full bg-[#222222] group hover:bg-bgBlackSD"
          onClick={() => {
            setLogoutVisible(true);
          }}
        >
          <Link className="s__Menu__Item__Link group" to="#">
            <div className="s__Menu__Icon bg-transparent">
              <LogOut />
            </div>
            <div className="s__Menu__Text !text-[#ffffff]">Logout</div>
          </Link>
        </div>
      </div>
      <AlertModal
        onCancel={() => setLogoutVisible(false)}
        onClose={() => setLogoutVisible(false)}
        onSubmit={onSubmit}
        showFooter
        showCrossIcon
        visible={logoutVisible}
        submitButtonText="Logout"
      >
        <div className="text-center">Are you sure you want to log out ? </div>
      </AlertModal>
    </aside>
  );
};
export default Sidebar;
