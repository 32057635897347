import { IconInputProps } from ".";

export const PauseCircleIcon = ({ className }: IconInputProps) => {
    return (
        <svg className={className ?? ''} width="67" height="75" viewBox="0 0 67 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M53.625 11.25V48.75C53.625 49.7446 53.2299 50.6984 52.5266 51.4016C51.8234 52.1049 50.8696 52.5 49.875 52.5H40.5C39.5054 52.5 38.5516 52.1049 37.8484 51.4016C37.1451 50.6984 36.75 49.7446 36.75 48.75V11.25C36.75 10.2554 37.1451 9.30161 37.8484 8.59835C38.5516 7.89509 39.5054 7.5 40.5 7.5H49.875C50.8696 7.5 51.8234 7.89509 52.5266 8.59835C53.2299 9.30161 53.625 10.2554 53.625 11.25ZM25.5 7.5H16.125C15.1304 7.5 14.1766 7.89509 13.4733 8.59835C12.7701 9.30161 12.375 10.2554 12.375 11.25V48.75C12.375 49.7446 12.7701 50.6984 13.4733 51.4016C14.1766 52.1049 15.1304 52.5 16.125 52.5H25.5C26.4946 52.5 27.4484 52.1049 28.1516 51.4016C28.8549 50.6984 29.25 49.7446 29.25 48.75V11.25C29.25 10.2554 28.8549 9.30161 28.1516 8.59835C27.4484 7.89509 26.4946 7.5 25.5 7.5Z" fill="#788895"/>
        <path d="M3 72H64" stroke="#788895" strokeWidth="5" strokeLinecap="round"/>
      </svg>
    )
}
