type TranscriptionStatusProps = {
  bgColor?: string;
  textColor?: string;
  ringColor?: string;
  status: string;
};

const TranscriptionStatus = (props: TranscriptionStatusProps) => {
  const { bgColor, textColor, ringColor, status } = props;

  const ColorClass = {
    textColor: textColor,
    bgColor: bgColor,
    ringColor: ringColor,
    status: null,
  };

  const getDefaultColorsFromStatus = () => {
    if (status === "Pending") {
      return {
        bgColor: "bg-gray-50",
        textColor: "text-gray-600",
        ringColor: "ring-gray-500/10",
        status: "Pending",
      };
    } else if (status === "In process") {
      return {
        bgColor: "bg-yellow-50",
        textColor: "text-yellow-800",
        ringColor: "ring-yellow-600/20",
        status: "Process",
      };
    } else if (status === "Completed") {
      return {
        bgColor: "bg-green-50",
        textColor: "text-green-700",
        ringColor: "ring-green-600/20",
        status: "Done",
      };
    } else {
      return {
        bgColor: "bg-pink-50",
        textColor: "text-pink-700",
        ringColor: "ring-pink-700/10",
        status: null,
      };
    }
  };

  const defaultColor = getDefaultColorsFromStatus();

  if (!textColor) {
    ColorClass["textColor"] = defaultColor?.textColor;
  }
  if (!bgColor) {
    ColorClass["bgColor"] = defaultColor?.bgColor;
  }
  if (!ringColor) {
    ColorClass["ringColor"] = defaultColor?.ringColor;
  }

  return (
    <span
      className={`inline-flex items-center rounded-full truncate ${ColorClass.bgColor} px-2 py-1 text-xs font-medium ${ColorClass.textColor} ring-1 ring-inset ${ColorClass.ringColor}`}
    >
      {defaultColor.status ? defaultColor?.status : status}
    </span>
  );
};
export default TranscriptionStatus;
